import { ReactComponent as Gold } from '../assets/icons/jackpot/gold.svg';
import { ReactComponent as Silver } from '../assets/icons/jackpot/silver.svg';
import { ReactComponent as Bronze } from '../assets/icons/jackpot/bronze.svg';
import { ReactComponent as Diamond } from '../assets/icons/jackpot/diamond.svg';
import { ReactComponent as Platinum } from '../assets/icons/jackpot/platinum.svg';

export const JACKPOT_ICONS_MAP = {
  bronze: Bronze,
  silver: Silver,
  gold: Gold,
  platinum: Platinum,
  diamond: Diamond,
};

export const JACKPOT_PRODUCT_OPTIONS = [
  {
    id: 'sportsbook',
    name: 'Sportsbook',
  },
  {
    id: 'casino',
    name: 'Casino',
  },
];

export const JACKPOT_TYPE_OPTIONS = [{ id: 'progressive', name: 'Progressive' }];
