import { QueryKey, useInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useRef, useState } from 'react';
import { PaginatedData } from '../@types/api';
import { getData, hasApiMorePages } from '../utils/api';

type Props = {
  queryKey: QueryKey;
  endpoint: string;
  queryParams: {
    page?: number;
    limit?: number;
    search?: string;
    [key: string]: unknown;
  };
  enabled?: boolean;
};

const useInfiniteScroll = <T>({ queryKey, endpoint, queryParams, enabled }: Props) => {
  const observer = useRef<IntersectionObserver | null>(null);
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);

  const { data, fetchNextPage, hasNextPage, isLoading, isFetching, isInitialLoading, isSuccess } = useInfiniteQuery<
    PaginatedData<T>
  >({
    queryKey: [...queryKey, queryParams],
    queryFn: ({ pageParam = 1 }) =>
      getData(endpoint, {
        page: pageParam,
        limit: 25,
        ...queryParams,
        search: queryParams.search?.trim() || undefined,
      }),
    getNextPageParam: (lastPage) => {
      if (hasApiMorePages(lastPage.count, lastPage.page, lastPage.limit)) {
        return lastPage.page + 1;
      }
    },
    enabled,
    keepPreviousData: true,
  });

  const lastElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (isLoading || isFetching || isFetchingNextPage) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          setIsFetchingNextPage(true);
          fetchNextPage().then(() => setIsFetchingNextPage(false));
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoading, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage]
  );

  const items = useMemo(() => data?.pages.flatMap((page) => page.items), [data]);

  return {
    items,
    isLoading,
    isFetching,
    isSuccess,
    isFetchingNextPage,
    lastElementRef,
    isInitialLoading,
    hasNextPage,
  };
};

export default useInfiniteScroll;
