import { AddCircle, ArrowDropUp } from '@mui/icons-material';
import { Box, Checkbox, CircularProgress, IconButton, Stack, Table, Tooltip, Typography } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { QueryKey } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { MarketsGroupedByMarketType, StyleObj } from '../../@types/';
import { Event, Market, Participant, SpecialValue } from '../../@types/api';
import { QUERY_KEYS } from '../../constants';
import { useModal } from '../../contexts/ModalContext';
import { getLatestValue } from '../../helpers';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import useMarketsInfiniteScroll from '../../hooks/useMarketsInfiniteScroll';
import useMutateData from '../../hooks/useMutateData';
import { formatMarketsData } from '../../utils';
import Switch from '../atoms/Switch';
import PublishStatusIcon from '../icons/PublishStatusIcon';
import ManageMarketMenu from '../molecules/ManageMarketMenu';
import OutcomesTable from './OutcomesTable';
import SpecialValueForm from './forms/SpecialValueForm';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: 2,
  [`&.${tableCellClasses.head}`]: {
    border: '1px solid rgba(0, 83, 55, 0.20)',
    background: theme.palette.background.lightGreen,
    color: theme.palette.background.contrastText,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '120%',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: '1px solid',
    borderColor: theme.palette.primary.container,
  },
}));

const styles: StyleObj = {
  tableContainer: {
    borderRadius: 0,
  },
  selectTableCell: {
    width: 48,
  },
  marketNameCellContent: {
    display: 'flex',
    alignItems: 'center',
    pl: 0.75,
    '& h6': {
      pl: 0.25,
    },
  },
  switchWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
  arrowIconWrapper: { cursor: 'pointer', width: '100%', display: 'flex', justifyContent: 'flex-end' },
  outcomeTableCell: {
    p: 0,
  },
  outcomeTableWrapper: {
    width: '95%',
    display: 'flex',
    margin: '0 auto',
    my: 2,
  },
  outcomeTable: {
    width: '42vw',
    overflowX: 'auto',
    '& .MuiDataGrid-footerContainer': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeader:first-of-type': {
      borderTopLeftRadius: 0,
    },
    '& .MuiDataGrid-columnHeader:last-of-type': {
      borderTopRightRadius: 0,
    },
  },
  toggleRowCell: {
    cursor: 'pointer',
  },
  mainRowSelected: {
    background: (theme) => theme.palette.selected.yellowLight,
  },
};

const isGroupOfMarkets = (market: Market | MarketsGroupedByMarketType): market is MarketsGroupedByMarketType =>
  'markets' in market;

type RowCollapseMode = {
  id: string;
  isCollapsedMode: boolean;
};

type MarketsTableProps = {
  queryKey: QueryKey;
  event: Event;
  activeTab: string | false;
};

const MarketsTable = ({ event, queryKey, activeTab }: MarketsTableProps) => {
  const [rowsCollapsedMode, setRowsCollapsedMode] = useState<RowCollapseMode[]>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const { openModal } = useModal();
  const invalidateData = useInvalidateQuery();

  const { createData: createMarketChangesData } = useMutateData('market-changes', [
    QUERY_KEYS.markets,
    {
      eventId: event?.id,
      activeGroup: activeTab,
    },
  ]);

  const { markets, isFetchingNextPage, isLoading, lastElementRef } = useMarketsInfiniteScroll(event?.id, activeTab);

  useEffect(() => {
    if (markets) {
      setRowsCollapsedMode((prev) => {
        const existingItemsIds = prev.map((item) => item.id);
        const newItems = markets
          ?.filter((item) => !existingItemsIds.includes(item.id))
          ?.map((item) => ({
            id: item.id,
            isCollapsedMode: item.isCollapsedMode ?? false,
          }));
        return [...prev, ...newItems];
      });
    }
  }, [markets]);

  const toggleRow = (id: string) => {
    setRowsCollapsedMode((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          return {
            id: item.id,
            isCollapsedMode: !item.isCollapsedMode,
          };
        }
        return item;
      })
    );
    setSelectedRows((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      }
      return [...prev, id];
    });
  };

  const handleMarketContextMenu = (event: React.MouseEvent, row: Market) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    if (!selectedRows.includes(row.id)) {
      handleSelectRow(row);
    }
  };

  const handleCloseMarketMenu = () => {
    setContextMenu(null);
  };

  const handleStatusUpdate = (id: string, isActive: boolean) => {
    createMarketChangesData(
      {
        marketId: id,
        isActive: !isActive,
      },
      () => invalidateData([QUERY_KEYS.events])
    );
  };

  const handleAddSpecialValue = (
    eventId: string,
    row: {
      eventId: string;
      participants: Participant[];
      marketTypeId: string;
      specialValues: SpecialValue[];
    }
  ) => {
    openModal(
      <SpecialValueForm
        item={{
          eventId: eventId,
          participants: event.participants,
          marketTypeId: row.marketTypeId,
          specialValues: row.specialValues,
        }}
      />
    );
  };

  const topLevelMarkets = useMemo(() => formatMarketsData(markets), [markets]);

  const handleSelectRow = (market: Market | MarketsGroupedByMarketType) => {
    const isMarketSelected = selectedRows.includes(market.id);

    const toggleSelection = (idsToToggle: string[]) => {
      setSelectedRows((prev) =>
        isMarketSelected ? prev.filter((id) => !idsToToggle.includes(id)) : [...prev, ...idsToToggle]
      );
    };

    if (isGroupOfMarkets(market)) {
      const marketIds = market.markets.map((item) => item.id);
      toggleSelection([...marketIds, market.id]);
    } else {
      toggleSelection([market.id]);
    }
  };

  const handleSelectAllRows = () => {
    if (selectedRows.length > 0) {
      setSelectedRows([]);
    } else {
      let marketsToSelect: string[] = [];
      topLevelMarkets.forEach((item) => {
        if (isGroupOfMarkets(item)) {
          marketsToSelect = [...marketsToSelect, ...item.markets.map((item) => item.id)];
        }
        marketsToSelect.push(item.id);
      });

      setSelectedRows(marketsToSelect);
    }
  };

  const getIsRowCollapsed = (id: string) => rowsCollapsedMode.find((item) => item.id === id)?.isCollapsedMode;

  return (
    <Fragment>
      {isLoading ? (
        <CircularProgress sx={{ position: 'absolute', top: '50%', left: '72%', transform: 'translate(-50%, -50%)' }} />
      ) : (
        <TableContainer sx={styles.tableContainer}>
          <Table aria-label='markets table' stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell component='th' align='center'>
                  <Checkbox
                    onChange={handleSelectAllRows}
                    checked={topLevelMarkets.every((item) => selectedRows.includes(item.id))}
                  />
                </StyledTableCell>
                <StyledTableCell component='th' align='center'>
                  Market Name
                </StyledTableCell>
                <StyledTableCell component='th' align='center'>
                  Status
                </StyledTableCell>
                <StyledTableCell component='th' align='center' />
              </TableRow>
            </TableHead>
            <TableBody sx={{ overflowY: 'auto' }}>
              {topLevelMarkets.map((row) => {
                return (
                  <Fragment key={row.id}>
                    <TableRow
                      key={row.id}
                      sx={selectedRows.includes(row.id) ? styles.mainRowSelected : {}}
                      hover
                      onContextMenu={
                        !isGroupOfMarkets(row) ? (event) => handleMarketContextMenu(event, row) : undefined
                      }
                    >
                      <StyledTableCell scope='row' align='center' sx={styles.selectTableCell}>
                        <Checkbox onChange={() => handleSelectRow(row)} checked={selectedRows.includes(row.id)} />
                      </StyledTableCell>
                      <StyledTableCell
                        scope='row'
                        align='left'
                        onClick={() => toggleRow(row.id)}
                        sx={styles.toggleRowCell}
                      >
                        <Box sx={styles.marketNameCellContent}>
                          {'publishStatus' in row && 'changed' in row && (
                            <PublishStatusIcon status={row.publishStatus} changed={row.changed} />
                          )}
                          <Typography variant='h6'>{row.name}</Typography>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align='center'>
                        <Box sx={styles.switchWrapper}>
                          {'isActive' in row && (
                            <Switch
                              value={getLatestValue(row, 'isActive')}
                              onChange={() => handleStatusUpdate(row.id, getLatestValue(row, 'isActive'))}
                              name='status'
                            />
                          )}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align='right' sx={styles.toggleRowCell}>
                        <Box sx={styles.arrowIconWrapper}>
                          {!isEmpty(row.specialValues) && (
                            <Tooltip title='Add special value' placement='top'>
                              <IconButton
                                color='primary'
                                aria-label='add special value'
                                onClick={() =>
                                  handleAddSpecialValue(event.id, {
                                    eventId: event.id,
                                    participants: event.participants,
                                    marketTypeId: isGroupOfMarkets(row) ? row.marketType : row.marketTypeId,
                                    specialValues: row.specialValues,
                                  })
                                }
                              >
                                <AddCircle />
                              </IconButton>
                            </Tooltip>
                          )}
                          <IconButton
                            aria-label='expand row'
                            size='small'
                            onClick={() => toggleRow(row.id)}
                            sx={styles.toggleRowCell}
                          >
                            {!getIsRowCollapsed(row.id) ? <ArrowDropDownIcon /> : <ArrowDropUp />}
                          </IconButton>
                        </Box>
                      </StyledTableCell>
                    </TableRow>
                    {!getIsRowCollapsed(row.id) && (
                      <TableRow>
                        <TableCell sx={styles.outcomeTableCell} colSpan={4}>
                          <Box sx={styles.outcomeTableWrapper}>
                            <OutcomesTable
                              event={event}
                              markets={isGroupOfMarkets(row) ? row.markets : [row]}
                              queryKey={queryKey}
                              key={row.id}
                              displayType={isGroupOfMarkets(row) ? 'multiple' : 'single'}
                              selectedRows={selectedRows}
                              handleSelectRow={handleSelectRow}
                              handleMarketContextMenu={handleMarketContextMenu}
                              handleMarketStatusUpdate={handleStatusUpdate}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
          {isFetchingNextPage ? (
            <Stack justifyContent='center' alignItems='center' p={1}>
              <CircularProgress />
            </Stack>
          ) : (
            <Box height={56} ref={lastElementRef} />
          )}
        </TableContainer>
      )}
      {selectedRows.length > 0 && (
        <ManageMarketMenu
          contextMenu={contextMenu}
          handleClose={handleCloseMarketMenu}
          queryKey={queryKey}
          selectedRows={selectedRows}
        />
      )}
    </Fragment>
  );
};

export default MarketsTable;
