import { useQuery } from '@tanstack/react-query';
import { getData } from '../utils/api';
import { FilterCRMUserData } from '../components/organisms/forms/FilterCRMUsers';
import { QUERY_KEYS } from '../constants';
import { useCallback } from 'react';

type CountryLocationData = {
  [key: string]: {
    regions: string[];
    cities: string[];
  };
};

type LocationData = {
  campaign: string[];
  leadSource: string[];
  status: string[];
  country: string[];
  city: string[];
  region: string[];
  language: string[];
  locationData: CountryLocationData;
};

type LocationType = 'city' | 'region';

const MAP_LOCATION_TYPE: Partial<Record<LocationType, keyof CountryLocationData[string]>> = {
  city: 'cities',
  region: 'regions',
};

const filterByCountry = (data: LocationData, countries: string[], locationType: LocationType) => {
  if (!countries.length) return data[locationType];

  const locationTypeKey = MAP_LOCATION_TYPE[locationType];

  const locations = countries.reduce((acc: string[], country: string) => {
    const locationsByCountry = (locationTypeKey && data.locationData[country][locationTypeKey]) || [];
    return [...acc, ...locationsByCountry];
  }, []);

  return locations;
};

const useLocationData = (selectedCountries: string[]) => {
  const { data } = useQuery<LocationData>([QUERY_KEYS.locationData], {
    queryFn: () => getData('customer/init-filters', undefined, 'crm'),
  });

  const getLocationOptions = useCallback(
    (key: keyof FilterCRMUserData) => {
      switch (key) {
        case 'country':
          return data?.country.filter(Boolean);
        case 'city': {
          return data && filterByCountry(data, selectedCountries, key);
        }
        case 'region': {
          return data && filterByCountry(data, selectedCountries, key);
        }
      }
    },
    [selectedCountries, data]
  );

  return {
    locationData: data,
    getLocationOptions,
  };
};

export default useLocationData;
