import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { BettingSlipAcceptStatus } from '../@types/api';
import { TicketAuthorizationMessage } from '../components/layouts/MainLayout';
import { useAudio } from '../contexts/AudioContext';
import { useBrowserVisibility } from './useBrowserVisibility';
import { MessageType } from './useWebsocket';

type TicketAuthorizationMessageData = {
  [id: string]: MessageType<TicketAuthorizationMessage>;
};

const usePlayTicketAuthSound = () => {
  const isTicketAuthorizationPageRef = useRef(false);
  const isBrowserMinimizedRef = useBrowserVisibility();

  const { loopAudio: loopIncomingTicketAudio } = useAudio();

  const location = useLocation();

  const canPlayAuthorizationSound = useCallback(
    (acceptStatus: BettingSlipAcceptStatus, reoffered: boolean) => {
      if (acceptStatus === 'pending' && !reoffered) {
        // Only play sound on authorization page if the browser is minimized
        if (isTicketAuthorizationPageRef.current) {
          return isBrowserMinimizedRef.current;
        }

        return true;
      }

      return false;
    },
    [isBrowserMinimizedRef]
  );

  const ticketAuthorizationCallback = useCallback(
    (data: TicketAuthorizationMessageData) => {
      const message = Object.values(data)[0];
      const { acceptStatus, reoffered } = message.payload;

      if (canPlayAuthorizationSound(acceptStatus, reoffered)) {
        loopIncomingTicketAudio(30);
      }
    },
    [canPlayAuthorizationSound, loopIncomingTicketAudio]
  );

  useEffect(() => {
    isTicketAuthorizationPageRef.current = location.pathname.includes('ticket-authorization');
  }, [location.pathname]);

  return {
    ticketAuthorizationCallback,
  };
};

export default usePlayTicketAuthSound;
