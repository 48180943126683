import { Box, Button, Typography } from '@mui/material';
import { StyleObj } from '../../@types';

interface Props {
  serverError?: boolean;
  onTryAgain?: () => void;
}

const styles: StyleObj = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
};

const NoDataMessage = ({ serverError = false, onTryAgain }: Props) => {
  return (
    <Box sx={styles.container}>
      {serverError ? (
        <>
          <Typography variant='h6'>There was a problem loading data, please try again</Typography>
          <Button onClick={onTryAgain}>Try again</Button>
        </>
      ) : (
        <Typography variant='h6'>There is currently no data in the table</Typography>
      )}
    </Box>
  );
};

export default NoDataMessage;
