import { GridCellModes, GridCellModesModel, GridCellParams } from '@mui/x-data-grid';
import { useCallback, useState } from 'react';

/**
 * This hook provides functionality to switch a DataGrid cell to edit mode on first click,
 * while ensuring that any other cells currently in edit mode are switched back to view mode.
 * It manages an internal state to track the mode (edit or view) of each cell.
 */

export const useCellEditMode = () => {
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

  const handleCellClick = useCallback((params: GridCellParams) => {
    if (!params.isEditable) return;

    setCellModesModel((prevModel) => ({
      ...Object.keys(prevModel).reduce(
        (acc, id) => ({
          ...acc,
          [id]: Object.keys(prevModel[id]).reduce(
            (acc2, field) => ({
              ...acc2,
              [field]: { mode: GridCellModes.View },
            }),
            {}
          ),
        }),
        {}
      ),
      [params.id]: {
        ...Object.keys(prevModel[params.id] || {}).reduce(
          (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
          {}
        ),
        [params.field]: { mode: GridCellModes.Edit },
      },
    }));
  }, []);

  const handleCellModesModelChange = useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);

  return { cellModesModel, handleCellClick, handleCellModesModelChange };
};
