import { CheckCircle, RemoveCircle } from '@mui/icons-material';
import { Box, Divider, Menu } from '@mui/material';
import { StyleObj } from '../../@types';
import { PublishStatus } from '../../@types/api';
import { MATCH_STATUS_OPTIONS, PUBLISH_STATUS_BASE_OPTIONS, SWITCH_CONTROL_OPTIONS } from '../../constants';
import { AutomaticIcon, ManualIcon } from '../icons';
import PublishStatusIcon from '../icons/PublishStatusIcon';
import ManageMenuSection from './ManageMenuSection';

export type HandleMenuItemClickOption = {
  id: number | string;
  name: string;
};

export type HandleMenuItemClickField = 'manualControl' | 'isActive' | 'publishStatus';

type ManageEventMenuProps = {
  statusChanged: boolean;
  contextMenu: { mouseX: number; mouseY: number } | null;
  handleClose: () => void;
  handleItemClick: (option: HandleMenuItemClickOption, field: HandleMenuItemClickField) => void;
};

const styles: StyleObj = {
  menu: {
    '& .MuiMenu-list': {
      pt: 0,
      pb: 0.75,
    },
  },
  menuContent: { minWidth: 156 },
};

const ManageEventMenu = ({ statusChanged, contextMenu, handleClose, handleItemClick }: ManageEventMenuProps) => {
  return (
    <Menu
      open={contextMenu !== null}
      onClose={handleClose}
      anchorReference='anchorPosition'
      anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      slotProps={{
        root: {
          onContextMenu: (e) => {
            e.preventDefault();
            handleClose();
          },
        },
      }}
      sx={styles.menu}
    >
      <Box sx={styles.menuContent}>
        <ManageMenuSection
          title='Switch control'
          options={SWITCH_CONTROL_OPTIONS}
          handleItemClick={(option) => handleItemClick(option, 'manualControl')}
          iconRenderer={(id) => (id === 1 ? <ManualIcon /> : <AutomaticIcon />)}
        />
        <Divider />
        <ManageMenuSection
          title='Match status'
          options={MATCH_STATUS_OPTIONS}
          handleItemClick={(option) => handleItemClick(option, 'isActive')}
          iconRenderer={(id) =>
            id === 1 ? (
              <CheckCircle color={'success'} fontSize='small' sx={styles.menuMuiIcon} />
            ) : (
              <RemoveCircle color={'error'} fontSize='small' sx={styles.menuMuiIcon} />
            )
          }
        />
        <Divider />
        <ManageMenuSection
          title='Publish status'
          options={PUBLISH_STATUS_BASE_OPTIONS}
          handleItemClick={(option) => handleItemClick(option, 'publishStatus')}
          iconRenderer={(id) => <PublishStatusIcon status={id as PublishStatus} />}
        />
      </Box>
    </Menu>
  );
};
export default ManageEventMenu;
