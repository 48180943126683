import { AccessTime, DeleteOutlined, EditOutlined } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, TextField, Typography, styled } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { StyleObj } from '../../@types';
import { CRMApiEntityType, Task } from '../../@types/api';
import { CRM_TASK_TYPE_CONFIG } from '../../config';
import { CUSTOMER_TASK_MESSAGE_TYPE_OPTIONS, QUERY_KEYS } from '../../constants';
import { useModal } from '../../contexts/ModalContext';
import CustomerDetailBadge from '../atoms/CustomerDetailBadge';
import ConfirmationModal from '../modals/ConfirmationModal';
import DateTimeSelect from './DateTimeSelect';
import TypeSelect from './TypeSelect';
import useMutateData from '../../hooks/useMutateData';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import { USER_TYPE_TO_CRM_API_ENTITY_MAP } from '../../config/crm';

const styles: StyleObj = {
  editButtonsWrapper: {
    display: 'flex',
    gap: 1,
    justifyContent: 'end',
  },
  editTextField: {
    typography: 'h6',
    p: 1,
  },
  circle: {
    minWidth: '4px',
    height: '4px',
    borderRadius: '50%',
    background: (theme) => theme.palette.grey[500],
    margin: '0 8px 3px 8px',
  },
  dateTime: (theme) => ({
    ...theme.typography.h6,
    fontSize: 12,
    fontWeight: 600,
    color: '#49454F',
  }),
};

const StyledCard = styled(Box)({
  background: '#fff',
  border: '1px solid rgba(0, 83, 55, 0.20)',
  borderRadius: '6px',
  padding: '8px 12px',
});

type Props = {
  task: Task;
  userType: CRMApiEntityType;
  isPast?: boolean;
};

type EditTaskData = Pick<Task, 'comment' | 'type'> & { scheduledTime: Dayjs };

const CRMTask = ({ task, userType, isPast = false }: Props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { openModal } = useModal();
  const invalidateData = useInvalidateQuery();

  const { color, Icon } = CRM_TASK_TYPE_CONFIG[task.type];
  const apiEntity = USER_TYPE_TO_CRM_API_ENTITY_MAP[userType];

  const { deleteData, updateData } = useMutateData(`tasks/${apiEntity}`, [QUERY_KEYS.crmTasks], 'crm');

  const {
    control,
    register,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      comment: task.comment,
      scheduledTime: dayjs(task.scheduledTime),
      type: task.type,
    },
  });

  const editTask = (newTask: EditTaskData) => {
    updateData(
      task.id,
      newTask,
      () => {
        setIsEditMode(false);
        invalidateData([`tasks/${apiEntity}`]);
      },
      'Task updated successfully'
    );
  };

  const deleteTask = () => {
    deleteData(
      task.id,
      () => {
        setIsEditMode(false);
        invalidateData([`tasks/${apiEntity}`]);
      },
      'Task deleted successfully'
    );
  };

  const openConfirmationModal = () => {
    openModal(<ConfirmationModal handleConfirm={deleteTask} />);
  };

  return (
    <StyledCard>
      <Stack spacing={2}>
        <Stack direction='row' justifyContent='space-between'>
          <Stack direction='row' alignItems='center' spacing={1}>
            {isEditMode ? (
              <TypeSelect
                control={control}
                name='type'
                error={errors.type}
                options={CUSTOMER_TASK_MESSAGE_TYPE_OPTIONS}
              />
            ) : (
              <>
                <CustomerDetailBadge
                  label={task.author}
                  icon={<Icon fontSize='small' color='inherit' />}
                  badgeColor={isPast ? '#1d192b1f' : color}
                  textColor={isPast ? '#1D1B2038' : '#fff'}
                />
                <Box sx={styles.circle} />
                <AccessTime color='inherit' fontSize='inherit' />
                <Typography component='span' sx={styles.dateTime}>
                  {dayjs(task.scheduledTime).format('DD.MM. - HH:mm')}
                </Typography>
              </>
            )}
          </Stack>
          <Box>
            <IconButton onClick={() => setIsEditMode((prev) => !prev)} size='small' disabled={isPast}>
              <EditOutlined color={isEditMode ? 'primary' : 'inherit'} />
            </IconButton>
            <IconButton onClick={openConfirmationModal} size='small' disabled={isPast || isEditMode}>
              <DeleteOutlined />
            </IconButton>
          </Box>
        </Stack>
        <Stack spacing={1}>
          {isEditMode ? (
            <>
              <DateTimeSelect
                control={control}
                name='scheduledTime'
                label='Time and date'
                error={errors.scheduledTime}
                disablePast
              />
              <Typography variant='h6' fontWeight={600}>
                {task.author}
              </Typography>
              <TextField
                {...register('comment')}
                multiline
                InputProps={{
                  sx: styles.editTextField,
                }}
              />
              <Box sx={styles.editButtonsWrapper}>
                <Button variant='outlined' onClick={() => setIsEditMode(false)}>
                  Cancel
                </Button>
                <Button onClick={handleSubmit(editTask)} disabled={!isDirty}>
                  Apply
                </Button>
              </Box>
            </>
          ) : (
            <Typography variant='h6'>{task.comment}</Typography>
          )}
        </Stack>
      </Stack>
    </StyledCard>
  );
};

export default CRMTask;
