import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';

interface Props {
  children: React.ReactElement;
  apiHost: string;
  clientKey: string;
}

const GrowthBookProviderWrapper = ({
  children,
  apiHost,
  clientKey,
}: Props): ReactElement | null => {
  const [isLoaded, setIsLoaded] = useState(false);

  const growthBook = useMemo(() => {
    return new GrowthBook({ apiHost, clientKey });
  }, [apiHost, clientKey]);

  useEffect(() => {
    growthBook?.init().finally(() => setIsLoaded(true));
  }, [growthBook]);

  if (!isLoaded) {
    return null;
  }

  return <GrowthBookProvider growthbook={growthBook}>{children}</GrowthBookProvider>;
};

export default GrowthBookProviderWrapper;
