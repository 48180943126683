import { CheckCircle, RemoveCircle } from '@mui/icons-material';
import { Box, Menu } from '@mui/material';
import { QueryKey } from '@tanstack/react-query';
import { StyleObj } from '../../@types';
import { OUTCOME_ALL_MATCHES_STATUS_OPTIONS, OUTCOME_STATUS_OPTIONS } from '../../constants';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import useMutateData from '../../hooks/useMutateData';
import StyledMenuItem from '../atoms/StyledMenuItem';
import ManageMenuSection from './ManageMenuSection';

export type HandleMenuItemClickOption = {
  id: number | string;
  name: string;
};

type ManageOutcomeMenuProps = {
  contextMenu: { mouseX: number; mouseY: number } | null;
  handleClose: () => void;
  queryKey: QueryKey;
  selectedOutcomeData: Record<string, string>;
};

const styles: StyleObj = {
  menu: {
    '& .MuiMenu-list': {
      pt: 0,
      pb: 0.75,
    },
  },
  menuMuiIcon: { mr: 0.5 },
};

const ManageOutcomeMenu = ({ contextMenu, handleClose, queryKey, selectedOutcomeData }: ManageOutcomeMenuProps) => {
  const invalidateData = useInvalidateQuery();
  const { createData: createOutcomeChangesData } = useMutateData('outcome-changes', queryKey);
  const { createData: createOutcomeChangesDataBulkTournament } = useMutateData('outcome-changes/tournament', queryKey);
  const { outcomeId, marketId } = selectedOutcomeData;
  const handleIsActiveUpdate = (option: HandleMenuItemClickOption) => {
    createOutcomeChangesData(
      {
        outcomeId,
        marketId,
        isActive: !!option.id,
      },
      () => {
        invalidateData(['events']);
      },
      'Item updated successfully.'
    );
  };

  const handleIsActiveTournamentLevelUpdate = (option: HandleMenuItemClickOption) => {
    createOutcomeChangesDataBulkTournament(
      {
        outcomeId,
        marketId,
        isActive: !!option.id,
      },
      () => invalidateData(['events']),
      'Items updated successfully.'
    );
  };

  return (
    <Menu
      open={contextMenu !== null}
      onClose={handleClose}
      anchorReference='anchorPosition'
      anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      slotProps={{
        root: {
          onContextMenu: (e) => {
            e.preventDefault();
            handleClose();
          },
        },
      }}
      sx={styles.menu}
    >
      <Box>
        <ManageMenuSection
          title='Outcome status'
          options={OUTCOME_STATUS_OPTIONS}
          handleItemClick={handleIsActiveUpdate}
          iconRenderer={(id) =>
            id === 1 ? (
              <CheckCircle color={'success'} fontSize='small' sx={styles.menuMuiIcon} />
            ) : (
              <RemoveCircle color={'error'} fontSize='small' sx={styles.menuMuiIcon} />
            )
          }
        />
        {OUTCOME_ALL_MATCHES_STATUS_OPTIONS.map((option) => {
          return (
            <StyledMenuItem key={option.id} onClick={() => handleIsActiveTournamentLevelUpdate(option)}>
              {option.id === 1 && <CheckCircle color={'success'} fontSize='small' sx={styles.menuMuiIcon} />}
              {option.id === 0 && <RemoveCircle color={'error'} fontSize='small' sx={styles.menuMuiIcon} />}
              {option.name}
            </StyledMenuItem>
          );
        })}
      </Box>
    </Menu>
  );
};
export default ManageOutcomeMenu;
