import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PropsWithChildren } from 'react';
import { SECOND } from './constants';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 * SECOND,
    },
  },
});

const ReactQueryProvider = ({ children }: PropsWithChildren) => {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export default ReactQueryProvider;
