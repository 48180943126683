import { Box } from '@mui/material';
import { GridColDef, GridColumnGroupingModel } from '@mui/x-data-grid';
import { StyleObj } from '../../@types';
import { DailyReportItem } from '../../@types/api';
import { generateDateRangeTimestamps, getTotalsRowClass } from '../../helpers';
import { createNumberColumn, createPercentageColumn } from '../../helpers/columns';
import { usePagination } from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';
import { createColumn } from '../../utils';
import DailyReportsTotalUsers from '../atoms/DailyReportsTotalUsers';
import TableTemplate from '../templates/TableTemplate';

const styles: StyleObj = {
  container: {
    position: 'relative',
    height: 'calc(100% - 200px)',
  },
  table: {
    border: 'none',
    '& .MuiDataGrid-columnHeader': {
      border: '1px solid rgba(0, 83, 55, 0.20)',
      backgroundColor: 'background.lightGreen',
      '&:first-of-type': {
        borderRadius: 0,
      },
      '&:last-of-type': {
        borderRadius: 0,
        width: '100% !important',
        maxWidth: 'unset !important',
      },
      '& .MuiDataGrid-sortIcon': {
        color: 'grey.700',
      },
    },
    '& .MuiDataGrid-columnHeadersInner': {
      width: '100%',
      '> div': {
        width: '100%',
      },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 700,
      color: 'grey.700',
    },
    '& .MuiDataGrid-row:nth-of-type(even)': {
      backgroundColor: 'background.light',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    '& .MuiDataGrid-columnHeaders:hover .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
    '& .customGroupHeader': {
      backgroundColor: 'primary.main',
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        justifyContent: 'center',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 700,
        color: 'white',
      },
    },
    '& .MuiDataGrid-columnHeader--emptyGroup': {
      backgroundColor: 'primary.main',
      '&:first-of-type': {
        borderTopLeftRadius: 8,
      },
      '&:last-of-type': {
        borderTopRightRadius: 8,
      },
    },
    '& .MuiDataGrid-virtualScrollerRenderZone': {
      '& .MuiDataGrid-row--lastVisible': {
        backgroundColor: '#D9E7D9',
        '& .MuiDataGrid-cell': {
          fontWeight: '700',
        },
      },
    },
  },
};

const columnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: 'Users',
    description: 'Users',
    headerClassName: 'customGroupHeader',
    children: [{ field: 'registeredCount' }],
  },
  {
    groupId: 'Deposits',
    description: 'Deposits',
    headerClassName: 'customGroupHeader',
    children: [{ field: 'depositCount' }, { field: 'depositAmount' }],
  },
  {
    groupId: 'Withdrawals',
    description: 'Withdrawals',
    headerClassName: 'customGroupHeader',
    children: [{ field: 'withdrawalCount' }, { field: 'withdrawalAmount' }],
  },
  {
    groupId: 'Sports Betting',
    description: 'Sports Betting',
    headerClassName: 'customGroupHeader',
    children: [
      { field: 'ticketCount' },
      { field: 'stakeAmount' },
      { field: 'ticketWonCount' },
      { field: 'winningAmount' },
      { field: 'profitAmount' },
      { field: 'profitMarginPercentage' },
    ],
  },
];

const columns: GridColDef[] = [
  createColumn('date', 'Date'),
  createColumn('registeredCount', 'Registered'),
  createColumn('depositCount', 'Total'),
  createNumberColumn('depositAmount', 'Total Amount'),
  createColumn('withdrawalCount', 'Total'),
  createNumberColumn('withdrawalAmount', 'Total Amount'),
  createColumn('ticketCount', 'Tickets Placed'),
  createNumberColumn('stakeAmount', 'Stake'),
  createColumn('ticketWonCount', 'Tickets Won'),
  createNumberColumn('winningAmount', 'Winnings'),
  createNumberColumn('profitAmount', 'Profit'),
  createPercentageColumn('profitMarginPercentage', 'Profit Margin %'),
];

const generateTotalsRow = (data?: DailyReportItem) => {
  if (data) {
    return {
      ...data,
      date: 'Totals',
    };
  }
};

const DailyReportsPage = () => {
  const { fromTimestamp, toTimestamp } = generateDateRangeTimestamps('today');
  const { data, changeQuery, isFetching, isLoading, updateQueryParams } = usePagination<DailyReportItem>(
    'reports/daily',
    {
      page: 1,
      limit: 25,
      fromTimestamp: fromTimestamp.valueOf(),
      toTimestamp: toTimestamp.valueOf(),
    }
  );

  const totalsRow = generateTotalsRow(data?.total as unknown as DailyReportItem);

  const aggregatedData = data?.items ? [...data.items, totalsRow] : [];

  const { handleSort } = useSort(changeQuery);

  return (
    <Box sx={styles.container}>
      <DailyReportsTotalUsers />
      <TableTemplate
        getRowId={(params) => params.date}
        experimentalFeatures={{ columnGrouping: true }}
        columnGroupingModel={columnGroupingModel}
        rows={aggregatedData}
        columns={columns}
        sx={styles.table}
        loading={isFetching || isLoading}
        rowCount={data?.count || 0}
        handlePaginationModelChange={updateQueryParams}
        handleSort={handleSort}
        getRowClassName={getTotalsRowClass}
        changeQuery={changeQuery}
        showExport
      />
    </Box>
  );
};

export default DailyReportsPage;
