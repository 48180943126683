import { Box, Theme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants';
import { getData } from '../../utils/api';

const styles = {
  width: '220px',
  height: '40px',
  padding: '12px',
  border: (theme: Theme) => `1px solid ${theme.palette.selected.blue}`,
  backgroundColor: 'background.lightBlue',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 500,
  position: 'absolute',
  top: 0,
  right: 0,
};

const DailyReportsTotalUsers = () => {
  const { data: totalUsers } = useQuery<{ count: number }>([QUERY_KEYS.totalUserCount], {
    queryFn: () => getData('customer/active/count', {}, 'crm'),
  });

  return <Box sx={styles}>Total Users: {totalUsers?.count}</Box>;
};

export default DailyReportsTotalUsers;
