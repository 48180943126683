import { Close } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useQuery } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import { capitalize, isEmpty } from 'lodash-es';
import { Fragment, useEffect, useState } from 'react';
import { StyleObj } from '../../@types';
import { Event, EventExclusions, EventList, ExcludedEntity } from '../../@types/api';
import { QUERY_KEYS } from '../../constants';
import { useModal } from '../../contexts/ModalContext';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import useMutateData from '../../hooks/useMutateData';
import {
  useCompetitionsInfiniteScroll,
  useEventsInfiniteScroll,
  useSports,
  useTournamentsInfiniteScroll,
} from '../../queries';
import { getData } from '../../utils/api';
import { useDebounce } from '@uidotdev/usehooks';

const styles: StyleObj = {
  container: { display: 'flex', gap: 3 },
  menu: {
    maxHeight: 360,
  },
  eventNameWrapper: {
    display: 'flex',
    justifyContent: 'center',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    overflow: 'hidden',
    border: '1px solid rgba(0, 83, 55, 0.20)',
    '& > div:first-of-type': {
      borderRight: '1px solid rgba(0, 83, 55, 0.20)',
    },
  },
  eventNameBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'background.lightGreen',
    p: 1.5,
    textAlign: 'center',
  },
  excludedEntitiesContainer: {
    pt: 1.5,
    pl: 1.5,
    maxHeight: 200,
    overflowY: 'scroll',
  },
  loaderContainer: { justifyContent: 'center' },
};

type SelectOption = {
  id: string;
  name: string;
};

type FilterEventsData = {
  fromTimestamp?: number | Dayjs;
  toTimestamp?: number | Dayjs;
  sports: string[];
  competitions: string[];
  tournaments: string[];
  events: string[];
};

type EntitiesToExclude = keyof EventExclusions;

type EntitiesWithCheckboxToExclude = Exclude<EntitiesToExclude, 'events'>;

type DateChangeHandler = (date: number | Dayjs | null, type: 'from' | 'to') => void;

type SelectField = {
  entity: EntitiesToExclude;
  data: SelectOption[];
};

type SelectFields = SelectField[];

const queryParams = {
  limit: 50,
};

const defaultValues = {
  fromTimestamp: dayjs(),
  toTimestamp: dayjs().add(14, 'day'),
  sports: [],
  competitions: [],
  tournaments: [],
  events: [],
};

const MatchCombiningTab = () => {
  const [filterData, setFilterData] = useState<FilterEventsData>(defaultValues);
  const [checkedEntities, setCheckedEntities] = useState<EventExclusions>({
    sports: [],
    competitions: [],
    tournaments: [],
    events: [],
  });
  const [searchTerms, setSearchTerms] = useState({
    competitions: '',
    tournaments: '',
    events: '',
  });

  const debouncedSearchTerms = useDebounce(searchTerms, 300);

  const { item, closeModal } = useModal<EventList>();

  const { data: event } = useQuery({
    queryKey: ['events', { id: item?.id }],
    queryFn: (): Promise<{ item: Event }> => getData(`events/${item?.id}`),
    select: (data) => data.item,
    enabled: !!item?.id,
  });

  const { createData: excludeEntities } = useMutateData(`/events/${item?.id}/exclude-entities`, [QUERY_KEYS.events]);
  const invalidateData = useInvalidateQuery();

  // on event change, set checkedEntities
  useEffect(() => {
    if (event?.eventExclusions) {
      const getExcludedData = (entityType: EntitiesToExclude) => {
        if (isEmpty(event?.eventExclusions)) return [];

        return event?.eventExclusions[entityType];
      };

      const excludedSports = getExcludedData('sports');
      const excludedCompetitions = getExcludedData('competitions');
      const excludedTournaments = getExcludedData('tournaments');
      const excludedEvents = getExcludedData('events');

      setCheckedEntities({
        sports: excludedSports,
        competitions: excludedCompetitions,
        tournaments: excludedTournaments,
        events: excludedEvents,
      });
    }
  }, [event?.eventExclusions]);

  const { data: sportsData } = useSports({ ...queryParams, isActive: true });
  const {
    items: competitionsData,
    lastElementRef: lastCompetitionRef,
    isFetchingNextPage: isFetchingNextCompetition,
  } = useCompetitionsInfiniteScroll(filterData.sports, { ...queryParams, search: debouncedSearchTerms.competitions });

  const {
    items: tournamentsData,
    lastElementRef: lastTournamentRef,
    isFetchingNextPage: isFetchingNextTournament,
  } = useTournamentsInfiniteScroll(filterData.competitions, {
    ...queryParams,
    checkOutcomes: false,
    search: debouncedSearchTerms.tournaments,
  });

  const {
    items: eventsData,
    lastElementRef: lastEventRef,
    isFetchingNextPage: isFetchingNextEvent,
  } = useEventsInfiniteScroll(filterData.tournaments, {
    ...queryParams,
    fromTimestamp: filterData.fromTimestamp?.valueOf(),
    toTimestamp: filterData.toTimestamp?.valueOf(),
    search: debouncedSearchTerms.events,
  });

  const entityPaginationMap: Record<
    EntitiesToExclude,
    {
      isFetchingNextPage: boolean;
      lastElementRef: ((node: HTMLElement | null) => void) | null;
    }
  > = {
    sports: {
      isFetchingNextPage: false,
      lastElementRef: null,
    },
    competitions: {
      isFetchingNextPage: isFetchingNextCompetition,
      lastElementRef: lastCompetitionRef,
    },
    tournaments: {
      isFetchingNextPage: isFetchingNextTournament,
      lastElementRef: lastTournamentRef,
    },
    events: {
      isFetchingNextPage: isFetchingNextEvent,
      lastElementRef: lastEventRef,
    },
  };

  // on eventsData change, add checked events to filterData.events if they don't exist
  useEffect(() => {
    if (eventsData) {
      // find events that are in checkedEntities.events but not in filterData.events
      const newEventsToAdd = checkedEntities.events.filter(
        (event) => !filterData.events.includes(event.id) && eventsData.some((item) => item.id === event.id)
      );

      setFilterData((prev) => ({
        ...prev,
        events: [...prev.events, ...newEventsToAdd.map((event) => event.id)],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsData]);

  const onFormSubmit = () => {
    excludeEntities(
      {
        sportIds: checkedEntities.sports.map((sport) => sport.id),
        competitionIds: checkedEntities.competitions.map((competition) => competition.id),
        tournamentIds: checkedEntities.tournaments.map((tournament) => tournament.id),
        eventIds: checkedEntities.events.map((event) => event.id),
      },
      () => {
        closeModal?.();
        invalidateData([QUERY_KEYS.events]);
      }
    );
  };

  const handleEntityCheck = (item: ExcludedEntity, entityType: EntitiesWithCheckboxToExclude) => {
    const entity = checkedEntities[entityType as keyof typeof checkedEntities];

    if (entity.some((checkedItem) => checkedItem.id === item.id)) {
      setCheckedEntities((prev) => ({
        ...prev,
        [entityType]: prev[entityType].filter((checkedItem) => checkedItem.id !== item.id),
      }));
    } else {
      setCheckedEntities((prev) => ({
        ...prev,
        [entityType]: [...prev[entityType], item],
      }));
    }
  };

  const isChecked = (option: SelectOption, entityType: EntitiesWithCheckboxToExclude) => {
    return checkedEntities[entityType as keyof typeof checkedEntities]?.some(
      (checkedItem) => checkedItem.id === option.id
    );
  };

  const selectFields: SelectFields = [
    {
      entity: 'sports',
      data: sportsData?.items || [],
    },
    {
      entity: 'competitions',
      data: competitionsData || [],
    },
    {
      entity: 'tournaments',
      data: tournamentsData || [],
    },
    {
      entity: 'events',
      data: eventsData || [],
    },
  ];

  const onDateChange: DateChangeHandler = (date, type) => {
    if (date) {
      setFilterData((prev) => ({ ...prev, [`${type}Timestamp`]: date }));
    }
  };

  const onEntityCheck = (option: SelectOption, field: SelectField) => {
    if (field.entity !== 'events') {
      handleEntityCheck(
        {
          id: option.id,
          entityName: option.name,
        },
        field.entity
      );
    }
  };

  const onEntitySelect = (options: SelectOption[], field: SelectField) => {
    const selectedIds = options.map((item) => item.id);

    const checkedEventsUpdater = (prev: EventExclusions, entityType: EntitiesToExclude) => {
      const updatedEvents: ExcludedEntity[] = [...checkedEntities.events];
      // if selectedIds has events that are not in checkedEntities.events, add them to updatedEvents
      selectedIds.forEach((selectedId) => {
        if (!prev.events.some((event) => event.id === selectedId)) {
          const event = field.data?.find((item) => item.id === selectedId);
          if (event) {
            updatedEvents.push({ id: event.id, entityName: event.name });
          }
        }
      });
      // if checkedEntities.events has events that are not in selectedIds, but they are in filterData.events, remove them from updatedEvents
      prev.events.forEach((event) => {
        if (!selectedIds.includes(event.id) && filterData.events.includes(event.id)) {
          updatedEvents.splice(
            updatedEvents.findIndex((item) => item.id === event.id),
            1
          );
        }
      });

      return {
        ...prev,
        [entityType]: updatedEvents,
      };
    };

    if (field.entity === 'sports') {
      setFilterData((prev) => ({ ...prev, sports: selectedIds, competitions: [], tournaments: [], events: [] }));
    }
    if (field.entity === 'competitions') {
      setFilterData((prev) => ({ ...prev, competitions: selectedIds, tournaments: [], events: [] }));
    }
    if (field.entity === 'tournaments') {
      setFilterData((prev) => ({ ...prev, tournaments: selectedIds, events: [] }));
    }

    if (field.entity === 'events') {
      setFilterData((prev) => ({ ...prev, events: selectedIds }));
      setCheckedEntities((prev) => checkedEventsUpdater(prev, field.entity));
    }
  };

  const onRemoveItem = (entity: ExcludedEntity, entityType: EntitiesToExclude) => {
    setCheckedEntities((prev) => ({
      ...prev,
      [entityType]: prev[entityType].filter((checkedItem) => checkedItem.id !== entity.id),
    }));

    if (entityType === 'events') {
      setFilterData((prev) => ({ ...prev, events: prev.events.filter((eventId) => eventId !== entity.id) }));
    }
  };

  const renderExcludedItems = (entityType: EntitiesToExclude, entityData: ExcludedEntity[]) => {
    return entityData?.map((entity) => {
      if (entity) {
        return (
          <Box key={entity.id} display='flex' justifyContent='space-between' alignItems='center'>
            <Typography>{entity.entityName}</Typography>
            <IconButton onClick={() => onRemoveItem(entity, entityType)}>
              <Close />
            </IconButton>
          </Box>
        );
      }
      return null;
    });
  };

  return (
    <Fragment>
      <DialogContent>
        <Stack spacing={2}>
          <Box sx={styles.container}>
            <DateTimePicker
              label='From'
              value={filterData.fromTimestamp}
              maxDateTime={filterData.toTimestamp}
              onChange={(value) => onDateChange(value, 'from')}
              ampm={false}
            />
            <DateTimePicker
              label='To'
              value={filterData.toTimestamp}
              minDateTime={filterData.fromTimestamp}
              onChange={(value) => onDateChange(value, 'to')}
              ampm={false}
            />
          </Box>
          {selectFields.map((field) => {
            const { lastElementRef, isFetchingNextPage } = entityPaginationMap[field.entity];

            // Event for which we are excluding should not appear in the list of available events
            const filteredOptions =
              field.entity !== 'events' ? field.data : field.data.filter((option) => item?.id !== option.id);

            return (
              <FormControl key={field.entity}>
                <Autocomplete
                  key={field.entity}
                  options={filteredOptions}
                  multiple
                  onChange={(_e, value) => onEntitySelect(value, field)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={capitalize(field.entity)}
                      name={field.entity}
                      sx={{ width: '100%' }}
                    />
                  )}
                  onInputChange={(_e, value) => {
                    field.entity !== 'sports' && setSearchTerms((prev) => ({ ...prev, [field.entity]: value }));
                  }}
                  renderOption={(props, option, { index }) => {
                    const isLastElement = field.data.length - 1 === index;

                    return (
                      <>
                        <MenuItem {...props} ref={isLastElement ? lastElementRef : null}>
                          {field.entity !== 'events' && (
                            <Checkbox
                              checked={isChecked(option, field.entity)}
                              onChange={() => onEntityCheck(option, field)}
                              onClick={(e) => e.stopPropagation()}
                            />
                          )}
                          {option.name}
                        </MenuItem>
                        {isLastElement && isFetchingNextPage && (
                          <MenuItem sx={styles.loaderContainer}>
                            <CircularProgress />
                          </MenuItem>
                        )}
                      </>
                    );
                  }}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  loading={isFetchingNextPage}
                  noOptionsText='No options available'
                  sx={styles.autocomplete}
                />
              </FormControl>
            );
          })}
          <Box sx={styles.eventNameWrapper}>
            <Box sx={styles.eventNameBox}>
              <Typography variant='h5'>Source event</Typography>
            </Box>
            <Box sx={styles.eventNameBox}>
              <Typography variant='h5'>{item?.name}</Typography>
            </Box>
          </Box>
        </Stack>
        {Object.values(checkedEntities).some((entity) => entity.length > 0) && (
          <Typography variant='body2' px={1.5} py={0.5}>
            Cannot combine with
          </Typography>
        )}
        <Box sx={styles.excludedEntitiesContainer}>
          <Stack spacing={1}>
            {renderExcludedItems('sports', checkedEntities.sports)}
            {renderExcludedItems('competitions', checkedEntities.competitions)}
            {renderExcludedItems('tournaments', checkedEntities.tournaments)}
            {renderExcludedItems('events', checkedEntities.events)}
          </Stack>
        </Box>
        <Divider />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={closeModal}>
          Cancel
        </Button>
        <Button variant='contained' onClick={onFormSubmit}>
          Save
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default MatchCombiningTab;
