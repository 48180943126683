import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { CRMApiEntityType, Note } from '../../../@types/api';
import { useCRMUser } from '../../../contexts/CRMUserContext';
import { usePagination } from '../../../hooks/usePagination';
import CRMNote from '../../molecules/CRMNote';
import CreateNote from '../CreateNote';

type Props = {
  userType: CRMApiEntityType;
};

const CRMNotesTab = ({ userType }: Props) => {
  const { selectedCRMUser } = useCRMUser();

  const selectedCRMUserId = selectedCRMUser?.id;

  const endpoint = userType === 'customer' ? 'notes/customer' : 'notes/agent';

  const { data: notes, changeQuery } = usePagination<Note>(
    endpoint,
    {
      page: 1,
      limit: 25,
      userId: selectedCRMUserId,
    },
    undefined,
    'crm'
  );

  useEffect(() => {
    changeQuery({ userId: selectedCRMUserId });
  }, [changeQuery, selectedCRMUserId]);

  return (
    <Stack spacing={1}>
      <CreateNote userType={userType} />
      {notes?.items.map((note) => <CRMNote key={note.id} note={note} userType={userType} />)}
    </Stack>
  );
};

export default CRMNotesTab;
