import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import { useAuth } from 'neofusion-fe-shared';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DialogType } from '../../../../@types';
import { Customer, Partner } from '../../../../@types/api';
import { CURRENCY_OPTIONS, GENDER_OPTIONS, QUERY_KEYS } from '../../../../constants';
import { USER_ROLES } from '../../../../constants/keycloak';
import { useModal } from '../../../../contexts/ModalContext';
import { useInvalidateQuery } from '../../../../hooks/useInvalidateQuery';
import useMutateData from '../../../../hooks/useMutateData';
import usePermissions from '../../../../hooks/usePermissions';
import { CustomerFormData, customerSchema, editCustomerSchema } from '../../../../schema';
import FormFieldStack from '../../../atoms/FormFieldStack';
import Switch from '../../../atoms/Switch';
import FormModalLayout from '../../../layouts/FormModalLayout';
import DateTimeSelect from '../../../molecules/DateTimeSelect';
import FormSelect from '../../../molecules/FormSelect';
import PasswordFields from '../../../molecules/PasswordFields';
import FormAutocomplete from '../../FormAutocomplete';

export const DEFAULT_CUSTOMER_DATA: CustomerFormData = {
  firstName: '',
  lastName: '',
  email: '',
  dateOfBirth: undefined,
  phone: '',
  username: '',
  password: '',
  confirmPassword: '',
  agentId: undefined,
  country: '',
  region: '',
  isActive: false,
  address: '',
  zip: '',
  city: '',
  gender: '',
  nationalId: '',
  currency: '',
  language: '',
};

const CustomerForm = ({ closeModal }: DialogType) => {
  const { item: customer } = useModal<Customer>();
  const { role } = usePermissions();
  const { userId: loggedInUserId } = useAuth();

  const isEdit = !!customer;
  const isAdmin = role === USER_ROLES.ADMIN;

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CustomerFormData>({
    defaultValues: DEFAULT_CUSTOMER_DATA,
    resolver: zodResolver(!!customer ? editCustomerSchema : customerSchema),
  });

  const invalidateData = useInvalidateQuery();

  useEffect(() => {
    if (isEdit) {
      reset({
        ...customer,
        dateOfBirth: customer.dateOfBirth ? dayjs(customer.dateOfBirth) : undefined,
        agentId: customer.agent?.id,
      });
    } else if (!isAdmin) {
      // When an agent is adding a new customer, assign them automatically
      reset({
        ...DEFAULT_CUSTOMER_DATA,
        agentId: loggedInUserId,
      });
    }
  }, [customer, isEdit, loggedInUserId, isAdmin, reset]);

  const { createData, updateData } = useMutateData('customer', [QUERY_KEYS.customers], 'crm');

  const handleClose = () => {
    closeModal?.();
    reset(DEFAULT_CUSTOMER_DATA);
  };

  const onFormSubmit = (newCustomer: CustomerFormData) => {
    if (isEdit) {
      updateData(customer.id, newCustomer, () => {
        handleClose();
        invalidateData(['customer/search']);
      });
    } else {
      createData({ ...newCustomer, confirmPassword: undefined }, () => {
        handleClose();
        invalidateData(['customer/search']);
      });
    }
  };

  return (
    <FormModalLayout
      onSave={handleSubmit(onFormSubmit)}
      label={isEdit ? 'Edit player' : 'Add new player'}
      onClose={handleClose}
      isEdit={isEdit}
      editEntity='punters'
    >
      <TextField
        label='First Name'
        {...register('firstName')}
        error={!!errors.firstName}
        helperText={errors.firstName?.message}
        required
      />
      <TextField
        label='Last Name'
        {...register('lastName')}
        error={!!errors.lastName}
        helperText={errors.lastName?.message}
        required
      />
      <TextField
        label='Username'
        {...register('username')}
        error={!!errors.username}
        helperText={errors.username?.message}
        required
        disabled={!!customer}
      />
      <PasswordFields resetPassword={!!customer} errors={errors} register={register} />
      {!isEdit && (
        <FormAutocomplete<Partner, CustomerFormData>
          control={control}
          label='Agent'
          getOptionLabel={(options, value) => {
            return options.find((option) => option.userId === value)?.fullName || '';
          }}
          name='agentId'
          queryKey={[QUERY_KEYS.partners]}
          url='agent/search'
          queryParams={{
            roleTypes: ['Agent'],
          }}
          apiService='crm'
          optionsFilter='userId'
          required
          error={errors.agentId}
          disabled={!isAdmin}
        />
      )}
      <FormFieldStack label='Status'>
        <Controller name='isActive' control={control} render={({ field }) => <Switch {...field} ref={null} />} />
      </FormFieldStack>
      <TextField
        label='Email'
        {...register('email')}
        error={!!errors.email}
        helperText={errors.email?.message}
        disabled={!!customer?.email}
      />
      <DateTimeSelect
        label='Date of birth'
        control={control}
        name='dateOfBirth'
        disableTimePicker
        error={errors.dateOfBirth}
      />
      <TextField
        label='Phone Number'
        {...register('phone')}
        error={!!errors.phone}
        helperText={errors.phone?.message}
      />
      <FormSelect name='gender' label='Gender' control={control} options={GENDER_OPTIONS} />
      <TextField
        label='National ID'
        {...register('nationalId')}
        error={!!errors.nationalId}
        helperText={errors.nationalId?.message}
      />
      <TextField
        label='Country'
        {...register('country')}
        error={!!errors.country}
        helperText={errors.country?.message}
      />
      <TextField label='Region' {...register('region')} error={!!errors.region} helperText={errors.region?.message} />
      <TextField label='City' {...register('city')} error={!!errors.city} helperText={errors.city?.message} />
      <TextField
        label='Address'
        {...register('address')}
        error={!!errors.address}
        helperText={errors.address?.message}
      />
      <TextField label='Zip Code' {...register('zip')} error={!!errors.zip} helperText={errors.zip?.message} />
      <FormSelect name='currency' label='Currency' control={control} options={CURRENCY_OPTIONS} />
      <TextField
        label='Language'
        {...register('language')}
        error={!!errors.language}
        helperText={errors.language?.message}
      />
    </FormModalLayout>
  );
};

export default CustomerForm;
