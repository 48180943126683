import { zodResolver } from '@hookform/resolvers/zod';
import { AddCircle, Clear, HighlightOff, Save } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { StyleObj } from '../../@types';
import { QUERY_KEYS } from '../../constants';
import useMutateData from '../../hooks/useMutateData';
import { useCashoutMarketMargins } from '../../queries';
import { MarketMarginFormData, marketMarginSchema } from '../../schema';
import FormNumberInput from './FormNumberInput';

const styles: StyleObj = {
  container: {
    width: 500,
  },
  tableBody: {
    '& .MuiTableCell-root': {
      p: 0.5,
      textAlign: 'center',
    },
  },
  input: { textAlign: 'center', width: 200 },
  actionColumn: { width: 'fit-content' },
  submitBtn: { mt: 1 },
};

const MarketMarginTable = () => {
  const [isAddingMargin, setIsAddingMargin] = useState(false);

  const { data, isLoading } = useCashoutMarketMargins();

  const {
    createData,
    deleteData,
    isLoading: isMutating,
  } = useMutateData('cashout-market-margins', [QUERY_KEYS.cashout]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<MarketMarginFormData>({
    resolver: zodResolver(marketMarginSchema),
    defaultValues: {
      marketMargin: undefined,
    },
  });

  const resetAddForm = () => {
    setIsAddingMargin(false);
    reset();
  };

  const handleAddMargin = (data: MarketMarginFormData) => {
    createData({ marketMargin: Number(100 - data.marketMargin) }, () => {
      resetAddForm();
    });
  };

  const handleDelete = (margin: number) => {
    deleteData(margin.toString());
  };

  return (
    <Stack>
      <TableContainer sx={styles.container}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>Margin Percentage</TableCell>
              <TableCell align='center'>Market Margin</TableCell>
              <TableCell sx={styles.actionColumn}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={styles.tableBody}>
            {isLoading ? (
              <TableRow>
                <TableCell align='center' colSpan={2}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              data
                ?.sort((a, b) => a - b)
                .map((margin) => (
                  <TableRow key={margin}>
                    <TableCell>{`${100 - margin}%`}</TableCell>
                    <TableCell>{(margin / 100).toFixed(2)}</TableCell>
                    <TableCell>
                      <IconButton color='error' onClick={() => handleDelete(margin)}>
                        <HighlightOff />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      {isAddingMargin ? (
        <Stack direction='row' px={0.5} pt={2}>
          <FormNumberInput
            control={control}
            name='marketMargin'
            InputProps={{
              endAdornment: <InputAdornment position='end'>%</InputAdornment>,
              inputProps: { sx: styles.input },
            }}
            autoFocus
            error={errors.marketMargin}
          />
          <Box p={1}>
            <IconButton color='primary' onClick={handleSubmit(handleAddMargin)} disabled={isMutating || !isValid}>
              {isMutating ? <CircularProgress size={16} /> : <Save />}
            </IconButton>
            <IconButton color='error' onClick={() => resetAddForm()}>
              <Clear />
            </IconButton>
          </Box>
        </Stack>
      ) : (
        <Button variant='text' startIcon={<AddCircle />} onClick={() => setIsAddingMargin(true)} sx={styles.submitBtn}>
          Add new
        </Button>
      )}
    </Stack>
  );
};

export default MarketMarginTable;
