import { Close } from '@mui/icons-material';
import { Box, Chip, SelectProps, Typography } from '@mui/material';
import { Control, FieldError, FieldValues, Merge, Path } from 'react-hook-form';
import { SelectOption, StyleObj } from '../../@types';
import FormSelect from './FormSelect';

const styles: StyleObj = {
  chipContainer: { display: 'flex', flexWrap: 'wrap', gap: 0.5 },
};

type Props<T extends FieldValues> = Omit<SelectProps, 'error'> & {
  control: Control<T>;
  name: Path<T>;
  label?: string;
  options: SelectOption[];
  onStakeRangeDelete?: (value: string) => void;
  error?: FieldError | Merge<FieldError, (FieldError | undefined)[]>;
};

const StakeRangeSelect = <T extends FieldValues>({
  control,
  label,
  name,
  options,
  onStakeRangeDelete,
  error,
  ...rest
}: Props<T>) => {
  return (
    <FormSelect
      label={label ?? 'Stake range'}
      name={name}
      options={options}
      control={control}
      closeOnSelect
      error={error}
      renderValue={(selected) => {
        if (Array.isArray(selected)) {
          return (
            <Box sx={styles.chipContainer}>
              {selected.filter(Boolean).map((value: string) => {
                const option = options.find((opt) => opt.id === value);
                return (
                  <Chip
                    key={value}
                    label={option ? option.name : value}
                    onDelete={() => {
                      !rest.disabled && onStakeRangeDelete?.(value);
                    }}
                    deleteIcon={<Close onMouseDown={(event) => event.stopPropagation()} />}
                  />
                );
              })}
            </Box>
          );
        } else {
          const option = options.find((opt) => opt.id === selected);
          return <Typography>{option?.name}</Typography>;
        }
      }}
      {...rest}
    />
  );
};

export default StakeRangeSelect;
