import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DialogType } from '../../../../@types';
import { ConditionType } from '../../../../@types/api';
import { CONDITION_ENTITY_LABELS, QUERY_KEYS } from '../../../../constants';
import { useModal } from '../../../../contexts/ModalContext';
import useMutateData from '../../../../hooks/useMutateData';
import { ConditionTypesFormData, conditionTypeSchema } from '../../../../schema';
import FormModalLayout from '../../../layouts/FormModalLayout';

const DEFAULT_FORM_DATA = {
  name: '',
  restrictionMsgAdmin: '',
  verificationMsgAdmin: '',
  restrictionMsgClient: '',
  verificationMsgClient: '',
  description: '',
};

type Props = DialogType;

const EditConditionTypeForm = ({ closeModal }: Props) => {
  const { item } = useModal<ConditionType>();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ConditionTypesFormData>({
    defaultValues: DEFAULT_FORM_DATA,
    resolver: zodResolver(conditionTypeSchema),
  });

  useEffect(() => {
    if (item) {
      reset({
        ...item,
        name: `${CONDITION_ENTITY_LABELS[item.modelType]} - ${item.name}`,
      });
    }
  }, [item, reset]);

  const { updateData: updateConditionType } = useMutateData('condition-types', [QUERY_KEYS.conditionTypes]);

  const handleClose = () => {
    closeModal?.();
    reset(DEFAULT_FORM_DATA);
  };

  const onFormSubmit = (payload: ConditionTypesFormData) => {
    if (!item) return;

    updateConditionType(item.id, payload, () => {
      handleClose();
    });
  };

  return (
    <FormModalLayout onSave={handleSubmit(onFormSubmit)} label='Edit' onClose={handleClose}>
      <TextField error={!!errors.name} helperText={errors.name?.message} label='Name' {...register('name')} disabled />
      <TextField
        error={!!errors.verificationMsgAdmin}
        helperText={errors.verificationMsgAdmin?.message}
        label='Verification message admin'
        {...register('verificationMsgAdmin')}
        multiline
      />
      <TextField
        error={!!errors.verificationMsgClient}
        helperText={errors.verificationMsgClient?.message}
        label='Verification message client'
        {...register('verificationMsgClient')}
        multiline
      />
      <TextField
        error={!!errors.restrictionMsgAdmin}
        helperText={errors.restrictionMsgAdmin?.message}
        label='Restriction message admin'
        {...register('restrictionMsgAdmin')}
        multiline
      />
      <TextField
        error={!!errors.restrictionMsgClient}
        helperText={errors.restrictionMsgClient?.message}
        label='Restriction message client'
        {...register('restrictionMsgClient')}
        multiline
      />
      <TextField
        error={!!errors.description}
        helperText={errors.description?.message}
        label='Description'
        multiline
        rows={4}
        {...register('description')}
      />
    </FormModalLayout>
  );
};

export default EditConditionTypeForm;
