import { MenuItem, MenuItemProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledMuiMenuItem = styled(MenuItem)(({ theme }) => ({
  paddingLeft: '0.875rem',
  paddingRight: '0.875rem',
  paddingTop: '0.25rem',
  paddingBottom: '0.25rem',
  fontSize: 14,
  gap: '0.5rem',
  display: 'flex',
  alignItems: 'center',
  lineHeight: '1.5rem',
  '&:hover': {
    backgroundColor: theme.palette.background.lighter,
  },
}));

const StyledMenuItem = (props: MenuItemProps) => {
  return <StyledMuiMenuItem {...props} />;
};

export default StyledMenuItem;
