import { GridColDef, GridColumnVisibilityModel, GridValidRowModel } from '@mui/x-data-grid';
import { isEmpty } from 'lodash-es';
import { useEffect, useState } from 'react';
import { LS_KEYS } from '../constants';
import { useLocation } from 'react-router-dom';
import usePersist from './usePersist';

type Props = {
  manualLSKey?: string;
  columns: GridColDef<GridValidRowModel>[];
  defaultVisibleColumns: GridColumnVisibilityModel;
};

const useVisibleColumns = ({ manualLSKey, columns, defaultVisibleColumns }: Props) => {
  const [visibleColumns, setVisibleColumns] = useState<GridColumnVisibilityModel>({});
  const { pathname } = useLocation();
  const visibleColumnsLSKey = manualLSKey ?? `${LS_KEYS.visibleColumns}-${pathname}`;
  const { getPersistData, setPersistData } = usePersist(false);

  const visibleColumnsLS = getPersistData<GridColumnVisibilityModel>(visibleColumnsLSKey);

  // set visible columns from local storage or based on defaultVisibleColumns
  useEffect(() => {
    if (!isEmpty(visibleColumnsLS)) {
      setVisibleColumns(visibleColumnsLS);
    } else {
      const visibleColumnsInitial = columns.reduce((acc, column) => {
        return {
          ...acc,
          [column.field]: defaultVisibleColumns[column.field],
        };
      }, {});

      setVisibleColumns(visibleColumnsInitial);
      setPersistData<GridColumnVisibilityModel>(visibleColumnsLSKey, visibleColumnsInitial);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  return {
    visibleColumns,
    setVisibleColumns,
    visibleColumnsLSKey,
  };
};

export default useVisibleColumns;
