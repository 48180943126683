import { Navigate, NavigateProps } from 'react-router-dom';
import usePermissions from '../../hooks/usePermissions';
import { NavigationTabOption } from '../../@types';

type Props = Omit<NavigateProps, 'to'> & {
  tabs: NavigationTabOption[];
  fallback?: string;
};

const ProtectedNavigate = ({ tabs, fallback = '/', ...props }: Props) => {
  const { hasPermission } = usePermissions();

  const defaultTab = tabs.find((tab) => !tab.permission || hasPermission(tab.permission));

  return <Navigate to={defaultTab ? `./${defaultTab.value}` : fallback} {...props} />;
};

export default ProtectedNavigate;
