import { PropsWithChildren, ReactElement, cloneElement, createContext, useContext, useState } from 'react';
import StyledDialog from '../components/atoms/StyledDialog';

type ModalState<T> = {
  isOpen: boolean;
  component?: ReactElement;
  item?: T;
};

export type OpenModal<T> = (content: ReactElement, item?: T) => void;

type ModalContextType<T> = {
  openModal: OpenModal<T>;
  closeModal: () => void;
  item?: T;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ModalContext = createContext<ModalContextType<any> | null>(null);

const initialState = {
  isOpen: false,
  component: undefined,
  item: undefined,
};
export const ModalProvider = <T,>({ children }: PropsWithChildren) => {
  const [state, setState] = useState<ModalState<T>>({ isOpen: false });

  const openModal: OpenModal<T> = (component, item) => {
    setState({ component, isOpen: true, item });
  };

  const closeModal = () => {
    setState(initialState);
  };

  const handleClose = (event: object, reason: string) => {
    if (reason !== 'backdropClick') {
      closeModal?.();
    }
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal, item: state.item }}>
      {children}
      <StyledDialog open={state.isOpen} onClose={handleClose} transitionDuration={{ exit: 0 }}>
        {state.component && cloneElement(state.component, { closeModal })}
      </StyledDialog>
    </ModalContext.Provider>
  );
};

export const useModal = <T,>() => {
  const context = useContext(ModalContext) as ModalContextType<T> | null;

  if (context === null) {
    throw new Error('useModal must be used within a Modal Provider');
  }
  return context;
};
