import { GetApp } from '@mui/icons-material';
import { GridCsvExportMenuItem, GridToolbarExportContainer } from '@mui/x-data-grid';
import { exportStyles } from '../../utils/export';
import ExcelExportMenuOption from '../atoms/export/ExcelExportMenuOption';
import PrintExportMenuOption from '../atoms/export/PrintExportMenuOption';
import StyledIcon from '../atoms/StyledIcon';
import { Tooltip } from '@mui/material';

const CustomToolbar = (showSearch: boolean, showCustomizeTable: boolean) => {
  return (
    <Tooltip placement='top-start' title='Export'>
      <GridToolbarExportContainer
        variant='text'
        sx={exportStyles(showSearch, showCustomizeTable)}
        startIcon={<StyledIcon Icon={GetApp} />}
      >
        <GridCsvExportMenuItem />
        <PrintExportMenuOption />
        <ExcelExportMenuOption />
      </GridToolbarExportContainer>
    </Tooltip>
  );
};

export default CustomToolbar;
