import { Dialog, styled } from '@mui/material';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    minWidth: 416,
    maxWidth: 700,
    padding: theme.spacing(3),
    borderRadius: theme.spacing(4),
  },
  '& .MuiDialogActions-root': {
    paddingRight: theme.spacing(2),
  },
  // Workaround for https://github.com/mui/material-ui/issues/29892
  '& .MuiDialogContent-root': {
    paddingTop: `${theme.spacing(1)} !important`,
  },
}));

export default StyledDialog;
