import SvgIcon from '@mui/material/SvgIcon';
import type { IconType } from '../../@types';
import { ICON_MAPS } from '../../config/icons';
import { capitalize } from '@mui/material';

type Props = {
  type: IconType;
  name: string;
};

const IconRenderer = ({ type, name }: Props) => {
  const IconComponent = ICON_MAPS[type][name];

  if (!IconComponent) {
    return null;
  }

  return <SvgIcon component={IconComponent} inheritViewBox title={capitalize(name)} />;
};

export default IconRenderer;
