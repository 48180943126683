import { QueryKey, useMutation } from '@tanstack/react-query';
import { ApiError } from '../@types';
import { useToast } from '../contexts/ToastContext';
import { ApiService, deleteData, MethodsHeaders, patchData, postData } from '../utils/api';
import { useInvalidateQuery } from './useInvalidateQuery';
import { useToastErrorMessage } from './useToastErrorMessage';
import { RawAxiosRequestHeaders, AxiosHeaders } from 'axios';

const useMutateData = (
  path: string,
  queryKey: QueryKey,
  apiService?: ApiService,
  disableSnackbar = false,
  headers?: (RawAxiosRequestHeaders & MethodsHeaders) | AxiosHeaders
) => {
  const { handleErrorMessage } = useToastErrorMessage();
  const { enqueueSnackbar } = useToast();
  const invalidateData = useInvalidateQuery();

  const handleSnackbar = (msg: string) => {
    if (disableSnackbar) {
      return;
    } else {
      enqueueSnackbar({ message: msg || 'Item deleted successfully.', variant: 'success' });
    }
  };

  const createMutation = useMutation(
    async (data: unknown) => {
      await postData(path, data, apiService, headers);
    },
    {
      onSuccess: async () => {
        await invalidateData(queryKey);
      },
      onError: (error: ApiError) => () => apiService !== 'kc' && handleErrorMessage(error),
    }
  );

  const deleteMutation = useMutation(
    async (id: string) => {
      await deleteData(`${path}/${id}`, apiService);
    },
    {
      onSuccess: async () => {
        await invalidateData(queryKey);
      },
      onError: (error: ApiError) => handleErrorMessage(error),
    }
  );

  const editMutation = useMutation(
    async (params: { id: string; data: unknown }) => {
      const { id, data } = params;
      await patchData(`${path}/${id}`, data, apiService);
    },
    {
      onSuccess: async () => {
        await invalidateData(queryKey);
      },
      onError: (error: ApiError) => {
        return handleErrorMessage(error);
      },
    }
  );

  const isLoading = createMutation.isLoading || deleteMutation.isLoading || editMutation.isLoading;

  return {
    createData: (data: unknown, onSuccess?: () => void, successMessage?: string, onSettled?: () => void) =>
      createMutation.mutate(data, {
        onSuccess: async () => {
          handleSnackbar(successMessage || 'Created successfully.');
          onSuccess?.();
        },
        onSettled: () => {
          onSettled?.();
        },
      }),
    createDataAsync: async (data: unknown, onSuccess?: () => void, successMessage?: string) => {
      await createMutation.mutateAsync(data);
      handleSnackbar(successMessage || 'Created successfully.');

      onSuccess?.();
    },
    deleteData: (id: string, onSuccess?: () => void, successMessage?: string) =>
      deleteMutation.mutate(id, {
        onSuccess: async () => {
          handleSnackbar(successMessage || 'Deleted successfully.');

          onSuccess?.();
        },
      }),
    updateData: (id: string, data: unknown, onSuccess?: () => void, successMessage?: string) =>
      editMutation.mutate(
        { id, data },
        {
          onSuccess: async () => {
            handleSnackbar(successMessage || 'Updated successfully.');
            onSuccess?.();
          },
        }
      ),
    isLoading,
  };
};

export default useMutateData;
