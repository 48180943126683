import { DeleteOutlined, EditOutlined } from '@mui/icons-material';
import { Button, IconButton, Stack, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { StyleObj } from '../../@types';
import { CRMApiEntityType, Note } from '../../@types/api';
import { NOTE_TYPES, NOTE_TYPE_OPTIONS, QUERY_KEYS } from '../../constants';
import { useModal } from '../../contexts/ModalContext';
import useMutateData from '../../hooks/useMutateData';
import CustomerDetailBadge from '../atoms/CustomerDetailBadge';
import ConfirmationModal from '../modals/ConfirmationModal';
import TypeSelect from './TypeSelect';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import { USER_TYPE_TO_CRM_API_ENTITY_MAP } from '../../config/crm';

const styles: StyleObj = {
  container: {
    background: '#fff',
    border: '1px solid rgba(0, 83, 55, 0.20)',
    borderRadius: '6px',
    padding: '8px 12px',
  },
  circle: {
    minWidth: '4px',
    height: '4px',
    borderRadius: '50%',
    background: (theme) => theme.palette.grey[500],
    margin: '0 8px 3px 8px',
  },
  dateText: {
    fontWeight: 600,
    fontSize: 12,
  },
  editedText: {
    color: '#A7BFA7',
    fontSize: 12,
    fontStyle: 'italic',
  },
  editTextField: {
    typography: 'h6',
    p: 1,
  },
  editButtonsWrapper: {
    display: 'flex',
    gap: 1,
    justifyContent: 'end',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};

type Props = {
  note: Note;
  userType: CRMApiEntityType;
};

type EditNoteData = Pick<Note, 'comment' | 'type'>;

const CRMNote = ({ note, userType }: Props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { openModal } = useModal();
  const invalidateData = useInvalidateQuery();

  const apiEntity = USER_TYPE_TO_CRM_API_ENTITY_MAP[userType];

  const { deleteData, updateData, isLoading } = useMutateData(
    `notes/${apiEntity}`,
    [QUERY_KEYS.crmNotes, apiEntity],
    'crm'
  );

  const { comment, createdAt, updatedAt, author, type } = note;

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      comment,
      type,
    },
  });

  const displayDate = dayjs(updatedAt).format('DD.MM.YYYY.');
  const isEdited = createdAt !== updatedAt;

  const editNote = ({ comment, type }: EditNoteData) => {
    updateData(
      note.id,
      { comment, type },
      () => {
        setIsEditMode(false);
        invalidateData([`notes/${apiEntity}`]);
      },
      'Note updated successfully'
    );
  };
  const deleteNote = () => {
    deleteData(
      note.id,
      () => {
        setIsEditMode(false);
        invalidateData([`notes/${apiEntity}`]);
      },
      'Note deleted successfully'
    );
  };

  const openConfirmationModal = () => {
    openModal(<ConfirmationModal handleConfirm={deleteNote} />);
  };

  return (
    <Stack spacing={1} sx={styles.container}>
      <Box sx={styles.flexContainer}>
        <Stack direction='row' spacing={1} alignItems='center'>
          {isEditMode ? (
            <TypeSelect control={control} name='type' error={errors.type} options={NOTE_TYPE_OPTIONS} />
          ) : (
            <CustomerDetailBadge label={NOTE_TYPES[type]} />
          )}
          <Box sx={styles.circle} />
          <Typography component='span' sx={styles.dateText}>
            {displayDate}
          </Typography>
          {isEdited && (
            <Typography component='span' sx={styles.editedText}>
              (edited)
            </Typography>
          )}
        </Stack>
        <Box>
          <IconButton onClick={() => setIsEditMode((prev) => !prev)} size='small'>
            <EditOutlined color={isEditMode ? 'primary' : 'inherit'} />
          </IconButton>
          <IconButton onClick={openConfirmationModal} size='small' disabled={isEditMode}>
            <DeleteOutlined />
          </IconButton>
        </Box>
      </Box>

      <Typography variant='h6' fontWeight={600}>
        {author}
      </Typography>

      <Stack spacing={1}>
        {isEditMode ? (
          <>
            <TextField
              {...register('comment')}
              multiline
              InputProps={{
                sx: styles.editTextField,
              }}
            />
            <Box sx={styles.editButtonsWrapper}>
              <Button variant='outlined' onClick={() => setIsEditMode(false)}>
                Cancel
              </Button>
              <Button onClick={handleSubmit(editNote)} disabled={isLoading || !isDirty}>
                Apply
              </Button>
            </Box>
          </>
        ) : (
          <Typography variant='h6'>{comment}</Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default CRMNote;
