export const getAuthUrl = () => {
  const hostname = window.location.hostname;
  const hostnameSegments = hostname.split('.');
  if (hostnameSegments.length > 2) {
    hostnameSegments[0] = 'auth';
  } else {
    hostnameSegments.unshift('auth');
  }
  return 'https://' + hostnameSegments.join('.');
};
