import { useQuery } from '@tanstack/react-query';
import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import {
  CompetitionList,
  ConditionEntity,
  ConditionEntityType,
  Customer,
  EventList,
  MarketTypeList,
  PaginatedData,
  SportList,
  TournamentList,
} from '../../@types/api';
import { QUERY_KEYS } from '../../constants';
import { useModal } from '../../contexts/ModalContext';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import useMutateData from '../../hooks/useMutateData';
import { ConditionFormData } from '../../schema';
import { getData } from '../../utils/api';
import ConditionsValuesForm from './forms/conditions/ConditionsValuesForm';

const CONDITION_ENTITY_MAP: Record<string, ConditionEntityType> = {
  sports: 'sport',
  competitions: 'competition',
  tournaments: 'tournament',
  punters: 'punter',
  tickets: 'ticket',
  markets: 'market_type',
  outrights: 'event',
  events: 'event',
  players: 'punter',
};

const ConditionsTab = () => {
  const { item, closeModal } = useModal<
    SportList | CompetitionList | TournamentList | MarketTypeList | EventList | Customer
  >();
  const location = useLocation();
  const invalidateData = useInvalidateQuery();

  const entity = location.pathname.split('/').pop();

  const isPunter = item && 'userId' in item;

  /* 
    Since CRM-API doesn't return condition data, we have to fetch it separately.
    This is only needed for punter conditions and enabled only on the '/crm/players' route
  */
  const { data: punterConditionData } = useQuery({
    queryKey: [QUERY_KEYS.conditions, isPunter && item.userId],
    queryFn: (): Promise<PaginatedData<ConditionEntity>> =>
      getData('conditions', {
        type: 'punter',
        isActive: item?.isActive,
        punterId: isPunter && item.userId,
      }),
    enabled: entity === 'players' && isPunter,
    select: (data) => data.items[0].conditions,
  });

  const conditionModelType = CONDITION_ENTITY_MAP[entity as ConditionEntityType];
  const conditionGroupId = isPunter ? punterConditionData?.conditionGroupId : item?.conditionGroupId;
  const modelId = isPunter ? item?.userId : item?.id;

  const { createData: createCondition } = useMutateData('conditions', [QUERY_KEYS.conditions]);

  const onSave = async (data: ConditionFormData) => {
    createCondition(data, () => {
      closeModal();
      invalidateData([entity]);
    });
  };

  return (
    <Fragment>
      <ConditionsValuesForm
        onCancel={closeModal}
        onSave={onSave}
        conditionGroupId={conditionGroupId || null}
        conditionModelType={conditionModelType}
        modelId={modelId}
      />
    </Fragment>
  );
};

export default ConditionsTab;
