import { DataGrid, GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { StyleObj } from '../../@types';
import { Bet } from '../../@types/api';

const styles: StyleObj = {
  dataGrid: {
    border: 'none',
    '& .MuiDataGrid-withBorderColor': {
      border: 'none',
    },
    '& .MuiDataGrid-columnHeader': {
      border: '1px solid #00533720',
      background: (theme) => theme.palette.background.lightGreen,
      '&:not(:first-of-type)': {
        borderLeft: 'none',
      },
    },
    '& .MuiDataGrid-cell': {
      border: 'none',
      fontWeight: 500,
    },
    '& .MuiDataGrid-row:nth-of-type(even)': {
      backgroundColor: 'background.light',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    '& .MuiDataGrid-columnHeaderTitle': (theme) => ({
      ...theme.typography.h5,
    }),
    '& .MuiDataGrid-columnHeader:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-columnHeaders': {
      borderRadius: 0,
    },
    '& .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none',
    },
    '& .MuiDataGrid-columnHeaders:hover .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
    '& .MuiFormControl-root': {
      alignItems: 'center',
    },
  },
};

type Props = {
  columns: GridColDef[];
  loading: boolean;
  visibleColumns: GridColumnVisibilityModel;
  bets: Bet[];
};

const TicketAuthDetailsDataGrid = ({ columns, loading, visibleColumns, bets }: Props) => {
  return (
    <DataGrid
      columns={columns}
      rows={bets}
      columnVisibilityModel={visibleColumns}
      loading={loading}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      disableRowSelectionOnClick
      pageSizeOptions={[20]}
      rowHeight={48}
      columnHeaderHeight={44}
      hideFooterPagination
      sx={styles.dataGrid}
      hideFooter
    />
  );
};

export default TicketAuthDetailsDataGrid;
