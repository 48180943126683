import { TextField, InputAdornment, TextFieldProps } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Merge, FieldError, UseFormRegister, FieldValues, Path } from 'react-hook-form';
import { useState } from 'react';

type PasswordFieldProps<T extends FieldValues> = Omit<TextFieldProps, 'error'> & {
  name: Path<T>;
  label: string;
  register: UseFormRegister<T>;
  error?: Merge<FieldError, (FieldError | undefined)[]>;
};

const PasswordField = <T extends FieldValues>({ name, label, register, error, ...rest }: PasswordFieldProps<T>) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      label={label}
      {...register(name)}
      error={!!error}
      helperText={error?.message}
      InputProps={{
        endAdornment: (
          <InputAdornment sx={{ cursor: 'pointer' }} onClick={toggleVisibility} position='end'>
            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};
export default PasswordField;
