import { Box, Menu } from '@mui/material';
import { StyleObj } from '../../@types';
import { BetStatus } from '../../@types/api';
import { RESOLVE_STATUS_OPTIONS } from '../../constants';
import BetStatusIcon from '../icons/BetStatusIcon';
import ManageMenuSection from './ManageMenuSection';

export type HandleMenuItemClickOption = {
  id: number | string;
  name: string;
};

export type HandleMenuItemClickField = 'manualControl' | 'isActive' | 'publishStatus';

type ResolveOutcomeMenuProps = {
  contextMenu: { mouseX: number; mouseY: number } | null;
  handleClose: () => void;
  handleItemClick: (option: HandleMenuItemClickOption) => void;
};

const styles: StyleObj = {
  menu: {
    '& .MuiMenu-list': {
      pt: 0,
      pb: 0.75,
    },
  },
  menuContent: { minWidth: 156 },
};

const ResolveOutcomeMenu = ({ contextMenu, handleClose, handleItemClick }: ResolveOutcomeMenuProps) => {
  return (
    <Menu
      open={!!contextMenu}
      onClose={handleClose}
      anchorReference='anchorPosition'
      anchorPosition={!!contextMenu ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      sx={styles.menu}
      slotProps={{
        root: {
          onContextMenu: (e) => {
            e.preventDefault();
            handleClose();
          },
        },
      }}
    >
      <Box sx={styles.menuContent}>
        <ManageMenuSection
          title='Manual Resolve'
          options={RESOLVE_STATUS_OPTIONS}
          handleItemClick={handleItemClick}
          iconRenderer={(id) => <BetStatusIcon status={id as BetStatus} />}
        />
      </Box>
    </Menu>
  );
};
export default ResolveOutcomeMenu;
