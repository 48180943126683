import { TextFieldProps } from '@mui/material';
import { Controller, FieldError, FieldValues, Merge, UseControllerProps, useController } from 'react-hook-form';
import NumberInput from '../atoms/NumberInput';

type FormNumberInputProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<TextFieldProps, 'error'> & {
    label?: string;
    error?: FieldError | Merge<FieldError, (FieldError | undefined)[]>;
    showHelperText?: boolean;
    allowNegative?: boolean;
    allowDecimals?: boolean;
    decimalPlaces?: number;
    valueAsString?: boolean;
  };

const FormNumberInput = <T extends FieldValues>({
  label,
  name,
  control,
  error,
  showHelperText = true,
  allowNegative = false,
  allowDecimals = false,
  decimalPlaces = 2,
  valueAsString = false,
  ...rest
}: FormNumberInputProps<T>) => {
  const {
    fieldState: { error: controlError },
  } = useController({ name, control });

  /* TODO: Refactor 
     - We can get the FieldError directly from the useController hook, there is no need to pass it as a prop. 
       Keep it for now, as to not break any existing code. Should retest and remove the prop in the future.
  */
  const fieldError = error ?? controlError;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <NumberInput
          error={!!fieldError}
          onChange={onChange}
          label={label || ''}
          value={value ?? ''}
          showHelperText={showHelperText}
          allowDecimals={allowDecimals}
          allowNegative={allowNegative}
          valueAsString={valueAsString}
          helperText={showHelperText ? fieldError?.message : undefined}
          {...rest}
        />
      )}
    />
  );
};

export default FormNumberInput;
