import { Box } from '@mui/material';
import { GridCellParams, GridColDef, GridValueGetterParams, MuiEvent } from '@mui/x-data-grid';
import { useState } from 'react';
import { StyleObj } from '../../../@types';
import { ConditionGroup, ConditionType } from '../../../@types/api';
import { CONDITION_ENTITY_LABELS, QUERY_KEYS } from '../../../constants';
import { createDescriptionColumn, createEditColumn } from '../../../helpers/columns';
import { defaultColumnsConditionGroups } from '../../../helpers/table';
import useMutateData from '../../../hooks/useMutateData';
import { usePagination } from '../../../hooks/usePagination';
import useSort from '../../../hooks/useSort';
import { ConditionTemplateFormData } from '../../../schema';
import { createColumn } from '../../../utils';
import ConditionsValuesForm from '../../organisms/forms/conditions/ConditionsValuesForm';
import TableTemplate from '../../templates/TableTemplate';

const makeStyles = (selectedRow: boolean): StyleObj => ({
  container: {
    display: 'grid',
    gridTemplateColumns: selectedRow ? '2fr 1fr' : '1fr',
    height: '100%',
    maxHeight: '100%',
    minHeight: 0,
    gap: 3,
  },
  createItemBtn: selectedRow
    ? {
        position: 'absolute',
        top: 8,
        right: 0,
        ml: 0,
      }
    : {},
  conditionsValuesFormWrapper: {
    mt: 11,
    backgroundColor: 'background.light',
    p: 3,
    borderRadius: 2,
    height: 'fit-content',
  },
});

const ConditionGroupsPage = () => {
  const [selectedGroup, setSelectedGroup] = useState<ConditionGroup | null>(null);

  const styles = makeStyles(!!selectedGroup);
  const { data, updateQueryParams, isFetching, isLoading, changeQuery } = usePagination<ConditionGroup>(
    'condition-groups',
    {
      page: 1,
      limit: 25,
      sort: 'modelType',
      order: 'ASC',
    }
  );

  const { createData } = useMutateData('condition-templates', [
    QUERY_KEYS.condtitionTemplates,
    { conditionGroupId: selectedGroup?.id },
  ]);

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  const { handleSort } = useSort(changeQuery);

  const conditionGroupColumns: GridColDef[] = [
    createColumn('id', 'ID', {
      sortable: false,
    }),
    createColumn('name', 'Name', { alwaysVisible: true }),
    createColumn('modelType', 'Entity', {
      alwaysVisible: true,
      valueGetter: ({ row }: GridValueGetterParams<ConditionType>) => CONDITION_ENTITY_LABELS[row.modelType],
    }),
    createDescriptionColumn(),
    createEditColumn(),
  ];

  const onSave = (values: ConditionTemplateFormData) => {
    createData({
      ...values,
      conditionGroupId: selectedGroup?.id,
      modelType: selectedGroup?.modelType,
    });
  };

  const onFormCancel = () => {
    setSelectedGroup(null);
  };

  const onCellClick = (params: GridCellParams<ConditionGroup>, event: MuiEvent<React.MouseEvent>) => {
    if (params.field !== 'edit') {
      setSelectedGroup(params.row);
    }
  };

  const slotProps = {
    row: {
      style: {
        cursor: 'pointer',
      },
    },
  };

  return (
    <Box sx={styles.container}>
      <TableTemplate
        rows={data?.items || []}
        rowCount={data?.count || 0}
        columns={conditionGroupColumns}
        defaultVisibleColumns={defaultColumnsConditionGroups}
        loading={isFetching || isLoading}
        handlePaginationModelChange={updateQueryParams}
        handleSearch={handleSearch}
        handleSort={handleSort}
        changeQuery={changeQuery}
        formType='conditions-groups'
        createItemBtnLabel='Add new group'
        onCellClick={onCellClick}
        rowSelectionModel={selectedGroup ? [selectedGroup.id] : []}
        createItemBtnStyle={styles.createItemBtn}
        slotProps={slotProps}
      />
      {selectedGroup && (
        <Box sx={styles.conditionsValuesFormWrapper}>
          <ConditionsValuesForm
            onCancel={onFormCancel}
            onSave={onSave}
            conditionGroupId={selectedGroup.id}
            conditionModelType={selectedGroup.modelType}
          />
        </Box>
      )}
    </Box>
  );
};

export default ConditionGroupsPage;
