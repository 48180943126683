import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { Control, FieldError, FieldValues, Merge, Path } from 'react-hook-form';
import { PaginatedData, Tournament } from '../../@types/api';
import { QUERY_KEYS } from '../../constants';
import { getData } from '../../utils/api';
import FormSelect from '../molecules/FormSelect';

type TournamentSelectProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  label?: string;
  required?: boolean;
  competitionIds?: string | string[];
  error?: Merge<FieldError, (FieldError | undefined)[]>;
  multiple?: boolean;
  closeMenuOnSelect?: boolean;
};

const TournamentSelect = <T extends FieldValues>({
  control,
  name,
  label,
  required,
  competitionIds,
  error,
  multiple,
  closeMenuOnSelect,
}: TournamentSelectProps<T>) => {
  const { data: tournamentsData } = useQuery([QUERY_KEYS.tournaments, competitionIds], {
    queryFn: (): Promise<PaginatedData<Tournament>> =>
      getData('tournaments', {
        limit: 100,
        page: 1,
        competitionIds,
      }),
    enabled: !isEmpty(competitionIds),
  });

  return (
    <FormSelect
      label={label ?? 'Tournament'}
      name={name}
      control={control}
      required={required}
      options={tournamentsData?.items || []}
      disabled={isEmpty(competitionIds)}
      error={error}
      multiple={multiple}
      closeOnSelect={closeMenuOnSelect}
    />
  );
};

export default TournamentSelect;
