import { Stack, TypographyVariant } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CRMApiEntityType, UserBalance } from '../../@types/api';
import { getData } from '../../utils/api';
import BettingInfoDisplay from '../atoms/BettingInfoDisplay';
import { QUERY_KEYS } from '../../constants';
import { useAuth } from 'neofusion-fe-shared';

const typographyVariantMap: Record<string, TypographyVariant> = {
  normal: 'subtitle1',
  small: 'body2',
};

type Props = {
  userType: CRMApiEntityType;
  userId?: string;
  variant?: 'small' | 'normal';
};

const formatBalance = (balance: number | undefined) => balance?.toFixed(2).toString() || '0.00';

const UserBalanceDisplay = ({ userType, userId, variant = 'normal' }: Props) => {
  const { userId: loggedInUserId } = useAuth();

  const { data: balanceData } = useQuery<UserBalance>([QUERY_KEYS.userBalance, userId], {
    queryFn: () =>
      getData(
        'wallets/user-balances',
        {
          userId: userId ?? loggedInUserId,
          userType,
        },
        'wallet'
      ),
  });

  const hasBonusWallet = userType === 'customer';

  const mainWalletBalance = formatBalance(balanceData?.main?.balance);
  const bonusWalletBalance = formatBalance(balanceData?.bonus?.balance);

  return (
    <Stack direction='row' alignItems='center' spacing={2} flex={1}>
      <BettingInfoDisplay
        label='Main balance'
        value={mainWalletBalance}
        backgroundColor='#EFFFFA'
        borderColor='#55CDA9'
        variant={typographyVariantMap[variant]}
      />
      {hasBonusWallet && (
        <BettingInfoDisplay
          label='Bonus balance'
          value={bonusWalletBalance}
          backgroundColor='background.lightBlue'
          borderColor='#2B7FFE'
          variant={typographyVariantMap[variant]}
        />
      )}
    </Stack>
  );
};

export default UserBalanceDisplay;
