import { Close } from '@mui/icons-material';
import { Button, IconButton, styled } from '@mui/material';
import { MaterialDesignContent, SnackbarKey, SnackbarProvider, closeSnackbar, useSnackbar } from 'notistack';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

const StyledSnackBar = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#2e7d32',
  },
}));

export const ToastProvider = ({ children }: PropsWithChildren) => {
  return (
    <SnackbarProvider
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      maxSnack={5}
      hideIconVariant
      action={(snackbarId) => (
        <IconButton color='inherit' onClick={() => closeSnackbar(snackbarId)}>
          <Close fontSize='small' />
        </IconButton>
      )}
      Components={{
        success: StyledSnackBar,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export const useToast = () => {
  const notistackContext = useSnackbar();
  const navigate = useNavigate();

  const closeSnackbarAndNavigate = (to: string, snackbarId: SnackbarKey, label = 'OPEN') => (
    <Button
      variant='text'
      color='inherit'
      onClick={() => {
        closeSnackbar(snackbarId);
        navigate(to);
      }}
    >
      {label}
    </Button>
  );

  return {
    closeSnackbarAndNavigate,
    ...notistackContext,
  };
};
