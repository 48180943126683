import { FormControl, MenuItem, Select, SelectProps, styled } from '@mui/material';
import { SelectOption } from '../../@types';

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiSelect-select': {
    textAlign: 'center',
    ...theme.typography.body3,
    fontWeight: 500,
  },
  width: '100%',
  display: 'flex',
  alignItems: 'center',
}));

const styles = {
  container: {
    width: '100%',
    '& .MuiSelect-icon': {
      color: 'primary.main',
    },
  },
  menu: {
    '& .MuiPaper-root': {
      maxHeight: 500,
    },
  },
  menuItem: {
    typography: 'body3',
  },
};

type SelectCellProps = Omit<SelectProps, 'onChange'> & {
  defaultSelectValue: string | number;
  options: SelectOption[];
  onChange: (value: string | number) => void;
};

const SelectCell = ({ defaultSelectValue, options, onChange, ...props }: SelectCellProps) => {
  return (
    <FormControl sx={styles.container}>
      <StyledSelect
        value={defaultSelectValue}
        onChange={(e) => onChange(e.target.value as string | number)}
        MenuProps={{
          sx: styles.menu,
        }}
        {...props}
      >
        {options.map((menuItem) => (
          <MenuItem key={menuItem.id} value={menuItem.id} sx={styles.menuItem}>
            {menuItem.name}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default SelectCell;
