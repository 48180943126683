import { useQuery } from '@tanstack/react-query';
import { Control, FieldError, FieldValues, Merge, Path } from 'react-hook-form';
import { PaginatedData, RiskFactor } from '../../@types/api';
import { QUERY_KEYS } from '../../constants';
import { getData } from '../../utils/api';
import FormSelect from '../molecules/FormSelect';

type RiskFactorSelectProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  label?: string;
  competitionIds?: string | string[];
  error?: Merge<FieldError, (FieldError | undefined)[]>;
  multiple?: boolean;
  closeMenuOnSelect?: boolean;
};

const RiskFactorSelect = <T extends FieldValues>({
  control,
  name,
  label,
  error,
  multiple,
  closeMenuOnSelect,
}: RiskFactorSelectProps<T>) => {
  const { data: riskFactorData } = useQuery([QUERY_KEYS.riskFactors], {
    queryFn: (): Promise<PaginatedData<RiskFactor>> => getData('risk-management/risk-factors'),
  });
  return (
    <FormSelect
      label={label ?? 'Risk Factor'}
      name={name}
      control={control}
      options={riskFactorData?.items || []}
      error={error}
      multiple={multiple}
      closeOnSelect={closeMenuOnSelect}
    />
  );
};

export default RiskFactorSelect;
