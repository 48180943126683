import React from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import { Box } from '@mui/material';

const styles = {
  iconWrapper: {
    fill: '#fff',
  },
  icon: {
    backgroundColor: 'primary.main',
    borderRadius: '50%',
    width: '2.5rem',
    height: '2.5rem',
    display: 'grid',
    placeItems: 'center',
  },
};

const StyledIcon = ({ Icon }: { Icon: SvgIconComponent }) => {
  return (
    <Box sx={styles.icon}>
      <Icon sx={styles.iconWrapper} />
    </Box>
  );
};

export default StyledIcon;
