import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import type { SwitchProps as MuiSwitchProps } from '@mui/material/Switch';
import { default as MuiSwitch } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { StyleObj } from '../../@types';
import { useModal } from '../../contexts/ModalContext';
import ConfirmationModal from '../modals/ConfirmationModal';

type SwitchProps = MuiSwitchProps & {
  label?: string;
  value: boolean;
  title?: string;
  handleConfirmationMessage?: () => string;
};

export const StyledSwitch = styled((props: MuiSwitchProps) => (
  <MuiSwitch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 52,
  height: 32,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 4,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      marginLeft: 8,
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 24,
    height: 24,
  },
  '& .MuiSwitch-track': {
    borderRadius: 32 / 2,
    backgroundColor: theme.palette.grey[500],
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const styles: StyleObj = {
  formControlLabel: {
    mx: 'auto',
  },
};

const Switch = ({ value, onChange, label, name, title, handleConfirmationMessage, ...rest }: SwitchProps) => {
  const { openModal, closeModal } = useModal();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (handleConfirmationMessage) {
      openModal(
        <ConfirmationModal
          title={handleConfirmationMessage()}
          actionButtonLabel='Yes'
          handleConfirm={() => onChange?.(event, checked)}
          closeModal={closeModal}
        />
      );
    } else {
      onChange?.(event, checked);
    }
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={<StyledSwitch checked={value} onChange={handleChange} {...rest} />}
        label={label}
        sx={styles.formControlLabel}
        name={name}
        title={title}
      />
    </FormGroup>
  );
};

export default Switch;
