import { Info } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { ConditionEntity, MarketType } from '../@types/api';
import TableIconContainer from '../components/atoms/TableIconContainer';
import { createStatusDisplayColumn } from '../helpers/columns';
import {
  defaultColumnsCompetitionConditions,
  defaultColumnsMarketConditions,
  defaultColumnsPunterConditions,
  defaultColumnsSportConditions,
  defaultColumnsTournamentConditions,
} from '../helpers/table';
import { createColumn } from '../utils';
import { getMarketTypeName } from '../helpers';

const commonColumns = {
  id: createColumn('id', 'ID', {
    sortable: false,
    alwaysVisible: true,
  }),
  conditionGroupName: createColumn('conditionGroupName', 'Name', {
    alwaysVisible: true,
    valueGetter: ({ row }: GridValueGetterParams<ConditionEntity>) => row.conditions?.conditionGroup?.name,
  }),
  description: createColumn('description', 'Description', {
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<ConditionEntity>) =>
      row.conditions?.conditionGroup?.description && (
        <TableIconContainer>
          <Tooltip title={row.conditions.conditionGroup.description}>
            <Info color="primary" />
          </Tooltip>
        </TableIconContainer>
      ),
  }),
  status: createStatusDisplayColumn(),
  sportName: createColumn('sportName', 'Sport', {
    alwaysVisible: true,
    valueGetter: ({ row }: GridValueGetterParams<ConditionEntity>) => row?.sport?.name,
  }),
};

const sportColumns: GridColDef[] = [
  commonColumns.id,
  commonColumns.conditionGroupName,
  createColumn('name', 'Sport', { alwaysVisible: true }),
  commonColumns.status,
  commonColumns.description,
];

const competitionColumns: GridColDef[] = [
  commonColumns.id,
  commonColumns.conditionGroupName,
  createColumn('name', 'Competition', { alwaysVisible: true }),
  commonColumns.sportName,
  commonColumns.status,
  commonColumns.description,
];

const tournamentColumns: GridColDef[] = [
  commonColumns.id,
  commonColumns.conditionGroupName,
  createColumn('name', 'Tournament', { alwaysVisible: true }),
  createColumn('competitionName', 'Competition', {
    alwaysVisible: true,
    valueGetter: ({ row }) => row?.competition?.name,
  }),
  createColumn('sportName', 'Sport', {
    alwaysVisible: true,
    valueGetter: ({ row }) => row?.competition?.sport?.name,
  }),
  commonColumns.status,
  commonColumns.description,
];

const punterColumns: GridColDef[] = [
  createColumn('userId', 'ID', { alwaysVisible: true }),
  commonColumns.conditionGroupName,
  createColumn('firstName', 'First Name'),
  createColumn('lastName', 'Last Name'),
  createColumn('username', 'Username', { alwaysVisible: true }),
  commonColumns.status,
  commonColumns.description,
];

const marketColumns: GridColDef[] = [
  commonColumns.id,
  commonColumns.conditionGroupName,
  createColumn('name', 'Market', {
    alwaysVisible: true,
    valueGetter: ({ row }: GridValueGetterParams<MarketType>) => getMarketTypeName(row),
  }),
  commonColumns.sportName,
  commonColumns.status,
  commonColumns.description,
];

export const CONDITION_ENTITY_CONFIG = {
  sport: {
    columns: sportColumns,
    defaultVisibleColumns: defaultColumnsSportConditions,
  },
  competition: {
    columns: competitionColumns,
    defaultVisibleColumns: defaultColumnsCompetitionConditions,
  },
  tournament: {
    columns: tournamentColumns,
    defaultVisibleColumns: defaultColumnsTournamentConditions,
  },
  punter: {
    columns: punterColumns,
    defaultVisibleColumns: defaultColumnsPunterConditions,
  },
  market_type: {
    columns: marketColumns,
    defaultVisibleColumns: defaultColumnsMarketConditions,
  },
};
