export default {
  name: 'CasinoBackgroundImage',
  title: 'Casino - Background Image',
  type: 'document',
  fields: [
    {
      name: 'image',
      title: 'Background Image',
      type: 'image',
      description: 'The image for the casino screen background',
      validation: (Rule: any) => Rule.required(),
    },
  ],
};
