import { Table as MuiTable, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { QueryKey } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { Fragment } from 'react';
import { StyleObj } from '../../@types';
import { EventBase, Market } from '../../@types/api';
import OutcomeTableMarket from '../molecules/OutcomeTableMarket';
import { getNameToDisplay } from '../../helpers';

const styles: StyleObj = {
  tableContainer: {
    width: '42vw',
    overflowX: 'auto',
    boxShadow: 'none',
  },
  tableHead: {
    '& .MuiTableCell-root': {
      backgroundColor: 'background.lightGreen',
      color: 'background.contrastText',
      border: '1px solid rgba(0, 83, 55, 0.20)',
      height: '44px',
      p: 0,
    },
  },
  participantCell: {
    height: '48px',
    p: 0,
    pl: 1.5,
  },
};

const sortByHomeParticipant = (a: string, _b: string, event: EventBase) => {
  const participantIdA = event.participants.find((participant) => participant.name === a)?.id;
  if (event.homeParticipantId) {
    return event.homeParticipantId === participantIdA ? -1 : 1;
  }
  return 0;
};

const sortBySpecialValues = (a: Market, b: Market) => {
  return (a.specialValues?.[0].value || 0) - (b.specialValues?.[0].value || 0);
};

type OutcomesTableProps = {
  event: EventBase;
  markets: Market[];
  queryKey: QueryKey;
  displayType?: 'single' | 'multiple';
  selectedRows: string[];
  handleSelectRow: (market: Market) => void;
  handleMarketContextMenu: (event: React.MouseEvent, market: Market) => void;
  handleMarketStatusUpdate: (id: string, isActive: boolean) => void;
};

const OutcomesTable = ({
  event,
  markets,
  queryKey,
  displayType,
  selectedRows,
  handleSelectRow,
  handleMarketContextMenu,
  handleMarketStatusUpdate,
}: OutcomesTableProps) => {
  const getParticipantName = (market: Market) => {
    const specialValueModel = market.specialValues?.[0]?.model;
    return specialValueModel?.playerTeamName ?? specialValueModel?.name;
  };

  const isDisplayMultiple = displayType === 'multiple';
  const hasSpecialValueWithPlayerModel = markets?.[0]?.specialValues?.some(
    (specialValue) => specialValue.modelType === 'player'
  );

  const hasSpecialValueModel = !isEmpty(markets?.find((market) => market.specialValues?.[0]?.model));

  const marketsWithSpecialValueModel: Market[] = [];
  const marketsWitoutModel: Market[] = [];

  markets?.forEach((market) => {
    if (market.specialValues?.[0]?.model) {
      marketsWithSpecialValueModel.push(market);
    } else {
      marketsWitoutModel.push(market);
    }
  });

  const marketsGroupedByParticapant: { [key: string]: Market[] } = {};

  if (hasSpecialValueModel) {
    marketsWithSpecialValueModel.forEach((market) => {
      const participantName = getParticipantName(market);

      if (participantName) {
        if (!marketsGroupedByParticapant[participantName]) {
          marketsGroupedByParticapant[participantName] = [];
        }
        marketsGroupedByParticapant[participantName].push(market);
      }
    });
  }

  const getColSpan = () => {
    let colSpan = 0;
    if (isDisplayMultiple) {
      colSpan += 3;
    }
    if (hasSpecialValueWithPlayerModel) {
      colSpan += 1;
    }
    colSpan += markets?.[0]?.outcomes?.length;
    colSpan += markets?.[0]?.specialValues?.length;
    return colSpan;
  };

  const renderMarket = (market: Market) => {
    return (
      <OutcomeTableMarket
        key={market.id}
        market={market}
        event={event}
        queryKey={queryKey}
        selectedRows={selectedRows}
        handleSelectRow={handleSelectRow}
        isDisplayMultiple={isDisplayMultiple}
        handleMarketContextMenu={handleMarketContextMenu}
        handleMarketStatusUpdate={handleMarketStatusUpdate}
        hasSpecialValueWithPlayerModel={hasSpecialValueWithPlayerModel}
      />
    );
  };

  const renderMarketsGroupedByParticipant = () => {
    return Object.keys(marketsGroupedByParticapant)
      .sort((a, b) => sortByHomeParticipant(a, b, event))
      .map((participantName) => {
        return (
          <Fragment key={participantName}>
            <TableRow>
              <TableCell align='left' colSpan={getColSpan()} sx={styles.participantCell}>
                {participantName}
              </TableCell>
            </TableRow>
            {marketsGroupedByParticapant[participantName]
              .sort(sortBySpecialValues)
              .map((market) => renderMarket(market))}
          </Fragment>
        );
      });
  };

  const renderMarkets = () => {
    return markets?.sort(sortBySpecialValues).map((market) => renderMarket(market));
  };

  return (
    <Fragment>
      <TableContainer component={Paper} sx={styles.tableContainer}>
        <MuiTable aria-label='events table'>
          <TableHead sx={styles.tableHead}>
            <TableRow>
              {isDisplayMultiple && (
                <>
                  <TableCell align='center' />
                  <TableCell align='center' />
                </>
              )}
              {markets?.[0]?.specialValues?.map((specialValue) => (
                <TableCell align='center' key={specialValue.id}>
                  SV - {specialValue.name}
                </TableCell>
              ))}
              {isDisplayMultiple && <TableCell align='center'>Status</TableCell>}
              {hasSpecialValueWithPlayerModel && <TableCell align='center'>Player</TableCell>}
              {markets?.[0]?.outcomes?.map((outcome) => (
                <TableCell align='center' key={outcome.id}>
                  {getNameToDisplay(outcome)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {!isEmpty(marketsWithSpecialValueModel) && !isDisplayMultiple && (
              <TableRow>
                <TableCell align='left' colSpan={getColSpan()} sx={styles.participantCell}>
                  {getParticipantName(marketsWithSpecialValueModel[0])}
                </TableCell>
              </TableRow>
            )}
            {!isEmpty(marketsGroupedByParticapant) && isDisplayMultiple
              ? renderMarketsGroupedByParticipant()
              : renderMarkets()}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Fragment>
  );
};

export default OutcomesTable;
