import { Bet, BettingSlipWithMonitoringInfo } from '../@types/api';
import { QUERY_KEYS } from '../constants';
import useMutateData from './useMutateData';

export type SpecialValueFormInput = {
  id: string;
  marketId: string;
  name: string;
  odds: string;
};

type TicketAuthorizationData = {
  totalStakeAmount: string;
  odds: Record<string, string>;
  specialValues: Record<string, SpecialValueFormInput[]>;
};

const useTicketAuthorization = (ticketDetails: BettingSlipWithMonitoringInfo | undefined) => {
  const ticketId = ticketDetails?.id;

  const { updateData, isLoading: isUpdating } = useMutateData('/betting-slips', [QUERY_KEYS.bettingSlips, ticketId]);

  const { createData, isLoading: isReoffering } = useMutateData(`/betting-slips/${ticketId}`, [
    QUERY_KEYS.bettingSlips,
    ticketId,
  ]);

  const rejectTicket = () => {
    ticketId && updateData(`${ticketId}/accept-status`, { accept: false }, undefined, 'Ticket rejected successfully');
  };

  const reofferTicket = (data: TicketAuthorizationData, bets: Bet[]) => {
    if (!ticketDetails) return;

    const { totalStakeAmount, odds, specialValues } = data;
    const { walletCurrency, walletType, requiredHitCount, combinationsCount } = ticketDetails;

    const totalStakeAmountFloat = parseFloat(totalStakeAmount);

    const specialValueBets = Object.entries(specialValues).map(([_key, specialValue]) => {
      const bet = bets.find((bet) => bet.id === _key);
      return {
        outcomeId: specialValue[0].id,
        odds: specialValue[0].odds,
        banker: bet?.banker,
        eventId: bet?.event.id,
        marketId: specialValue[0].marketId,
      };
    });

    const regularBets = Object.entries(odds).map(([outcomeId, odds]) => {
      const bet = bets.find((bet) => bet.outcome.id === outcomeId);
      return {
        outcomeId,
        odds,
        banker: bet?.banker,
        eventId: bet?.event.id,
        marketId: bet?.outcome.marketId,
      };
    });

    const betTypes = [
      {
        requiredHitCount: requiredHitCount,
        stakeAmountPerCombination: totalStakeAmountFloat / combinationsCount,
      },
    ];

    createData(
      {
        bets: [...specialValueBets, ...regularBets],
        betTypes,
        totalStakeAmount: totalStakeAmountFloat,
        walletCurrency,
        walletType,
      },
      undefined,
      'Ticket reoffered successfully'
    );
  };

  const acceptTicket = () => {
    ticketId && updateData(`${ticketId}/accept-status`, { accept: true }, undefined, 'Ticket accepted successfully');
  };

  return {
    isSubmitting: isUpdating || isReoffering,
    rejectTicket,
    reofferTicket,
    acceptTicket,
  };
};

export default useTicketAuthorization;
