import { Add, SettingsOutlined } from '@mui/icons-material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Button, Stack, SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGridProps, GridColumnVisibilityModel, GridSortModel } from '@mui/x-data-grid';
import { isEmpty } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { StyleObj } from '../../@types';
import { LS_KEYS } from '../../constants';
import { useModal } from '../../contexts/ModalContext';
import { AdditionalQueryParams } from '../../hooks/usePagination';
import usePersist from '../../hooks/usePersist';
import useVisibleColumns from '../../hooks/useVisibleColumns';
import FilterModal, { FilterTypes } from '../modals/FilterModal';
import FormModal, { FormTypes } from '../modals/FormModal';
import CustomizeTable from '../molecules/CustomizeTable';
import SearchTableData from '../molecules/SearchTableData';
import StyledDataGrid from '../organisms/StyledDataGrid';
import TableActionButton from '../atoms/TableActionButton';
import CustomToolbar from '../molecules/CustomToolbar';

type TableTemplateProps = DataGridProps & {
  handleSearch?: (value: string | null) => void;
  createItemBtnLabel?: string;
  createItemBtnStyle?: SxProps<Theme>;
  defaultVisibleColumns?: GridColumnVisibilityModel;
  handleDelete?: (id: string) => void;
  handlePaginationModelChange?: (model: { page: number; pageSize: number }) => void;
  handleSort?: (model: GridSortModel) => void;
  changeQuery?: (query: AdditionalQueryParams) => void;
  formType?: FormTypes;
  customTableHeaderContent?: JSX.Element;
  showFilter?: boolean;
  showCustomizeTable?: boolean;
  showExport?: boolean;
};

const makeStyles = (createItemBtnStyle: SxProps<Theme> = {}, showSearch: boolean): StyleObj => ({
  container: { minHeight: 0, minWidth: 0, flex: 1, height: '100%' },
  topContentWrapper: (theme) => ({
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
    display: 'flex',
    alignItems: 'center',
    marginBottom: 4,
    gap: '1rem',
    pl: showSearch ? 0 : 1,
  }),
  actionBtn: (theme) => ({
    [theme.breakpoints.up('lg')]: {
      ml: 'auto',
    },
    ...createItemBtnStyle,
  }),
});

const TableTemplate = (props: TableTemplateProps) => {
  const {
    rows,
    rowCount,
    columns,
    handleSort,
    handlePaginationModelChange,
    handleSearch,
    createItemBtnLabel,
    createItemBtnStyle,
    defaultVisibleColumns,
    handleDelete,
    changeQuery = () => void 0,
    formType,
    customTableHeaderContent,
    showFilter = true,
    showExport = false,
    ...rest
  } = props;
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });

  const showSearchBar = !!handleSearch;
  const styles = makeStyles(createItemBtnStyle, showSearchBar);

  const { visibleColumns, setVisibleColumns, visibleColumnsLSKey } = useVisibleColumns({
    columns,
    defaultVisibleColumns: defaultVisibleColumns || {},
  });

  const { openModal } = useModal();
  const { pathname } = useLocation();
  const { getPersistData, deletePersistData } = usePersist(false);

  const visibleColumnsLS = getPersistData<GridColumnVisibilityModel>(visibleColumnsLSKey);

  useEffect(() => {
    // remove LS filter data on page change
    const formDataKey = `${LS_KEYS.appFormFilter}-${pathname}`;
    deletePersistData(formDataKey);
  }, [deletePersistData, pathname]);

  const handleCreate = () => {
    formType && openModal(<FormModal form={formType} />);
  };

  const resetPaginationPage = () => {
    setPaginationModel((prev) => ({ ...prev, page: 0 }));
  };

  const onSearch = (value: string | null) => {
    if (handleSearch) {
      handleSearch(value?.trim() || null);
      resetPaginationPage();
    }
  };

  const onSortModelChange = (model: GridSortModel) => {
    if (!handleSort) return;

    resetPaginationPage();
    handleSort(model);
  };

  const onPaginationModelChange = (model: { page: number; pageSize: number }) => {
    if (!handlePaginationModelChange) return;
    setPaginationModel(model);
    handlePaginationModelChange(model);
  };

  const openCustomizationDialog = () => {
    if (!defaultVisibleColumns) return;

    openModal(
      <CustomizeTable
        columns={columns}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        visibleColumnsLSKey={visibleColumnsLSKey}
      />
    );
  };

  const openFilterDialog = () => {
    const page = pathname.split('/').pop();

    // Still not ideal, we have to take care of order of filters inside the map
    // eg. place 'events-results' before 'events' to avoid matching events filter on results page with .includes
    const filterMap: Record<string, FilterTypes> = {
      'product-reports/cashout': 'cashout-report',
      'product-reports/jackpot': 'jackpot-report',
      'ticket-monitoring': 'ticket-monitoring',
      'ticket-authorization': 'ticket-authorization',
      'product-reports/partners/agents': 'agents',
      'product-reports/partners/managers': 'managers',
      'wallet-transaction': 'wallet-transaction',
      'liability-monitoring': 'liability-monitoring',
      'payment-transaction': 'payment-transaction',
      'crm/players': 'crm',
      'product-reports/players': 'player-report',
      'product-reports/sportsbook': 'sportsbook-report',
      'payments/total': 'payments-total',
      'payments/list': 'payments-list',
      'product-reports/daily': 'daily',
      'publish-events': 'publish-events',
      'product-reports/bonus': 'bonus-reports',
      jackpot: 'jackpot',
      events: 'events',
      outrights: 'outrights',
      partners: 'partners',
      bonus: 'bonus',
    };

    let filter = '*';

    for (const [pattern, value] of Object.entries(filterMap)) {
      if (page === value) {
        filter = value;
        break;
      }
      if (pathname.includes(pattern)) {
        filter = value;
        break;
      }
    }

    openModal(<FilterModal filter={filter as FilterTypes} changeQuery={changeQuery} />);
  };
  return (
    <Stack sx={styles.container}>
      <Box component='div' sx={styles.topContentWrapper}>
        {showSearchBar && <SearchTableData onSearch={onSearch} />}
        <Stack direction='row'>
          {defaultVisibleColumns && (
            <TableActionButton onClick={openCustomizationDialog} Icon={SettingsOutlined} title='Customize Table' />
          )}
          {showFilter && <TableActionButton onClick={openFilterDialog} Icon={FilterAltOutlinedIcon} title='Filter' />}
        </Stack>
        {formType && (
          <Button variant='outlined' startIcon={<Add />} onClick={handleCreate} sx={styles.actionBtn}>
            {createItemBtnLabel}
          </Button>
        )}
      </Box>
      {customTableHeaderContent}
      <StyledDataGrid
        rows={rows}
        rowCount={rowCount}
        columns={columns}
        paginationModel={paginationModel}
        columnVisibilityModel={!isEmpty(visibleColumns) ? visibleColumns : visibleColumnsLS ?? defaultVisibleColumns}
        onPaginationModelChange={onPaginationModelChange}
        onSortModelChange={onSortModelChange}
        slots={{
          toolbar: showExport ? () => CustomToolbar(showSearchBar, !!defaultVisibleColumns) : null,
        }}
        disableVirtualization
        {...rest}
      />
    </Stack>
  );
};

export default TableTemplate;
