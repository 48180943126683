import { EditOutlined } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import { Box, IconButton, Tooltip } from '@mui/material';
import {
  GridCellParams,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridValueGetterParams,
  MuiEvent,
  type GridColDef,
} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { StyleObj } from '../../@types';
import { Event, EventList } from '../../@types/api';
import { LS_KEYS, QUERY_KEYS } from '../../constants';
import { useModal } from '../../contexts/ModalContext';
import { getLatestValue } from '../../helpers';
import { defaultColumnsOutrights } from '../../helpers/table';
import useMutateData from '../../hooks/useMutateData';
import { usePagination } from '../../hooks/usePagination';
import usePersist from '../../hooks/usePersist';
import useSort from '../../hooks/useSort';
import { createColumn } from '../../utils';
import IconRenderer from '../atoms/IconRenderer';
import Switch from '../atoms/Switch';
import TableIconContainer from '../atoms/TableIconContainer';
import { ManualIcon } from '../icons';
import PublishStatusIcon from '../icons/PublishStatusIcon';
import FormModal from '../modals/FormModal';
import ManageEventMenu, { HandleMenuItemClickField, HandleMenuItemClickOption } from '../molecules/ManageEventMenu';
import EventMarkets from '../organisms/EventMarkets';
import { FilterEventsData } from '../organisms/forms/FilterEvents';
import TableTemplate from '../templates/TableTemplate';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';

const makeStyles = (selectedRow: string | null): StyleObj => ({
  container: {
    display: 'flex',
    height: '100%',
    minHeight: 0,
    gap: '1.5rem',
    position: 'relative',
    width: '100%',
  },
  nameColumnWrapper: { display: 'grid', gridTemplateColumns: 'auto 1fr', alignItems: 'center' },
  nameColumnIconsWrapper: { display: 'flex', alignItems: 'center' },
  nameWrapper: { overflow: 'hidden', textOverflow: 'ellipsis' },
  createItemBtn: selectedRow
    ? {
        position: 'absolute',
        top: 8,
        right: 0,
        ml: 0,
      }
    : {},
});

const OutrightsPage = () => {
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const styles = makeStyles(selectedRow);

  const { pathname } = useLocation();
  const { getPersistData } = usePersist(false);

  const {
    data: eventsData,
    updateQueryParams,
    isFetching,
    isLoading,
    changeQuery,
  } = usePagination<EventList>('outrights', {
    page: 1,
    limit: 25,
    fromTimestamp: dayjs().unix() * 1000,
    toTimestamp: dayjs().add(3, 'month').unix() * 1000,
  });

  const { updateData } = useMutateData('events', [QUERY_KEYS.outrights]);
  const { createData: createEventChanges } = useMutateData('event-changes', [QUERY_KEYS.outrights]);
  const { createData: createEventChangesBulk } = useMutateData('event-changes/bulk', [QUERY_KEYS.outrights]);

  const { handleSort } = useSort(changeQuery);
  const { openModal } = useModal();
  const invalidateData = useInvalidateQuery();

  // on eventsData change, check if selectedRow is still in the list
  // if not, hide the markets table
  useEffect(() => {
    if (!selectedRow) return;

    const event = eventsData?.items.find((event) => event.id === selectedRow);
    if (!event) {
      setSelectedRow(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsData]);

  const openEditDialog = (row: Event) => {
    openModal(<FormModal form='editOutright' />, row);
  };

  const columns: GridColDef[] = [
    createColumn('id', 'ID', {
      sortable: false,
    }),
    createColumn('sportIcon', 'Icon', {
      sortable: false,
      alwaysVisible: true,
      flex: 0.5,
      renderCell: (params) => (
        <TableIconContainer>
          <IconRenderer name={params.row.tournament.sport.icon} type='sport' />
        </TableIconContainer>
      ),
    }),
    createColumn('sportName', 'Sport', {
      valueGetter: (params: GridValueGetterParams<EventList>) => params.row.tournament.sport.name,
    }),
    createColumn('competitionName', 'Competition', {
      valueGetter: (params: GridValueGetterParams<EventList>) => params.row.tournament.competition.name,
    }),
    createColumn('name', 'Event', {
      align: 'left',
      flex: 2,
      alwaysVisible: true,
      renderCell: (params: GridRenderCellParams<EventList>) => {
        return (
          <Box sx={styles.nameColumnWrapper}>
            <Box sx={styles.nameColumnIconsWrapper}>
              {getLatestValue(params.row, 'manualControl') && <ManualIcon width='16' height='16' viewBox='4 4 16 16' />}
              <PublishStatusIcon
                status={params.row.publishStatus}
                viewBox='2 2 20 20'
                width='20'
                height='20'
                changed={params.row.changed}
              />
            </Box>
            <Box title={params.row.name} sx={styles.nameWrapper}>
              {getLatestValue(params.row, 'name')}
            </Box>
          </Box>
        );
      },
    }),
    createColumn('startDate', 'Date', {
      valueGetter: (params: GridValueGetterParams<EventList>) =>
        dayjs(getLatestValue(params.row, 'startDate')).format('ddd. DD-MM'),
    }),
    createColumn('startTime', 'Time', {
      sortable: false,
      flex: 0.75,
      valueGetter: (params: GridValueGetterParams<EventList>) =>
        dayjs(getLatestValue(params.row, 'startDate')).format('HH:mm'),
    }),
    createColumn('isActive', 'Status', {
      flex: 0.5,
      renderCell: (params) => (
        <Switch
          value={params.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            createEventChanges({
              eventId: params.row.id,
              isActive: e.target.checked,
            });
          }}
          name='status'
        />
      ),
      valueGetter: (params: GridValueGetterParams<EventList>) => {
        return getLatestValue(params.row, 'isActive');
      },
    }),
    createColumn('information', 'Information', {
      sortable: false,
      renderCell: (params) => {
        const info = getLatestValue(params.row, 'information');
        return (
          info && (
            <TableIconContainer>
              <Tooltip title={info}>
                <InfoIcon color='primary' />
              </Tooltip>
            </TableIconContainer>
          )
        );
      },
    }),
    createColumn('edit', 'Edit', {
      sortable: false,
      flex: 0.5,
      renderCell: (params) => (
        <IconButton onClick={() => openEditDialog(params.row)} color='primary'>
          <EditOutlined />
        </IconButton>
      ),
    }),
  ];

  const filterData = getPersistData<FilterEventsData>(`${LS_KEYS.appFormFilter}-${pathname}`);
  const isLive = filterData?.isLive === 1;

  const hideMarketsTable = () => {
    setSelectedRow(null);
  };

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(contextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null);
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const onCellClick = (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
    if (params.field !== 'edit' && params.field !== 'isActive' && params.field !== '__check__') {
      setSelectedRow(params.row.id);
      setSelectedRows([params.row.id]);
    }
  };

  const handleMenuItemClick = (option: HandleMenuItemClickOption, field: HandleMenuItemClickField) => {
    if (field === 'publishStatus') {
      updateData('bulk', {
        eventIds: selectedRows,
        [field]: typeof option.id === 'number' ? !!option.id : option.id,
      });
    } else {
      createEventChangesBulk(
        {
          eventIds: selectedRows,
          [field]: typeof option.id === 'number' ? !!option.id : option.id,
        },
        () => invalidateData([QUERY_KEYS.events])
      );
    }

    handleClose();
  };

  const onRowSelectionModelChange = (newSelection: GridRowSelectionModel) => {
    setSelectedRows(newSelection as string[]);
  };

  const slotProps = {
    row: {
      onContextMenu: selectedRows.length > 0 ? handleContextMenu : undefined,
      style: { cursor: selectedRows.length > 0 ? 'context-menu' : 'pointer' },
    },
  };

  const getColumns = () => {
    return columns.filter((column) => !(isLive && column.field === 'featured'));
  };

  return (
    <>
      <Box sx={styles.container}>
        <TableTemplate
          rows={eventsData?.items || []}
          columns={getColumns()}
          rowCount={eventsData?.count || 0}
          loading={isFetching || isLoading}
          defaultVisibleColumns={defaultColumnsOutrights}
          handlePaginationModelChange={updateQueryParams}
          handleSort={handleSort}
          handleSearch={handleSearch}
          checkboxSelection
          onCellClick={onCellClick}
          // createItemBtnLabel="Add new outright"
          // createItemBtnStyle={styles.createItemBtn}
          onRowSelectionModelChange={onRowSelectionModelChange}
          rowSelectionModel={selectedRows}
          slotProps={slotProps}
          changeQuery={changeQuery}
          // formType="createOutright"
        />
        {selectedRow && (
          <EventMarkets
            eventId={selectedRow}
            tournament={eventsData?.items.find((event) => event.id === selectedRow)?.tournament}
            handleClose={hideMarketsTable}
          />
        )}
      </Box>
      <ManageEventMenu
        statusChanged={eventsData?.items.find((event) => event.id === selectedRow)?.changed || false}
        contextMenu={contextMenu}
        handleClose={handleClose}
        handleItemClick={handleMenuItemClick}
      />
    </>
  );
};

export default OutrightsPage;
