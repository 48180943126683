import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Competition, PaginatedData, Participant, Player, Sport, Tournament } from '../../../@types/api';
import { QUERY_KEYS } from '../../../constants';
import useMutateData from '../../../hooks/useMutateData';

import { DialogType } from '../../../@types';
import { useModal } from '../../../contexts/ModalContext';
import { PlayerFormData, editPlayerFormSchema, playerFormSchema } from '../../../schema';
import { getData } from '../../../utils/api';
import FormFieldStack from '../../atoms/FormFieldStack';
import Switch from '../../atoms/Switch';
import FormModalLayout from '../../layouts/FormModalLayout';
import FormSelect from '../../molecules/FormSelect';
import FormAutocomplete from '../FormAutocomplete';

const DEFAULT_FORM_DATA = {
  participantId: '',
  firstName: '',
  lastName: '',
  displayName: '',
  isActive: false,
  sportId: '',
  competitionId: '',
  tournamentId: '',
};

type Props = DialogType;

const PlayerForm = ({ closeModal }: Props) => {
  const { item } = useModal<Player>();
  const { createData, updateData } = useMutateData('players', [QUERY_KEYS.players]);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm<PlayerFormData>({
    defaultValues: DEFAULT_FORM_DATA,
    resolver: zodResolver(item ? editPlayerFormSchema : playerFormSchema),
  });

  const sportId = watch('sportId') || item?.sport?.id;
  const competitionId = watch('competitionId');
  const tournamentId = watch('tournamentId');
  const participant = watch('participantId');

  const { data: sportsData } = useQuery([QUERY_KEYS.sports], {
    queryFn: (): Promise<PaginatedData<Sport>> =>
      getData('sports', {
        limit: 100,
        page: 1,
      }),
  });

  const { data: tournamentsData } = useQuery([QUERY_KEYS.tournaments, competitionId], {
    queryFn: (): Promise<PaginatedData<Tournament>> =>
      getData('tournaments', {
        limit: 100,
        page: 1,
        competitionIds: [competitionId],
      }),
    enabled: !!competitionId,
  });

  const { data: participantsData } = useQuery([QUERY_KEYS.participants, tournamentId], {
    queryFn: (): Promise<PaginatedData<Participant>> =>
      getData('participants', {
        limit: 1000,
        page: 1,
        tournamentIds: [tournamentId],
        participantIds: [participant],
      }),
    enabled: !!tournamentId,
  });

  useEffect(() => {
    if (item) {
      reset({
        ...item,
        sportId: item.sport.id,
        participantId: item.participant.id,
        competitionId: item.competitions?.[0]?.id,
        tournamentId: item.tournaments?.[0]?.id,
      });
    }
  }, [item, reset]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type !== 'change') return;

      if (name === 'sportId') {
        setValue('competitionId', '');
        setValue('tournamentId', '');
        setValue('participantId', '');
      }

      if (name === 'competitionId') {
        setValue('tournamentId', '');
      }

      if (name === 'tournamentId') {
        setValue('participantId', '');
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const handleClose = () => {
    reset(DEFAULT_FORM_DATA);
    closeModal?.();
  };

  const onFormSubmit = (data: PlayerFormData) => {
    if (item) {
      updateData(item.id, data, () => {
        handleClose();
      });
    } else {
      createData(data, () => {
        handleClose();
      });
    }
  };

  return (
    <FormModalLayout
      onSave={handleSubmit(onFormSubmit)}
      label={item ? 'Edit player' : 'Add new player'}
      onClose={handleClose}
      isEdit={!!item}
    >
      <TextField
        error={!!errors.firstName}
        helperText={errors.firstName?.message}
        label='First name'
        required
        {...register('firstName')}
      />
      <TextField
        error={!!errors.lastName}
        helperText={errors.lastName?.message}
        label='Last name'
        required
        {...register('lastName')}
      />
      <TextField
        error={!!errors.displayName}
        helperText={errors.displayName?.message}
        label='Display name'
        {...register('displayName')}
      />
      <FormSelect
        label='Sport'
        name='sportId'
        required
        control={control}
        error={errors.sportId}
        options={sportsData?.items || []}
      />
      <FormAutocomplete<Competition, PlayerFormData>
        name='competitionId'
        control={control}
        label='Competition'
        required
        url='competitions'
        queryKey={[QUERY_KEYS.competitions, sportId]}
        queryParams={{
          sportId: sportId,
          isActive: true,
        }}
        disabled={!sportId}
        error={errors.competitionId}
        hookEnabled={!!sportId}
        getOptionLabel={(options, value) => {
          const option = options.find((option) => option?.id === value);
          return option?.name || '';
        }}
      />
      <FormSelect
        label='Tournament'
        name='tournamentId'
        required
        control={control}
        error={errors.tournamentId}
        options={tournamentsData?.items || []}
        disabled={!competitionId}
      />
      <FormSelect
        label='Participant'
        name='participantId'
        required
        control={control}
        error={errors.participantId}
        options={participantsData?.items || (item ? [item.participant] : [])}
        disabled={!tournamentId}
      />
      <FormFieldStack label='Status'>
        <Controller name='isActive' control={control} render={({ field }) => <Switch {...field} ref={null} />} />
      </FormFieldStack>
    </FormModalLayout>
  );
};

export default PlayerForm;
