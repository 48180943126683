import { PublishModelType } from '../../@types/api';
import AutomaticIcon from './AutomaticIcon';
import ManualIcon from './ManualIcon';
import SemiIcon from './SemiIcon';

type ResolveModelIconProps = {
  publishTimeMatchModel: PublishModelType;
};

const ResolveModelIcon = (props: ResolveModelIconProps) => {
  if (props.publishTimeMatchModel === 'semi_automatic') {
    return <SemiIcon />;
  } else if (props.publishTimeMatchModel === 'automatic') {
    return <AutomaticIcon />;
  }
  return <ManualIcon />;
};

export default ResolveModelIcon;
