import { styled } from '@mui/material/styles';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import { getPageSizeOptions } from '../../helpers';
import NoDataMessage from '../atoms/NoDataMessage';

const StyledMuiDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 'none',
  '--unstable_DataGrid-radius': '0',
  '& .MuiDataGrid-withBorderColor': {
    border: 'none',
  },
  '& .MuiDataGrid-columnHeader': {
    border: '1px solid #00533780',
    background: theme.palette.primary.main,
    color: theme.palette.background.light,

    '&:not(:first-of-type)': {
      borderLeft: 'none',
    },
    '&:first-of-type': {
      borderTopLeftRadius: '8px',
    },
    '&:last-of-type': {
      borderTopRightRadius: '8px',
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.background.light,
    },
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    ...theme.typography.h5,
  },
  '& .MuiDataGrid-sortIcon': {
    color: theme.palette.background.light,
  },
  '& .MuiDataGrid-cell': {
    border: 'none',
    fontWeight: 500,
  },
  '& .MuiDataGrid-row:nth-of-type(even)': {
    backgroundColor: theme.palette.background.light,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: theme.palette.background.lighter,
  },
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-footerContainer': {
    justifyContent: 'flex-end',
  },
  '.totals .MuiDataGrid-cell': {
    fontWeight: '700',
    backgroundColor: '#D9E7D9 !important',
  },
}));

const StyledDataGrid = ({
  onSortModelChange,
  paginationModel,
  onPaginationModelChange,
  sortingMode = 'server',
  paginationMode = 'server',
  disableRowSelectionOnClick = true,
  disableColumnFilter = true,
  disableColumnMenu = true,
  disableColumnSelector = true,
  rowCount,
  slots,
  rowHeight = 48,
  columnHeaderHeight = 44,
  ...rest
}: DataGridProps) => {
  const customSlots = {
    noResultsOverlay: NoDataMessage,
    noRowsOverlay: NoDataMessage,
    ...slots,
  };

  return (
    <StyledMuiDataGrid
      sortingMode={sortingMode}
      onSortModelChange={onSortModelChange}
      paginationModel={paginationModel}
      onPaginationModelChange={onPaginationModelChange}
      paginationMode={paginationMode}
      disableRowSelectionOnClick={disableRowSelectionOnClick}
      disableColumnMenu={disableColumnMenu}
      disableColumnSelector={disableColumnSelector}
      rowCount={rowCount}
      showColumnVerticalBorder
      pageSizeOptions={getPageSizeOptions(rowCount || 0)}
      rowHeight={rowHeight}
      columnHeaderHeight={columnHeaderHeight}
      slots={customSlots}
      sx={{
        '& .MuiDataGrid-root': {
          border: '4px solid #00533780',
          backgroundColor: 'red',
        },
      }}
      {...rest}
    />
  );
};

export default StyledDataGrid;
