import { Stack } from '@mui/material';
import CashoutSettingsTable from '../molecules/CashoutSettingsTable';
import MarketMarginTable from '../molecules/MarketMarginTable';

const CashoutPage = () => {
  return (
    <Stack direction='row' spacing={2} width={1}>
      <MarketMarginTable />
      <CashoutSettingsTable product='pre-match' />
      <CashoutSettingsTable product='in-play' />
    </Stack>
  );
};

export default CashoutPage;
