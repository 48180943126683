import { isEmpty } from 'lodash-es';
import { ApiError, CRMError, FieldError } from '../@types';
import { useToast } from '../contexts/ToastContext';

export const useToastErrorMessage = () => {
  const { enqueueSnackbar } = useToast();

  const handleDefaultError = () => {
    enqueueSnackbar({ message: 'Something went wrong.', variant: 'error' });
  };

  const handleStringError = (errorMessage: string) => {
    if (!isEmpty(errorMessage)) {
      enqueueSnackbar({ message: errorMessage, variant: 'error' });
    } else {
      handleDefaultError();
    }
  };

  const handleObjectError = (errorMessage: { message: string }) => {
    if (errorMessage && errorMessage.message) {
      enqueueSnackbar({ message: errorMessage.message, variant: 'error' });
    } else {
      handleDefaultError();
    }
  };

  const handleErrorsArray = (errors: (FieldError | CRMError | string)[]) => {
    errors.forEach((errorItem) => {
      let errorMessage: string;

      if (typeof errorItem === 'string') {
        errorMessage = errorItem;
      } else if ('message' in errorItem) {
        errorMessage = errorItem.message;
      } else {
        errorMessage = errorItem.error;
      }

      enqueueSnackbar({ message: errorMessage, variant: 'error' });
    });
  };

  const handleErrorMessage = (error: ApiError) => {
    const errorData = error?.response?.data;

    if (!errorData) {
      handleDefaultError();
    } else if (typeof errorData.errorMessage === 'string') {
      handleStringError(errorData.errorMessage);
    } else if (typeof errorData.errorMessage === 'object') {
      handleObjectError(errorData.errorMessage);
    } else if (Array.isArray(errorData.errors) && errorData.errors.length > 0) {
      handleErrorsArray(errorData.errors);
    } else {
      handleDefaultError();
    }
  };

  return {
    handleErrorMessage,
  };
};
