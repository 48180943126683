import { GridSortModel } from '@mui/x-data-grid';
import { useCallback } from 'react';
import { ApiService } from '../utils/api';

type ChangeQueryFunction = (query: {
  sort?: string;
  order?: string;
  sortBy?: {
    [key: string]: string;
  };
}) => void;

const useSort = (changeQuery: ChangeQueryFunction, service: ApiService = 'feed') => {
  const handleSort = useCallback(
    (model: GridSortModel) => {
      const sortModelItem = model[0];

      if (sortModelItem) {
        const sortQueryObject =
          service === 'feed'
            ? {
                sort: sortModelItem.field,
                order: (sortModelItem.sort || 'asc').toUpperCase(),
              }
            : {
                sortBy: {
                  [sortModelItem.field]: (sortModelItem.sort || 'asc').toUpperCase(),
                },
              };

        changeQuery(sortQueryObject);
      } else {
        changeQuery({ sort: undefined, order: undefined, sortBy: undefined });
      }
    },
    [changeQuery, service]
  );

  return { handleSort };
};

export default useSort;
