import { Button, DialogActions, DialogTitle } from '@mui/material';
import { DialogType } from '../../@types';
import { MESSAGES } from '../../constants/messages';

type DialogDelete = DialogType & {
  handleConfirm: () => void;
  title?: string;
  actionButtonLabel?: string;
  actionButtonClose?: string;
};

const ConfirmationModal = ({ title, actionButtonLabel, handleConfirm, closeModal, actionButtonClose }: DialogDelete) => {
  const onConfirm = () => {
    handleConfirm();
    closeModal?.();
  };

  return (
    <>
      <DialogTitle variant='h4' align='center' gutterBottom>
        {title ?? MESSAGES.CONFIRM_DELETE}
      </DialogTitle>
      <DialogActions>
        <Button variant='outlined' onClick={closeModal}>
          {actionButtonClose ?? 'Cancel'}
        </Button>
        <Button variant='contained' onClick={onConfirm}>
          {/* Delete */}
          {actionButtonLabel ?? 'Delete'}
        </Button>
      </DialogActions>
    </>
  );
};

export default ConfirmationModal;
