import { ChevronRight } from '@mui/icons-material';
import { Box, capitalize, IconButton, Typography } from '@mui/material';
import { StyleObj } from '../../@types';
import { MarketGroupsList } from '../../@types/api';
import { getMarketTypeName } from '../../helpers';

const styles: StyleObj = {
  container: {
    width: '30%',
    mt: 11,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    height: 44,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'primary.main',
    color: 'background.light',
    borderRadius: '8px 8px 0 0',
  },
  headerTitle: {
    typography: 'h5',
    lineHeight: 14,
  },
  marketType: {
    p: 1.5,
    borderColor: 'grey.100',
    borderWidth: 1,
    borderStyle: 'solid',
    borderTop: 'none',
  },
};

type Props = {
  marketGroupData: MarketGroupsList;
  handleClose: () => void;
};

const MarketGroupDetails = ({ marketGroupData, handleClose }: Props) => {
  const { sport, name, usedFor, marketTypes } = marketGroupData;
  const headerTitle = `${sport.name} / ${name} / ${capitalize(usedFor.replace('_', ' '))}`;

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <IconButton onClick={handleClose} color='inherit'>
          <ChevronRight />
        </IconButton>
        <Typography sx={styles.headerTitle}>{headerTitle}</Typography>
      </Box>
      <Box>
        {marketTypes.map((marketType) => (
          <Typography sx={styles.marketType} variant='body3' key={marketType.id}>
            {getMarketTypeName(marketType)}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default MarketGroupDetails;
