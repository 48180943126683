import { Box } from '@mui/material';
import React, { useState } from 'react';
import { getTotalsRowClass } from '../../../helpers';
import TableTemplate from '../../templates/TableTemplate';
import { usePagination } from '../../../hooks/usePagination';
import { GridColDef } from '@mui/x-data-grid';
import { createColumn } from '../../../utils';
import { BonusTotals } from '../../../@types/api';
import DateRangeSelect from '../../molecules/DateRangeSelect';
import dayjs, { Dayjs } from 'dayjs';
import { StyleObj } from '../../../@types';

const styles: StyleObj = {
  container: {
    position: 'relative',
    height: 'calc(100% - 200px)',
  },
};

const BonusReportsPage = () => {
  const [date, setDate] = useState<{ fromTimestamp: Dayjs; toTimestamp: Dayjs }>({
    fromTimestamp: dayjs().startOf('day'),
    toTimestamp: dayjs().endOf('day'),
  });
  const handleDateChange = (newDate: { fromTimestamp: Dayjs; toTimestamp: Dayjs }) => {
    setDate(newDate);
    changeQuery({
      fromTimestamp: newDate.fromTimestamp.valueOf(),
      toTimestamp: newDate.toTimestamp.valueOf(),
    });
  };

  const { data, updateQueryParams, isFetching, isLoading, changeQuery } = usePagination<BonusTotals>(
    'reports/bonuses',
    {
      page: 1,
      limit: 25,
      fromTimestamp: date.fromTimestamp.valueOf(),
      toTimestamp: date.toTimestamp.valueOf(),
    }
  );

  const generateTotalsRow = (data?: BonusTotals) => {
    return {
      type: 'totals',
      distributedBonusAmount: data?.distributedBonusAmount ?? '',
      stakeAmount: data?.stakeAmount ?? '',
      winningAmount: data?.winningAmount ?? '',
      profit: data?.profit ?? '',
      ticketCount: data?.ticketCount ?? '',
    };
  };
  const totalsRow = generateTotalsRow(data?.totals);

  const aggregatedData = data?.items ? [...data.items, totalsRow] : [];

  const columns: GridColDef[] = [
    createColumn('type', 'Bonus Type', {
      renderCell: (params) => (params.row.type === 'totals' ? 'Totals' : params.value),
    }),
    createColumn('distributedBonusAmount', 'Distributed'),
    createColumn('ticketCount', 'Tickets'),
    createColumn('stakeAmount', 'Stakes'),
    createColumn('winningAmount', 'Winnings'),
    createColumn('profit', 'Profit'),
  ];

  return (
    <Box sx={styles.container}>
      <DateRangeSelect date={date} onDateChange={handleDateChange} />
      <TableTemplate
        rows={aggregatedData || []}
        columns={columns}
        loading={isFetching || isLoading}
        rowCount={data?.count ?? 1}
        handlePaginationModelChange={updateQueryParams}
        getRowClassName={getTotalsRowClass}
        changeQuery={changeQuery}
        getRowId={(row) => row.type}
        showExport
      />
    </Box>
  );
};

export default BonusReportsPage;
