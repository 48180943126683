import { useEffect, useRef } from 'react';

export const useBrowserVisibility = () => {
  const isBrowserMinimizedRef = useRef(false);

  const handleVisibilityChange = () => {
    isBrowserMinimizedRef.current = document.hidden;
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return isBrowserMinimizedRef;
};
