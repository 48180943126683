import { SVGProps } from 'react';

const ManualIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' {...props}>
      <path d='M20 12a8 8 0 1 0-16 0 8 8 0 0 0 16 0Z' fill='#2B7FFE' />
      <path
        d='M14.204 16v-4.66l-1.82 3.47h-.77l-1.82-3.47V16h-1.38V8.9h1.48l2.1 4.04 2.12-4.04h1.47V16h-1.38Z'
        fill='#fff'
      />
    </svg>
  );
};

export default ManualIcon;
