import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import MessagesBadge from './MessagesBadge';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '0 10px',
  },
};

type Props = {
  author: string;
};

const MessagesProfileBadge = ({ author }: Props) => {
  return (
    <Box sx={styles.container}>
      <MessagesBadge author={author} />
      <Typography>{author}</Typography>
    </Box>
  );
};

export default MessagesProfileBadge;
