import { Box } from '@mui/material';
import type { GridCellParams, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash-es';
import { useEffect, useState } from 'react';
import { StyleObj } from '../../@types';
import type { BettingSlipWithMonitoringInfo } from '../../@types/api';
import { useAudio } from '../../contexts/AudioContext';
import { defaultColumnsTicketAuthorization } from '../../helpers/table';
import { usePagination } from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';
import useWebsocket from '../../hooks/useWebsocket';
import { createColumn } from '../../utils';
import { BettingSlipFromIcon } from '../icons';
import TicketAuthDetailsTable from '../organisms/TicketAuthDetailsTable';
import TableTemplate from '../templates/TableTemplate';
import { createNumberColumn } from '../../helpers/columns';

const styles: StyleObj = {
  container: {
    display: 'flex',
    gap: 3,
    position: 'relative',
    height: 'calc(100% - 140px)',
  },
};

const columns: GridColDef[] = [
  createColumn('ticketId', 'ID', {
    sortable: false,
    alwaysVisible: true,
    flex: 1.5,
  }),
  createColumn('createdAt', 'Created At', {
    valueGetter: (params: GridValueGetterParams<BettingSlipWithMonitoringInfo>) => {
      return dayjs(params.row.createdAt).format('DD-MM-YYYY HH:mm');
    },
    alwaysVisible: true,
    flex: 1.5,
  }),
  createColumn('totalStakeAmount', 'Stake', {
    alwaysVisible: true,
    flex: 0.75,
  }),
  createNumberColumn('totalOdds', 'Odds', {
    flex: 0.75,
  }),
  createColumn('possibleWinnings', 'Possible Winnings', {
    alwaysVisible: true,
    flex: 1.5,
  }),
  createColumn('betsCount', 'Bets', {
    alwaysVisible: true,
    flex: 0.75,
  }),
  createColumn('from', 'From', {
    renderCell: (params: GridRenderCellParams<BettingSlipWithMonitoringInfo>) => {
      return <BettingSlipFromIcon from={params.row.from} />;
    },
    flex: 0.75,
  }),
  createColumn('user', 'Name', {
    valueGetter: (params: GridValueGetterParams<BettingSlipWithMonitoringInfo>) => params.row.user?.fullName,
    flex: 1.5,
  }),
  createColumn('username', 'User Name', {
    valueGetter: (params: GridValueGetterParams<BettingSlipWithMonitoringInfo>) => {
      return params.row.user?.username;
    },
    alwaysVisible: true,
  }),
  createColumn('sourceType', 'Source Type', {
    valueGetter: (params: GridValueGetterParams<BettingSlipWithMonitoringInfo>) => {
      return 'Punter';
    },
  }),
];

const TicketAuthorizationPage = () => {
  const [selectedRow, setSelectedRow] = useState<string | null>(null);

  const {
    data: bettingSlipsData,
    updateQueryParams,
    isLoading,
    refetch: refetchBettingSlips,
    changeQuery,
  } = usePagination<BettingSlipWithMonitoringInfo>('/betting-slips', {
    page: 1,
    limit: 25,
    fromTimestamp: dayjs().subtract(1, 'day').valueOf(),
    toTimestamp: dayjs().add(1, 'hour').valueOf(),
    acceptStatus: 'pending',
    reoffered: false,
  });

  useWebsocket({
    skipCache: true,
    callback: () => {
      refetchBettingSlips();
    },
  });

  const { stopAudio } = useAudio();

  useEffect(() => {
    if (bettingSlipsData) {
      setSelectedRow(bettingSlipsData?.items[0]?.id || null);
    }
  }, [bettingSlipsData]);

  useEffect(() => {
    if (isEmpty(bettingSlipsData?.items)) {
      stopAudio();
    }
  }, [bettingSlipsData, stopAudio]);

  const { handleSort } = useSort(changeQuery);

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  const onCellClick = (params: GridCellParams) => {
    setSelectedRow(params.row.id);
  };

  return (
    <Box sx={styles.container}>
      <TableTemplate
        rows={bettingSlipsData?.items || []}
        columns={columns}
        rowCount={bettingSlipsData?.count || 0}
        loading={isLoading}
        defaultVisibleColumns={defaultColumnsTicketAuthorization}
        handlePaginationModelChange={updateQueryParams}
        handleSearch={handleSearch}
        handleSort={handleSort}
        changeQuery={changeQuery}
        onCellClick={onCellClick}
      />
      {selectedRow && <TicketAuthDetailsTable ticketId={selectedRow} />}
    </Box>
  );
};

export default TicketAuthorizationPage;
