import { MenuItem } from '@mui/material';
import { GridExportMenuItemProps, useGridApiContext } from '@mui/x-data-grid';
import { exportToExcel, getExportData } from '../../../utils/export';

const ExcelExportMenuOption = (props: GridExportMenuItemProps<Record<string, never>>) => {
  const apiRef = useGridApiContext();
  const { hideMenu } = props;

  return (
    <MenuItem
      onClick={() => {
        exportToExcel(getExportData(apiRef));
        hideMenu?.();
      }}
    >
      Export as Excel
    </MenuItem>
  );
};

export default ExcelExportMenuOption;
