import { Stack } from '@mui/material';
import { Control, useWatch } from 'react-hook-form';
import { BONUS_VALIDITY_PERIOD_OPTIONS } from '../../constants';
import { BonusFilterFormData } from '../../schema';
import DateTimeSelect from './DateTimeSelect';
import FormSelect from './FormSelect';

type Props = {
  control: Control<BonusFilterFormData>;
  disabled?: boolean;
};

const ValidityPeriodSelect = ({ control, disabled }: Props) => {
  const validityPeriod = useWatch({ control, name: 'validityPeriod' });
  const showDateRange = validityPeriod === 'CUSTOM';

  return (
    <>
      <FormSelect
        control={control}
        name='validityPeriod'
        label='Validity period'
        options={BONUS_VALIDITY_PERIOD_OPTIONS}
        disabled={disabled}
        required
      />
      {showDateRange && (
        <Stack direction='row' spacing={2}>
          <DateTimeSelect control={control} name='fromTimestamp' label='From' disablePast disabled={disabled} />
          <DateTimeSelect control={control} name='toTimestamp' label='To' disabled={disabled} />
        </Stack>
      )}
    </>
  );
};

export default ValidityPeriodSelect;
