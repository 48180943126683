import { MailOutline } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { QUERY_KEYS } from '../../constants';
import { getData } from '../../utils/api';

const styles = {
  icon: {
    position: 'relative',
  },
  count: {
    position: 'absolute',
    top: 10,
    left: 8,
    height: '13px',
    width: '13px',
    backgroundColor: 'primary.dark',
    color: '#fff',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  countNumber: {
    fontSize: '10px',
  },
};

const MessageIcon = () => {
  const navigate = useNavigate();
  const { data: messagesCount } = useQuery([QUERY_KEYS.messagesCount], {
    queryFn: (): Promise<number> => getData('messages/count'),
  });

  const handleOnClick = () => {
    navigate('/messages/inbox');
  };

  return (
    <IconButton onClick={handleOnClick} size='large' sx={styles.icon}>
      <Box sx={styles.count}>
        <Typography sx={styles.countNumber}>{messagesCount}</Typography>
      </Box>
      <MailOutline color='primary' />
    </IconButton>
  );
};

export default MessageIcon;
