import { SVGProps } from 'react';

const MLetterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill='none' {...props}>
    <rect width={16} height={16} fill='#2B7FFE' rx={8} />
    <path
      fill='#fff'
      d='M10.204 12V7.34l-1.82 3.47h-.77l-1.82-3.47V12h-1.38V4.9h1.48l2.1 4.04 2.12-4.04h1.47V12h-1.38Z'
    />
  </svg>
);
export default MLetterIcon;
