import { useCallback, useEffect, useState, useRef } from 'react';

const workerBlob = `
let seconds = 0;
let timer;

self.onmessage = function (e) {
  if (e.data.action === 'start') {
    seconds = e.data.seconds;
    startTimer();
  } else if (e.data.action === 'stop') {
    clearInterval(timer);
  }
};

function startTimer() {
  timer = setInterval(() => {
    seconds--;
    if (seconds >= 0) {
      self.postMessage(seconds);
    } else {
      clearInterval(timer);
    }
  }, 1000);
}
`;

const useCountdownTimer = (initialSeconds: number) => {
  const [seconds, setSeconds] = useState(initialSeconds);
  const countdownWorker = useRef<Worker | null>(null);

  useEffect(() => {
    const blob = new Blob([workerBlob], { type: 'application/javascript' });
    countdownWorker.current = new Worker(URL.createObjectURL(blob));
    countdownWorker.current.postMessage({ action: 'start', seconds: initialSeconds });

    countdownWorker.current.onmessage = (e) => {
      setSeconds(e.data);
    };

    return () => {
      countdownWorker.current?.postMessage({ action: 'stop' });
      countdownWorker.current?.terminate();
    };
  }, [initialSeconds]);

  const resetTimer = useCallback(() => {
    if (countdownWorker.current) {
      countdownWorker.current.postMessage({ action: 'stop' });
      countdownWorker.current.postMessage({ action: 'start', seconds: initialSeconds });
    } else {
      setSeconds(initialSeconds);
    }
  }, [initialSeconds]);

  return { seconds, resetTimer };
};

export default useCountdownTimer;
