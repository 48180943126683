import { Autocomplete, AutocompleteProps, LinearProgress, MenuItem, Popper, TextField, styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { SelectOption } from '../../@types';
import { PaginatedData, Partner } from '../../@types/api';
import { QUERY_KEYS } from '../../constants';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import useMutateData from '../../hooks/useMutateData';
import { getData } from '../../utils/api';

const StyledAutocomplete = styled((props: AutocompleteProps<{ id: string; name: string }, false, true, false>) => (
  <Autocomplete {...props} />
))(({ theme }) => ({
  '& .MuiInputBase-root': {
    padding: 0,
    ...theme.typography.h6,
  },
  '& .MuiInputBase-root[class*="Mui-focused"]': {
    marginTop: 6,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    paddingLeft: 0,
  },
  '& .MuiAutocomplete-inputRoot[class*="Mui-focused"]': {
    backgroundColor: theme.palette.primary.container,
    paddingLeft: 12,
  },
  '& .MuiAutocomplete-popper': {
    background: 'red',
  },
}));

export type ParentType = 'manager' | 'agent';

type AssignParentProps = {
  userId: string;
  parentType: ParentType;
  currentParent: Partner | undefined;
};

const config = {
  agent: {
    label: 'Agent',
    roleTypes: ['Agent'],
    assignmentEndpoint: 'customer/assign-to-manager',
    createAssignmentPayload: (selectedEntity: SelectOption, userId: string) => ({
      accountManager: selectedEntity.id,
      customerIds: [userId],
    }),
    fetcherQueryKey: ['customer/search'],
  },
  manager: {
    label: 'Manager',
    roleTypes: ['Manager'],
    assignmentEndpoint: 'agent/assign',
    createAssignmentPayload: (selectedEntity: SelectOption, userId: string) => ({
      teamLeadId: selectedEntity.id,
      agentId: userId,
    }),
    fetcherQueryKey: ['agent/search'],
  },
};

const AssignParent = ({ userId, parentType, currentParent }: AssignParentProps) => {
  const invalidateData = useInvalidateQuery();

  const { label, roleTypes, assignmentEndpoint, createAssignmentPayload, fetcherQueryKey } = config[parentType];

  const { data: partnerData } = useQuery<PaginatedData<Partner>>([QUERY_KEYS.crmUsers, parentType], {
    queryFn: () =>
      getData(
        'agent/search',
        {
          roleTypes,
          limit: 100, // todo: use FormAutoComplete to fetch all data by search query
        },
        'crm'
      ),
  });

  const options = partnerData?.items?.map((partner) => ({ id: partner.id, name: partner.fullName }));

  const { createData, isLoading } = useMutateData(assignmentEndpoint, [QUERY_KEYS.crmUsers, parentType], 'crm');

  const initialValue = currentParent && {
    id: currentParent.id,
    name: currentParent.fullName,
  };

  const handleChange = (_event: React.SyntheticEvent, selectedEntity: SelectOption) => {
    createData(
      createAssignmentPayload(selectedEntity, userId),
      () => {
        invalidateData([QUERY_KEYS.crmUsers, userId]);
        invalidateData(fetcherQueryKey);
      },
      `${label} assigned successfully`
    );
  };

  if (isLoading) return <LinearProgress sx={{ mt: 1 }} />;

  return (
    <StyledAutocomplete
      options={options || []}
      getOptionLabel={(option) => option.name}
      disableClearable
      disablePortal
      value={initialValue}
      onChange={handleChange}
      renderOption={(props, option) => {
        return (
          <MenuItem {...props} key={option.id}>
            {option.name}
          </MenuItem>
        );
      }}
      renderInput={(params) => {
        return <TextField placeholder='Assign parent' {...params} sx={{ width: 164 }} />;
      }}
      PopperComponent={StyledPopper}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
};

const StyledPopper = styled(Popper)(({ theme }) => ({
  '& .MuiAutocomplete-option': {
    ...theme.typography.h6,
  },
  '& .MuiAutocomplete-listbox': {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export default AssignParent;
