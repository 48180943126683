import type { GridColDef } from '@mui/x-data-grid';
import { useMemo } from 'react';
import type { BonusCampaign } from '../../@types/api';
import { QUERY_KEYS } from '../../constants';
import { createEditColumn } from '../../helpers/columns';
import { defaultColumnsBonuses } from '../../helpers/table';
import useMutateData from '../../hooks/useMutateData';
import { usePagination } from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';
import { createColumn, formatSnakeCaseString } from '../../utils';
import TableTemplate from '../templates/TableTemplate';

const BonusPage = () => {
  const {
    data: bonusData,
    updateQueryParams,
    isFetching,
    isLoading,
    changeQuery,
  } = usePagination<BonusCampaign>('bonus-campaigns');
  const { deleteData } = useMutateData('bonus-campaigns', [QUERY_KEYS.bonus]);
  const { handleSort } = useSort(changeQuery);

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  const columns: GridColDef[] = useMemo(
    () => [
      createColumn('id', 'ID', {
        sortable: false,
      }),
      createColumn('type', 'Type', {
        alwaysVisible: true,
        sortable: false,
        valueFormatter: ({ value }) => formatSnakeCaseString(value),
      }),
      createColumn('name', 'Name', {
        alwaysVisible: true,
      }),
      createColumn('product', 'Product', {
        alwaysVisible: true,
        valueFormatter: ({ value }) => formatSnakeCaseString(value),
      }),
      createColumn('status', 'Status', {
        alwaysVisible: true,
        valueFormatter: ({ value }) => formatSnakeCaseString(value),
      }),
      createColumn('description', 'Description'),
      createEditColumn<BonusCampaign>({
        deleteData,
      }),
    ],
    [deleteData]
  );

  return (
    <TableTemplate
      rows={bonusData?.items || []}
      columns={columns}
      loading={isFetching || isLoading}
      rowCount={bonusData?.count || 0}
      createItemBtnLabel='Add new bonus'
      defaultVisibleColumns={defaultColumnsBonuses}
      handlePaginationModelChange={updateQueryParams}
      handleSearch={handleSearch}
      handleSort={handleSort}
      changeQuery={changeQuery}
      formType='bonus'
    />
  );
};

export default BonusPage;
