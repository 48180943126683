import { zodResolver } from '@hookform/resolvers/zod';
import { AddCircle, HighlightOff, Save } from '@mui/icons-material';
import { Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { ApiError, DialogType, StyleObj } from '../../../@types';
import { Jackpot, StakeRange } from '../../../@types/api';
import { QUERY_KEYS } from '../../../constants';
import { JACKPOT_PRODUCT_OPTIONS } from '../../../constants/jackpot';
import { useModal } from '../../../contexts/ModalContext';
import { useToast } from '../../../contexts/ToastContext';
import { useInvalidateQuery } from '../../../hooks/useInvalidateQuery';
import useMutateData from '../../../hooks/useMutateData';
import { useToastErrorMessage } from '../../../hooks/useToastErrorMessage';
import { useJackpotStakeRanges } from '../../../queries';
import { JackpotFormData, editJackpotFormSchema, jackpotFormSchema } from '../../../schema';
import { postData } from '../../../utils/api';
import FormFieldStack from '../../atoms/FormFieldStack';
import Switch from '../../atoms/Switch';
import FormModalLayout from '../../layouts/FormModalLayout';
import FormNumberInput from '../../molecules/FormNumberInput';
import FormSelect from '../../molecules/FormSelect';
import IconSelect from '../../molecules/IconSelect';
import StakeRangeSelect from '../../molecules/StakeRangeSelect';

const DEFAULT_FORM_DATA = {
  name: '',
  product: '',
  isActive: false,
  selectedStakeRangeIds: [],
  payInAmountRanges: [],
  winningTickets: [],
  winningLow: undefined,
  winningHigh: undefined,
  description: '',
  icon: 'bronze',
};

const styles: StyleObj = {
  iconButton: { ':hover': { background: 'transparent' } },
};

const JackpotForm = ({ closeModal }: DialogType) => {
  const { item } = useModal<Jackpot>();
  const invalidateData = useInvalidateQuery();
  const { enqueueSnackbar } = useToast();
  const { handleErrorMessage } = useToastErrorMessage();

  const { data: stakeRangesData } = useJackpotStakeRanges();
  const options = useMemo(() => stakeRangesData?.items || [], [stakeRangesData]);

  const { createData: createJackpotPool, updateData: updateJackpotPool } = useMutateData('jackpots', [
    QUERY_KEYS.jackpots,
  ]);

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm<JackpotFormData>({
    defaultValues: DEFAULT_FORM_DATA,
    resolver: zodResolver(item ? editJackpotFormSchema : jackpotFormSchema),
  });

  const selectedStakeRangeIds = watch('selectedStakeRangeIds');
  const payInAmountRanges = watch('payInAmountRanges');
  const winningTickets = watch('winningTickets');

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'payInAmountRanges',
    keyName: 'key',
  });

  const { mutate: createStakeRange } = useMutation({
    mutationFn: (data: Pick<StakeRange, 'from' | 'to'>) => postData('jackpots/stake-ranges', data),
    onError: (error: ApiError) => handleErrorMessage(error),
  });

  const [previousStakeRangeIds, setPreviousStakeRangeIds] = useState<string[]>([]);

  useEffect(() => {
    if (item) {
      reset({
        ...item,
        selectedStakeRangeIds: item.stakeRanges.map((range) => range.id),
        payInAmountRanges: item.stakeRanges.map((range) => ({
          ...range,
          from: range.from,
          to: range.to || undefined,
          contribution: range.contribution,
        })),
      });
    }
  }, [item, reset]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'selectedStakeRangeIds' && type === 'change') {
        const currentStakeRangeIds = value.selectedStakeRangeIds?.map((range) => range ?? '') || [];

        const addedStakeRangeId = currentStakeRangeIds.find((id) => !previousStakeRangeIds.includes(id));
        const removedStakeRangeId = previousStakeRangeIds.find((id) => !currentStakeRangeIds.includes(id));

        if (addedStakeRangeId) {
          const optionToAdd = options.find((option) => option.id === addedStakeRangeId);
          if (optionToAdd) {
            append({
              id: optionToAdd.id,
              from: optionToAdd.from,
              to: optionToAdd.to || undefined,
              contribution: 0,
            });
          }
        }

        if (removedStakeRangeId) {
          const indexToRemove = fields.findIndex((field) => field.id === removedStakeRangeId);
          if (indexToRemove !== -1) {
            remove(indexToRemove);
          }
        }

        setPreviousStakeRangeIds(currentStakeRangeIds);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, options, append, remove, fields, previousStakeRangeIds]);

  const handleRemoveStakeRange = (id: string) => {
    const indexToRemove = fields.findIndex((field) => field.id === id);

    if (indexToRemove !== -1) {
      remove(indexToRemove);
    }

    const updatedStakeRangeIds = selectedStakeRangeIds.filter((range) => range !== id);
    setValue('selectedStakeRangeIds', updatedStakeRangeIds);
    setPreviousStakeRangeIds(updatedStakeRangeIds);
  };

  const handleRemoveWinningTicketsRange = (id: string) => {
    setValue(
      'winningTickets',
      winningTickets.filter((rangeId) => rangeId !== id)
    );
  };

  const handleClose = () => {
    reset(DEFAULT_FORM_DATA);
    closeModal?.();
  };

  const handleCreateStakeRange = (index: number) => {
    createStakeRange(
      {
        from: Number(payInAmountRanges[index].from),
        to: Number(payInAmountRanges[index].to),
      },
      {
        onSuccess: (data: { item: Omit<StakeRange, 'contribution'> }) => {
          invalidateData([QUERY_KEYS.stakeRanges]);
          enqueueSnackbar({ message: 'Stake range created successfully.', variant: 'success' });
          setValue('selectedStakeRangeIds', [...selectedStakeRangeIds, data.item.id]);
          update(index, {
            ...payInAmountRanges[index],
            id: data.item.id,
          });
        },
      }
    );
  };

  const isStakeRangeSelected = (id: string) => selectedStakeRangeIds.includes(id);

  const onFormSubmit = (data: JackpotFormData) => {
    if (item) {
      updateJackpotPool(item.id, data, handleClose);
    } else {
      createJackpotPool(
        {
          ...data,
          stakeRanges: payInAmountRanges.map((range) => ({
            from: Number(range.from),
            to: Number(range.to),
            contribution: Number(range.contribution),
          })),
        },
        handleClose
      );
    }
  };

  return (
    <FormModalLayout
      onSave={handleSubmit(onFormSubmit)}
      label={item ? 'Edit jackpot pool' : 'Add new jackpot pool'}
      onClose={handleClose}
      isEdit={!!item}
      showEditTabs={false}
    >
      <TextField label='Name' {...register('name')} error={!!errors.name} helperText={errors.name?.message} required />

      <FormSelect
        control={control}
        name='product'
        label='Product'
        options={JACKPOT_PRODUCT_OPTIONS}
        disabled={!!item}
        required
        error={errors.product}
      />
      <StakeRangeSelect
        name='selectedStakeRangeIds'
        control={control}
        label='Ticket pay in amount'
        options={options}
        onStakeRangeDelete={handleRemoveStakeRange}
        multiple
        error={errors.selectedStakeRangeIds}
        disabled={!!item}
        required
      />
      {!item && (
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography variant='h6' fontWeight={600}>
            Ticket pay in amount
          </Typography>
          <Button
            onClick={() => append({ id: fields.length.toString(), from: 0, to: undefined, contribution: 0 })}
            endIcon={<AddCircle color='primary' />}
            variant='text'
            sx={{ mr: -2 }}
          >
            Add new
          </Button>
        </Stack>
      )}
      {fields.map((field, index) => (
        <Stack direction='row' spacing={2} key={field.id}>
          <FormNumberInput
            sx={{ width: 150 }}
            name={`payInAmountRanges.${index}.from`}
            control={control}
            label='From'
            allowDecimals
            valueAsString
            required
            disabled={!!item || isStakeRangeSelected(field.id)}
          />
          <FormNumberInput
            sx={{ width: 150 }}
            name={`payInAmountRanges.${index}.to`}
            control={control}
            label='To'
            allowDecimals
            valueAsString
            disabled={!!item || isStakeRangeSelected(field.id)}
          />
          <IconButton
            size='small'
            disabled={isStakeRangeSelected(field.id) || !payInAmountRanges[index].from}
            onClick={() => handleCreateStakeRange(index)}
            color='primary'
            sx={styles.iconButton}
          >
            <Save />
          </IconButton>
          <FormNumberInput
            sx={{ width: 150 }}
            name={`payInAmountRanges.${index}.contribution`}
            control={control}
            label='Contributing'
            allowDecimals
            valueAsString
            required
            disabled={!!item}
          />
          {!item && (
            <Stack direction='row' spacing={1}>
              <IconButton
                size='small'
                color='error'
                onClick={() => handleRemoveStakeRange(field.id)}
                sx={styles.iconButton}
              >
                <HighlightOff />
              </IconButton>
            </Stack>
          )}
        </Stack>
      ))}
      <StakeRangeSelect
        name='winningTickets'
        control={control}
        label='Winning tickets'
        options={options}
        multiple
        onStakeRangeDelete={handleRemoveWinningTicketsRange}
        error={errors.winningTickets}
        required
        disabled={!!item}
      />
      <Typography variant='h6' fontWeight={600}>
        Winning amount range
      </Typography>
      <Stack direction='row' spacing={2}>
        <FormNumberInput
          name='winningLow'
          control={control}
          label='From'
          fullWidth
          disabled={!!item}
          required
          error={errors.winningLow}
        />
        <FormNumberInput
          name='winningHigh'
          control={control}
          label='To'
          fullWidth
          disabled={!!item}
          required
          error={errors.winningHigh}
        />
      </Stack>
      <FormFieldStack label='Status'>
        <Controller name='isActive' control={control} render={({ field }) => <Switch {...field} ref={null} />} />
      </FormFieldStack>
      <FormFieldStack label='Icon *'>
        <IconSelect control={control} name='icon' iconType='jackpot' />
      </FormFieldStack>
      <TextField
        error={!!errors.description}
        helperText={errors.description?.message}
        label='Description'
        multiline
        {...register('description')}
      />
    </FormModalLayout>
  );
};

export default JackpotForm;
