import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { StyledMessagesTable } from '../atoms/StyledMessagesTable';
import MessagesLayout from '../layouts/MessagesLayout';
import { TMessage } from '../../@types';
import { usePagination } from '../../hooks/usePagination';
import dayjs from 'dayjs';
import { IconButton } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import { useMutation } from '@tanstack/react-query';
import { patchData } from '../../utils/api';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import { QUERY_KEYS } from '../../constants';
import { useState } from 'react';

const DeletedMessagesPage = () => {
  const { data: messages } = usePagination<TMessage>('messages/deleted');
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const invalidateData = useInvalidateQuery();
  const { mutate: recoverMessages } = useMutation({
    mutationFn: (data: { messageIds: string[] }) => patchData('messages/recover', data),
    onSuccess: () => {
      invalidateData([QUERY_KEYS.deletedMessages]);
    },
  });

  const handleSelectionChange = (newSelection: GridRowSelectionModel) => {
    setSelectedRows(newSelection as string[]);
  };

  const handleRecoverMessages = () => {
    recoverMessages({
      messageIds: selectedRows,
    });
  };

  const columns: GridColDef[] = [
    { field: 'from', headerName: 'From:', width: 150 },
    {
      field: 'subject',
      headerName: 'Subject:',
      width: 150,
    },
    {
      field: 'date',
      headerName: 'Date:',
      width: 150,
    },
    {
      field: 'spacer',
      headerName: '',
      flex: 1,
      sortable: false,
    },
    {
      field: 'recover',
      headerName: '',
      sortable: false,
      width: 150,
      renderHeader: () =>
        selectedRows.length > 0 ? (
          <IconButton onClick={handleRecoverMessages}>
            <ReplayIcon sx={{ color: 'info.600' }} />
          </IconButton>
        ) : null,
    },
  ];

  const rows = messages?.items.map((message) => {
    return {
      from: message.author,
      subject: message.topic,
      date: dayjs(message.createdAt).format('DD.MM.YYYY'),
      id: message.id,
    };
  });

  return (
    <MessagesLayout>
      {rows && rows.length > 0 ? (
        <StyledMessagesTable
          columns={columns}
          rows={rows}
          disableColumnMenu
          checkboxSelection
          onRowSelectionModelChange={handleSelectionChange}
        />
      ) : (
        'No deleted messages to display'
      )}
    </MessagesLayout>
  );
};

export default DeletedMessagesPage;
