import { SelectProps } from '@mui/material';
import { Control, FieldError, FieldValues, Path } from 'react-hook-form';
import FormSelect from './FormSelect';
import { SelectOption, StyleObj } from '../../@types';

const styles: StyleObj = {
  container: {
    width: 'fit-content',
    background: '#fff',
    borderRadius: 2,
    height: 32,
    fontSize: 14,
    fontWeight: 600,
    boxShadow: (theme) => theme.shadows[1],
    '& .MuiInputBase-input': {
      padding: 1.25,
    },
  },
  paper: {
    boxShadow: (theme) => theme.shadows[2],
  },
  menu: {
    '& .MuiMenu-list': {
      boxShadow: 'none',
      paddingTop: 0,
      paddingBottom: 0,
      '& .MuiMenuItem-root': {
        paddingLeft: 1.5,
        fontSize: 14,
      },
    },
  },
};

type TypeSelectProps<T extends FieldValues> = Omit<SelectProps, 'label' | 'error'> & {
  control: Control<T>;
  name: Path<T>;
  error?: FieldError | undefined;
  options: SelectOption[];
};

const TypeSelect = <T extends FieldValues>({ control, name, error, options, ...rest }: TypeSelectProps<T>) => {
  return (
    <FormSelect
      name={name}
      control={control}
      error={error}
      options={options}
      sx={styles.container}
      MenuProps={{
        PaperProps: {
          sx: styles.paper,
        },
        sx: styles.menu,
      }}
      {...rest}
    />
  );
};

export default TypeSelect;
