import { Box } from '@mui/material';
import type { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';
import { MarketGroupsList, MarketType } from '../../@types/api';
import { QUERY_KEYS } from '../../constants';
import { createEditColumn, createIsActiveColumn, createSportIconColumn } from '../../helpers/columns';
import { defaultColumnsMarketGroups } from '../../helpers/table';
import useMutateData from '../../hooks/useMutateData';
import { usePagination } from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';
import { createColumn } from '../../utils';
import MarketGroupsPositionInput from '../atoms/MarketGroupsPositionInput';
import MarketGroupDetails from '../organisms/MarketGroupDetails';
import TableTemplate from '../templates/TableTemplate';

const makeStyles = (selectedRow: MarketGroupsList | null) => ({
  container: {
    display: 'flex',
    height: '100%',
    minHeight: 0,
    gap: '1.5rem',
    position: 'relative',
    width: '100%',
  },
  createItemBtn: selectedRow
    ? {
        position: 'absolute',
        top: 8,
        right: 0,
        ml: 0,
      }
    : {},
});

const MarketGroupsPage = () => {
  const [selectedRow, setSelectedRow] = useState<MarketGroupsList | null>(null);
  const styles = makeStyles(selectedRow);
  const {
    data: marketGroupsData,
    updateQueryParams,
    isFetching,
    isLoading,
    changeQuery,
  } = usePagination<MarketGroupsList>('market-type-groups', {
    page: 1,
    limit: 25,
    isActive: true,
  });
  const { deleteData, updateData } = useMutateData('market-type-groups', [QUERY_KEYS.marketTypeGroups]);
  const { handleSort } = useSort(changeQuery);

  const onCellClick = (params: GridCellParams) => {
    if (params.field !== 'edit' && params.field !== 'isActive' && params.field !== '__check__') {
      setSelectedRow(params.row);
    }
  };

  const columns: GridColDef[] = [
    createColumn('id', 'ID', { sortable: false }),
    createSportIconColumn<MarketType>(),
    createColumn('sportName', 'Sport', {
      valueGetter: (params) => params.row.sport.name,
    }),
    createColumn('name', 'Name'),
    createColumn('position', 'Position', {
      renderCell: (params) => (
        <MarketGroupsPositionInput
          defaultValue={params.row.position}
          onEnterClick={updateData}
          marketId={params.id as string}
        />
      ),
    }),
    createColumn('usedFor', 'Used for'),
    createIsActiveColumn<MarketType>({
      updateData,
    }),
    createColumn('displayType', 'Display'),
    createEditColumn({
      deleteData,
      columnName: 'Actions',
    }),
  ];

  const hideGroupDetails = () => {
    setSelectedRow(null);
  };

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  return (
    <Box sx={styles.container}>
      <TableTemplate
        rows={marketGroupsData?.items || []}
        columns={columns}
        loading={isFetching || isLoading}
        rowCount={marketGroupsData?.count || 0}
        createItemBtnLabel='Add new market group'
        defaultVisibleColumns={defaultColumnsMarketGroups}
        handlePaginationModelChange={updateQueryParams}
        createItemBtnStyle={styles.createItemBtn}
        handleSearch={handleSearch}
        onCellClick={onCellClick}
        handleSort={handleSort}
        changeQuery={changeQuery}
        formType='market-groups'
      />
      {selectedRow && <MarketGroupDetails handleClose={hideGroupDetails} marketGroupData={selectedRow} />}
    </Box>
  );
};

export default MarketGroupsPage;
