import { Stack } from '@mui/material';
import { QUERY_KEYS } from '../../../constants';
import LiabilityAssignTable from '../../organisms/LiabilityAssignTable';
import RiskFactorDefaultsTable from '../../organisms/RiskFactorDefaultsTable';
import RiskManagementTable from '../../organisms/RiskManagementTable';

const RiskSettingsPage = () => {
  return (
    <Stack spacing={2} direction='row'>
      <Stack direction='column' width='50%' spacing={2}>
        <RiskManagementTable
          path='risk-management/liabilities'
          queryKey={[QUERY_KEYS.liabilities]}
          tableHeader='Liability'
        />
        <RiskFactorDefaultsTable productType='pre-match' />
        <RiskFactorDefaultsTable productType='in-play' />
      </Stack>
      <Stack direction='column' width='50%' spacing={2}>
        <LiabilityAssignTable />
        <RiskManagementTable
          path='risk-management/risk-factors'
          queryKey={[QUERY_KEYS.riskFactors]}
          tableHeader='Risk Factors'
        />
      </Stack>
    </Stack>
  );
};

export default RiskSettingsPage;
