import { ReactComponent as Badminton } from '../assets/icons/sports/badminton.svg';
import { ReactComponent as Baseball } from '../assets/icons/sports/baseball.svg';
import { ReactComponent as Basketball } from '../assets/icons/sports/basketball.svg';
import { ReactComponent as Boxing } from '../assets/icons/sports/boxing.svg';
import { ReactComponent as Cricket } from '../assets/icons/sports/cricket.svg';
import { ReactComponent as Darts } from '../assets/icons/sports/darts.svg';
import { ReactComponent as Esports } from '../assets/icons/sports/esports.svg';
import { ReactComponent as Floorball } from '../assets/icons/sports/floorball.svg';
import { ReactComponent as Futsal } from '../assets/icons/sports/futsal.svg';
import { ReactComponent as Golf } from '../assets/icons/sports/golf.svg';
import { ReactComponent as Handball } from '../assets/icons/sports/handball.svg';
import { ReactComponent as IceHockey } from '../assets/icons/sports/ice_hockey.svg';
import { ReactComponent as MMA } from '../assets/icons/sports/mma.svg';
import { ReactComponent as RugbyLeague } from '../assets/icons/sports/rugby_league.svg';
import { ReactComponent as Snooker } from '../assets/icons/sports/snooker.svg';
import { ReactComponent as Soccer } from '../assets/icons/sports/soccer.svg';
import { ReactComponent as TableTennis } from '../assets/icons/sports/table_tennis.svg';
import { ReactComponent as Tennis } from '../assets/icons/sports/tennis.svg';
import { ReactComponent as Volleyball } from '../assets/icons/sports/volleyball.svg';
import { ReactComponent as WaterPolo } from '../assets/icons/sports/water_polo.svg';

export const SPORTS_ICONS_MAP = {
  badminton: Badminton,
  baseball: Baseball,
  basketball: Basketball,
  boxing: Boxing,
  cricket: Cricket,
  darts: Darts,
  esports: Esports,
  floorball: Floorball,
  futsal: Futsal,
  golf: Golf,
  handball: Handball,
  ice_hockey: IceHockey,
  mma: MMA,
  rugby_league: RugbyLeague,
  snooker: Snooker,
  soccer: Soccer,
  table_tennis: TableTennis,
  tennis: Tennis,
  volleyball: Volleyball,
  water_polo: WaterPolo,
} as const;
