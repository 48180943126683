import { useQuery } from '@tanstack/react-query';
import { Control, FieldError, FieldValues, Merge, Path } from 'react-hook-form';
import { Liability, PaginatedData } from '../../@types/api';
import { QUERY_KEYS } from '../../constants';
import { getData } from '../../utils/api';
import FormSelect from '../molecules/FormSelect';

type LiabilityLimitSelectProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  label?: string;
  competitionIds?: string | string[];
  error?: Merge<FieldError, (FieldError | undefined)[]>;
  multiple?: boolean;
  closeMenuOnSelect?: boolean;
};

const LiabilityLimitSelect = <T extends FieldValues>({
  control,
  name,
  label,
  error,
  multiple,
  closeMenuOnSelect,
}: LiabilityLimitSelectProps<T>) => {
  const { data: liabilityLimitData } = useQuery([QUERY_KEYS.liabilities], {
    queryFn: (): Promise<PaginatedData<Liability>> => getData('risk-management/liabilities'),
  });
  return (
    <FormSelect
      label={label ?? 'Liability Limit'}
      name={name}
      control={control}
      options={liabilityLimitData?.items || []}
      error={error}
      multiple={multiple}
      closeOnSelect={closeMenuOnSelect}
    />
  );
};

export default LiabilityLimitSelect;
