import { GridColDef, GridRowSelectionModel, GridCellParams } from '@mui/x-data-grid';
import { Box, IconButton } from '@mui/material';
import { useState } from 'react';
import { usePagination } from '../../hooks/usePagination';
import { TMessage } from '../../@types';
import dayjs from 'dayjs';
import { useMutation } from '@tanstack/react-query';
import { deleteData } from '../../utils/api';
import { QUERY_KEYS } from '../../constants';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import { StyledMessagesTable } from '../atoms/StyledMessagesTable';
import MessagesLayout from '../layouts/MessagesLayout';
import DeleteIcon from '../icons/DeleteIcon';
import MessagesThread from '../organisms/MessagesThread';
import useMutateData from '../../hooks/useMutateData';

const MessagesInboxPage = () => {
  const invalidateData = useInvalidateQuery();
  const { data: messages } = usePagination<TMessage>('messages');
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [messageThread, setMessageThread] = useState({
    open: false,
    messageId: '',
  });

  const { mutate: deleteMessage } = useMutation({
    mutationFn: (data: { messageIds: string[] }) => deleteData('messages', 'feed', {}, data),
    onSuccess: () => {
      invalidateData([QUERY_KEYS.messages]);
    },
  });

  const { updateData } = useMutateData('messages', [QUERY_KEYS.messagesCount], 'feed', true);

  const deleteMessages = () => {
    setMessageThread({
      open: false,
      messageId: '',
    });
    deleteMessage({
      messageIds: selectedRows,
    });
  };

  const handleSelectionChange = (newSelection: GridRowSelectionModel) => {
    setSelectedRows(newSelection as string[]);
  };

  const onCellClick = (params: GridCellParams) => {
    setMessageThread({
      open: true,
      messageId: params.row.id,
    });

    const selectedMessage = messages?.items.find((item) => item.id === params.row.id);

    const messageReplyIds = selectedMessage?.replies.map((replyMsg) => replyMsg.id);
    const markAsReadMessageIds = messageReplyIds ? [selectedMessage?.id, ...messageReplyIds] : [selectedMessage?.id];

    updateData('', {
      messageIds: markAsReadMessageIds,
    });
  };

  const columns: GridColDef[] = [
    { field: 'from', headerName: 'From:', width: 150 },
    {
      field: 'subject',
      headerName: 'Subject:',
      width: 150,
    },
    {
      field: 'date',
      headerName: 'Date:',
      width: 150,
    },
    {
      field: 'spacer',
      headerName: '',
      flex: 1,
      sortable: false,
    },
    {
      field: 'delete',
      headerName: '',
      width: 150,
      renderHeader: () =>
        selectedRows.length > 0 ? (
          <IconButton onClick={deleteMessages}>
            <DeleteIcon />
          </IconButton>
        ) : null,
      sortable: false,
    },
  ];

  const rows = messages?.items.map((message) => {
    return {
      from: message.author,
      subject: message.topic,
      date: dayjs(message.createdAt).format('DD.MM.YYYY'),
      id: message.id,
    };
  });

  return (
    <MessagesLayout>
      <Box sx={{ display: 'flex', gap: '0 24px' }}>
        {rows && rows.length > 0 ? (
          <StyledMessagesTable
            columns={columns}
            rows={rows}
            disableColumnMenu
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={handleSelectionChange}
            onCellClick={onCellClick}
          />
        ) : (
          'No messages to display'
        )}
        {messageThread.open && (
          <MessagesThread
            parentId={messageThread.messageId}
            closeThread={() => setMessageThread({ open: false, messageId: '' })}
          />
        )}
      </Box>
    </MessagesLayout>
  );
};

export default MessagesInboxPage;
