import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DialogType, StyleObj } from '../../../@types';
import { EventPart, Sport } from '../../../@types/api';
import { QUERY_KEYS } from '../../../constants';
import { useModal } from '../../../contexts/ModalContext';
import useMutateData from '../../../hooks/useMutateData';
import { SportFormData, sportFormSchema } from '../../../schema';
import FormFieldStack from '../../atoms/FormFieldStack';
import Switch from '../../atoms/Switch';
import FormModalLayout from '../../layouts/FormModalLayout';
import FormNumberInput from '../../molecules/FormNumberInput';
import IconSelect from '../../molecules/IconSelect';
import FormAutocomplete from '../FormAutocomplete';

const DEFAULT_SPORT_INPUT_VALUES = {
  name: '',
  shortName: null,
  position: null,
  isActive: false,
  icon: 'soccer',
  eventPartIds: [],
};

const styles: StyleObj = {
  position: {
    width: 80,
  },
  select: {
    height: 48,
    width: 80,
    '& .MuiSelect-select > img': {
      pt: '6px',
    },
  },
};

type Props = DialogType;

const SportsForm = ({ closeModal }: Props) => {
  const { item } = useModal<Sport>();
  const { createData, updateData } = useMutateData('sports', [QUERY_KEYS.sports]);

  const schema = item ? sportFormSchema.omit({ eventPartIds: true }) : sportFormSchema;

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<SportFormData>({
    defaultValues: DEFAULT_SPORT_INPUT_VALUES,
    resolver: zodResolver(schema),
  });

  const eventPartIds = watch('eventPartIds');

  useEffect(() => {
    if (item) {
      reset(item);
    }
  }, [item, reset]);

  const handleClose = () => {
    closeModal?.();
    reset(DEFAULT_SPORT_INPUT_VALUES);
  };

  const onFormSubmit = (newSport: SportFormData) => {
    if (item) {
      updateData(item.id, newSport, () => {
        handleClose();
      });
    } else {
      createData(newSport, () => {
        handleClose();
      });
    }
  };

  return (
    <FormModalLayout
      onSave={handleSubmit(onFormSubmit)}
      label={item ? 'Edit sport' : 'Add new sport'}
      onClose={handleClose}
      isEdit={!!item}
    >
      <TextField error={!!errors.name} helperText={errors.name?.message} label='Name' {...register('name')} required />
      <TextField
        error={!!errors.shortName}
        helperText={errors.shortName?.message}
        label='Short name'
        {...register('shortName')}
      />
      <FormFieldStack label='Position'>
        <FormNumberInput name='position' control={control} error={errors.position} sx={styles.position} />
      </FormFieldStack>
      <FormFieldStack label='Status'>
        <Controller name='isActive' control={control} render={({ field }) => <Switch {...field} ref={null} />} />
      </FormFieldStack>
      <FormFieldStack label='Icon'>
        <IconSelect control={control} name='icon' iconType='sport' />
      </FormFieldStack>
      {!item && (
        <FormAutocomplete<EventPart, SportFormData>
          name='eventPartIds'
          control={control}
          label='Event parts'
          required
          url='event-parts'
          queryKey={[QUERY_KEYS.eventParts]}
          queryParams={{
            isActive: true,
            eventPartIds,
          }}
          getOptionLabel={(options, value) => {
            const option = options.find((option) => option?.id === value);
            return option?.name || '';
          }}
          multiple
          error={errors.eventPartIds}
        />
      )}
    </FormModalLayout>
  );
};

export default SportsForm;
