import { Box, Theme, Typography } from '@mui/material';
import React from 'react';
import dayjs from 'dayjs';
import MessagesBadge from '../atoms/MessagesBadge';

const extractHoursFromDateFormat = (date: string) => {
  const formattedTime = dayjs(date).format('HH:MM:ss');
  const formattedDate = dayjs(date).format('DD.MM.YYYY');

  return {
    displayDate: formattedDate,
    displayTime: formattedTime,
  };
};

const makeStyles = (isRecievedMessage: boolean) => ({
  container: {
    display: 'flex',
    alignItems: 'end',
    gap: '0 5px',
    justifyContent: isRecievedMessage ? 'unset' : 'flex-end',
  },
  text: {
    border: isRecievedMessage ? (theme: Theme) => `1px solid ${theme.palette.primary.lightBorder}` : 'unset',
    backgroundColor: isRecievedMessage ? 'neutral.600' : 'primary.main',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 5px',
    borderRadius: '4px',
    fontSize: '14px',
    order: isRecievedMessage ? 'unset' : 3,
    color: isRecievedMessage ? 'unset' : '#fff',
  },
  time: {
    fontSize: '12px',
  },
});

type Props = {
  author: string;
  text: string;
  date: string;
  messageCreator: string;
};

const Message = ({ author, text, date, messageCreator }: Props) => {
  const { displayDate, displayTime } = extractHoursFromDateFormat(date);
  const isRecievedMessage = messageCreator === author;
  const styles = makeStyles(isRecievedMessage);

  return (
    <Box sx={styles.container}>
      {isRecievedMessage && <MessagesBadge author={author} />}
      <Box sx={styles.text}>{text}</Box>
      <Box>
        <Typography sx={styles.time}>{displayDate}</Typography>
        <Typography sx={styles.time}>{displayTime}</Typography>
      </Box>
    </Box>
  );
};

export default Message;
