import { AccessTime } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { StyleObj } from '../../@types';
import { OPERATOR_COUNTDOWN_DURATION } from '../../constants';
import useCountdownTimer from '../../hooks/useCountdownTimer';

const styles: StyleObj = {
  timerWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    position: 'absolute',
    top: 12,
    right: 24,
  },
};

type Props = {
  ticketId: string;
  createdAt: string;
};

const calculateRemainingSeconds = (createdAt: string, duration: number) => {
  const createdAtTimestamp = dayjs(createdAt);
  const currentTimestamp = dayjs();
  return Math.max(createdAtTimestamp.add(duration, 'second').diff(currentTimestamp, 'second'), 0);
};

const TicketAuthCountdown = ({ ticketId, createdAt }: Props) => {
  const remainingSeconds = useMemo(
    () => calculateRemainingSeconds(createdAt, OPERATOR_COUNTDOWN_DURATION),
    [createdAt]
  );

  const { seconds: operatorTimeRemaining, resetTimer } = useCountdownTimer(remainingSeconds);

  useEffect(() => {
    resetTimer();
  }, [resetTimer, ticketId]);

  return (
    <Box sx={styles.timerWrapper}>
      <Typography variant='h6' fontWeight={600}>
        Operator:
      </Typography>
      <AccessTime color='primary' fontSize='large' />
      <Typography fontSize={16} fontWeight={700}>
        {operatorTimeRemaining}
      </Typography>
    </Box>
  );
};

export default TicketAuthCountdown;
