import { useAuth } from 'neofusion-fe-shared';
import { useCallback, useMemo } from 'react';
import { BaseTab, Permission } from '../@types';
import { PERMISSIONS, USER_ROLES } from '../constants/keycloak';
import { UserRole } from '../@types/api';

const usePermissions = () => {
  const user = useAuth();

  const hasPermission = useCallback(
    (permission: Permission) => {
      return user.permissions.includes(PERMISSIONS[permission]);
    },
    [user]
  );

  const role: UserRole = useMemo(() => {
    if (hasPermission('defaultAdminRole')) return USER_ROLES.ADMIN;
    if (hasPermission('defaultManagerRole')) return USER_ROLES.MANAGER;
    if (hasPermission('defaultAgentRole')) return USER_ROLES.AGENT;
    if (hasPermission('defaultTraderRole')) return USER_ROLES.TRADER;
    if (hasPermission('defaultTechSupportRole')) return USER_ROLES.TECH_SUPPORT;

    return USER_ROLES.SUPER_ADMIN;
  }, [hasPermission]);

  const filterAccessibleTabs = useCallback(
    <T extends BaseTab>(tabs: T[]) => {
      return tabs.filter((tab) => {
        // If tab.permission is undefined, we allow access to all users
        if (!tab.permission) return true;

        return hasPermission(tab.permission);
      });
    },
    [hasPermission]
  );

  return {
    hasPermission,
    role,
    filterAccessibleTabs,
  };
};

export default usePermissions;
