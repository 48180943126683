import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { DialogType, StyleObj } from '../../../@types';
import { Event, EventPartScoresResponse } from '../../../@types/api';
import { QUERY_KEYS } from '../../../constants';
import { getLatestValue } from '../../../helpers';
import { useInvalidateQuery } from '../../../hooks/useInvalidateQuery';
import useMutateData from '../../../hooks/useMutateData';
import { ResultsFormData } from '../../../schema';
import { getData } from '../../../utils/api';
import FormModalLayout from '../../layouts/FormModalLayout';
import FormNumberInput from '../../molecules/FormNumberInput';

const defaultValues = {
  scores: [],
};

type Props = DialogType & {
  item: Event;
};

const styles: StyleObj = {
  table: {
    '& .MuiTableCell-root ': {
      borderColor: 'primary.lightBorder',
    },
  },
  tableContainer: {
    width: '100%',
    overflowX: 'auto',
  },
  inputCell: {
    width: 50,
    padding: 0,
  },
  input: {
    width: 50,
    padding: 0,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiOutlinedInput-input': {
      padding: 0,
      textAlign: 'center',
    },
  },
};

const ResultsForm = ({ item, closeModal }: Props) => {
  const eventId = item?.id;

  const invalidateQuery = useInvalidateQuery();

  const { data: eventPartScores } = useQuery([QUERY_KEYS.eventPartScores, eventId], {
    queryFn: (): Promise<EventPartScoresResponse> =>
      getData('event-part-scores', {
        eventId,
      }),
    enabled: !!eventId,
    select: (data) => data.items,
  });
  const { updateData } = useMutateData('event-part-scores', [QUERY_KEYS.eventPartScores, eventId]);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ResultsFormData>({
    defaultValues: defaultValues,
  });

  const { fields } = useFieldArray({
    control,
    name: 'scores',
  });

  useEffect(() => {
    if (eventPartScores) {
      reset({
        scores: eventPartScores.map((eventPartScore) => ({
          eventPartScoreId: eventPartScore.id,
          eventPartName: eventPartScore.eventPartName,
          valueHome: eventPartScore.valueHome?.toString() as unknown as number,
          valueAway: eventPartScore.valueAway?.toString() as unknown as number,
        })),
      });
    }
  }, [reset, eventPartScores]);

  const handleClose = () => {
    closeModal?.();
    reset(defaultValues);
  };

  const onFormSubmit = (data: ResultsFormData) => {
    updateData(
      '',
      {
        scores: data.scores.map((score) => ({
          ...score,
          valueHome: score.valueHome ? Number(score.valueHome) : null,
          valueAway: score.valueAway ? Number(score.valueAway) : null,
        })),
      },
      () => {
        invalidateQuery([QUERY_KEYS.results]);
        handleClose();
      }
    );
  };

  return (
    <FormModalLayout onSave={handleSubmit(onFormSubmit)} label={getLatestValue(item, 'name')} onClose={handleClose}>
      <TableContainer sx={styles.tableContainer}>
        <Table border={1} sx={styles.table}>
          <TableBody>
            {fields.map((field, index) => {
              return (
                <TableRow key={field.eventPartScoreId}>
                  <TableCell>{field.eventPartName}</TableCell>
                  <TableCell sx={styles.inputCell}>
                    <FormNumberInput
                      name={`scores.${index}.valueHome`}
                      control={control}
                      error={errors.scores?.[index]?.valueHome}
                      showHelperText={false}
                      allowNegative
                      valueAsString
                      sx={styles.input}
                    />
                  </TableCell>
                  <TableCell sx={styles.inputCell}>
                    <FormNumberInput
                      name={`scores.${index}.valueAway`}
                      control={control}
                      error={errors.scores?.[index]?.valueAway}
                      showHelperText={false}
                      allowNegative
                      valueAsString
                      sx={styles.input}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </FormModalLayout>
  );
};

export default ResultsForm;
