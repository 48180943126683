import { zodResolver } from '@hookform/resolvers/zod';
import { AddCircle, HighlightOff, Save } from '@mui/icons-material';
import {
  Button,
  IconButton,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Fragment } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { StyleObj } from '../../@types';
import { RiskFactor } from '../../@types/api';
import useMutateData from '../../hooks/useMutateData';
import { usePagination } from '../../hooks/usePagination';
import { RiskManagementFormData, riskManagementFormSchema } from '../../schema';
import FormNumberInput from '../molecules/FormNumberInput';

const styles: StyleObj = {
  table: {
    '& .MuiTableRow-head': {
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      backgroundColor: 'primary.main',
      '& th': {
        color: 'white',
      },
      '& th:first-child': {
        borderRadius: '8px 0 0 0',
      },
      '& th:last-child': {
        borderRadius: '0 8px 0 0',
      },
    },
    '& .MuiTableRow-root': {
      '& td': {
        p: 1,
        borderBottom: 'none',
      },
    },
    '& .MuiTableRow-root:nth-of-type(even)': {
      backgroundColor: 'background.light',
    },
  },
  iconBtn: {
    p: 0,
  },
  addBtn: {
    mt: 2,
  },
  numberInput: { width: '100%' },
};

const inputProps = {
  disableUnderline: true,
  inputProps: { sx: { textAlign: 'center', fontSize: 14 } },
};

const defaultValues = {
  items: [],
};

type RiskManagementTableProps = {
  path: string;
  queryKey: string[];
  tableHeader: string;
};

const RiskManagementTable = ({ path, queryKey, tableHeader }: RiskManagementTableProps) => {
  const { data: riskManagementData } = usePagination<RiskFactor>(path, {
    page: 1,
    limit: 100,
  });

  const { deleteData, createData } = useMutateData(path, queryKey);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm<RiskManagementFormData>({
    resolver: zodResolver(riskManagementFormSchema),
    defaultValues,
  });

  const { fields, append } = useFieldArray({
    control,
    name: 'items',
  });

  const watchFields = watch('items');

  const addNewItem = () => {
    append({ name: '', value: '' });
  };

  const handleSave = async (data: RiskManagementFormData) => {
    const payload = {
      name: data.items[0].name,
      value: parseFloat(data.items[0].value),
    };
    createData(payload);
    reset(defaultValues);
  };

  const handleDelete = (id?: string) => {
    if (!id) return;

    deleteData(id);
  };

  const items = riskManagementData?.items || [];

  return (
    <Fragment>
      <TableContainer>
        <MuiTable aria-label='risk factors table' sx={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell align='center' width='50%'>
                <Typography variant='h5'>{tableHeader}</Typography>
              </TableCell>
              <TableCell align='center' />
              <TableCell align='center' />
            </TableRow>
          </TableHead>
          <TableBody>
            {[...items, ...fields].map((liability, index) => {
              // since we are appending new items to the items array, we need to check if the current item is new or not
              // if it is new, we will display the input fields, otherwise we will display the data from the items array
              // to check if the item is new, we will check if the index is greater than or equal to the length of the items array
              const isNew = index >= items.length;
              let saveDisabled = false;
              if (isNew) {
                // in the fields array, the index starts from 0, so we need to subtract the length of the items array
                index = index - items.length;
                saveDisabled = !watchFields[index]?.name || !watchFields[index]?.value;
              }

              return (
                <TableRow key={liability.id}>
                  <TableCell align='center'>
                    {isNew && (
                      <TextField
                        {...register(`items.${index}.name` as const)}
                        fullWidth
                        variant='standard'
                        InputProps={inputProps}
                        error={!!errors.items?.[index]?.name}
                      />
                    )}
                    {!isNew && liability.name}
                  </TableCell>
                  <TableCell align='center'>
                    {isNew && (
                      <FormNumberInput
                        control={control}
                        name={`items.${index}.value` as const}
                        InputProps={inputProps}
                        variant='standard'
                        error={errors.items?.[index]?.value}
                        showHelperText={false}
                        sx={styles.numberInput}
                        allowDecimals
                        decimalPlaces={2}
                        valueAsString
                      />
                    )}
                    {!isNew && liability.value}
                  </TableCell>
                  <TableCell width={30}>
                    {isNew && (
                      <IconButton disabled={saveDisabled} onClick={handleSubmit(handleSave)} sx={styles.iconBtn}>
                        <Tooltip title='Save'>
                          <Save color={saveDisabled ? 'disabled' : 'primary'} />
                        </Tooltip>
                      </IconButton>
                    )}
                    {!isNew && (
                      <Tooltip title='Delete'>
                        <IconButton onClick={() => handleDelete(liability.id)} sx={styles.iconBtn}>
                          <HighlightOff />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
      <Button
        variant='text'
        startIcon={<AddCircle />}
        disabled={fields.length > 0}
        onClick={addNewItem}
        sx={styles.addBtn}
      >
        Add new {tableHeader}
      </Button>
    </Fragment>
  );
};

export default RiskManagementTable;
