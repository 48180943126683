import { useMemo } from 'react';
import { DefaultValues, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { LS_KEYS } from '../constants';
import usePersist from './usePersist';

type UseFilterFormProps<T extends FieldValues> = {
  defaultData: DefaultValues<T>;
  onSubmit: SubmitHandler<T>;
};

const useFilterForm = <T extends FieldValues>({ defaultData, onSubmit }: UseFilterFormProps<T>) => {
  const { pathname } = useLocation();
  const formDataKey = `${LS_KEYS.appFormFilter}-${pathname}`;

  const { setPersistData, getPersistData, deletePersistData } = usePersist(false);

  const savedData = useMemo(() => {
    const persistedData = getPersistData<DefaultValues<T>>(formDataKey);

    return persistedData ?? defaultData;
  }, [formDataKey, getPersistData, defaultData]);

  const { control, handleSubmit, ...rest } = useForm<T>({ defaultValues: savedData });

  const handleFormSubmit = (data: T) => {
    setPersistData<T>(formDataKey, data);
    onSubmit(data);
  };

  const handleResetFilter = (resetData: Partial<T>, changeQuery: (data: Partial<T>) => void) => {
    deletePersistData(formDataKey);
    changeQuery(resetData);
  };

  return { control, handleSubmit: handleSubmit(handleFormSubmit), handleResetFilter, ...rest };
};

export default useFilterForm;
