import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DialogType } from '../../../@types';
import { Event } from '../../../@types/api';
import { QUERY_KEYS } from '../../../constants';
import { useModal } from '../../../contexts/ModalContext';
import { getLatestValue } from '../../../helpers';
import useMutateData from '../../../hooks/useMutateData';
import { EditOutrightFormData, editOutrightFormSchema } from '../../../schema';
import FormModalLayout from '../../layouts/FormModalLayout';
import DateTimeSelect from '../../molecules/DateTimeSelect';

const DEFAULT_FORM_DATA = {
  name: '',
  startDate: undefined,
  information: null,
};

type Props = DialogType;

const EditOutrightForm = ({ closeModal }: Props) => {
  const { createData: createEventChanges } = useMutateData('event-changes', [QUERY_KEYS.outrights]);
  const { item } = useModal<Event>();

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<EditOutrightFormData>({
    defaultValues: DEFAULT_FORM_DATA,
    resolver: zodResolver(editOutrightFormSchema),
  });

  const handleClose = () => {
    closeModal?.();
    reset(DEFAULT_FORM_DATA);
  };

  useEffect(() => {
    if (item) {
      reset({
        ...item,
        name: getLatestValue(item, 'name'),
        startDate: dayjs(getLatestValue(item, 'startDate')),
        information: getLatestValue(item, 'information'),
      });
    }
  }, [item, reset]);

  const onFormSubmit = (data: EditOutrightFormData) => {
    if (item) {
      const payload = {
        name: data.name,
        startDate: data.startDate?.toISOString(),
        information: data.information,
      };

      createEventChanges(
        {
          eventId: item.id,
          ...payload,
        },
        () => handleClose()
      );
    }
  };

  return (
    <FormModalLayout
      onSave={handleSubmit(onFormSubmit)}
      label='Edit outright'
      onClose={handleClose}
      isEdit
      editEntity='events'
    >
      <TextField
        error={!!errors.name}
        helperText={errors.name?.message}
        label='Match name'
        {...register('name')}
        disabled
      />
      <DateTimeSelect control={control} name='startDate' error={errors.startDate} disablePast />
      <TextField
        error={!!errors.information}
        helperText={errors.information?.message}
        label='Information'
        rows={3}
        multiline
        {...register('information')}
      />
    </FormModalLayout>
  );
};

export default EditOutrightForm;
