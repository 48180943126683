import { TextField } from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { StyleObj } from '../../@types';

type SearchTableDataProps = {
  onSearch: (value: string | null) => void;
};

const styles: StyleObj = {
  search: {
    width: 200,
  },
  searchIcon: {
    mr: 0.5,
  },
};

const SearchTableData = ({ onSearch }: SearchTableDataProps) => {
  const [search, setSearch] = useState('');
  const location = useLocation();

  const isValidSearchTerm = search.trim() !== '';

  // TODO: Rethink, quick solution for resetting search field when switching tabs
  useEffect(() => {
    setSearch('');
  }, [location.pathname]);

  const handleSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);

    if (event.target.value === '') {
      onSearch(null);
    }
  };

  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && isValidSearchTerm) {
      onSearch(search);
    }
  };
  return (
    <TextField
      placeholder='Search'
      value={search}
      onChange={handleSearchValueChange}
      onKeyDown={handleEnterKeyPress}
      name='search'
      sx={styles.search}
      InputProps={{
        startAdornment: <GridSearchIcon sx={styles.searchIcon} />,
      }}
    />
  );
};

export default SearchTableData;
