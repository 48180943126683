import { useInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Market } from '../@types/api';
import { QUERY_KEYS } from '../constants';
import { getData, hasApiMorePages } from '../utils/api';

const useMarketsInfiniteScroll = (eventId: string, activeGroup?: string | false) => {
  const observer = useRef<IntersectionObserver | null>(null);
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);

  const {
    data: marketsData,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
  } = useInfiniteQuery({
    queryKey: [
      QUERY_KEYS.markets,
      {
        eventId,
        activeGroup,
      },
    ],
    queryFn: ({ pageParam = 1 }) => {
      return getData('markets', {
        page: pageParam,
        limit: 100,
        marketTypeGroupId: activeGroup ? activeGroup : undefined,
        eventId,
      });
    },
    getNextPageParam: (lastPage) => {
      if (hasApiMorePages(lastPage.count, lastPage.page, lastPage.limit)) {
        return lastPage.page + 1;
      }
    },
  });

  const lastElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isLoading || isFetching || isFetchingNextPage) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          setIsFetchingNextPage(true);
          fetchNextPage().then(() => setIsFetchingNextPage(false));
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoading, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage]
  );

  const markets = useMemo(() => marketsData?.pages.map((page) => page.items as Market[]).flat(), [marketsData]);

  return { markets, isLoading, isFetching, isFetchingNextPage, lastElementRef };
};

export default useMarketsInfiniteScroll;
