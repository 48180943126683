import { Add } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { StyleObj } from '../../../@types';
import { CRMApiEntityType, Task } from '../../../@types/api';
import { CRM_TASKS_TABS } from '../../../constants';
import { useCRMUser } from '../../../contexts/CRMUserContext';
import { useModal } from '../../../contexts/ModalContext';
import { usePagination } from '../../../hooks/usePagination';
import { StyledTab, StyledTabs } from '../../atoms/StyledTabs';
import TabPanel from '../../atoms/TabPanel';
import FormModal from '../../modals/FormModal';
import CRMTask from '../../molecules/CRMTask';

const styles: StyleObj = {
  addButton: {
    width: 'fit-content',
  },
};

type CustomerTaskTab = {
  label: string;
  value: string;
};

type Props = {
  userType: CRMApiEntityType;
};

const filterTasksByTab = (tasks: Task[], tab: CustomerTaskTab) => {
  const currentDate = new Date();
  return tasks.filter((task) => {
    const taskDate = new Date(task.scheduledTime);
    return tab.value === 'past' ? taskDate < currentDate : taskDate >= currentDate;
  });
};

const CRMTasksTab = ({ userType }: Props) => {
  const [activeTab, setActiveTab] = useState(0);
  const { openModal } = useModal();

  const { selectedCRMUser } = useCRMUser();

  const selectedCRMUserId = selectedCRMUser?.id;

  const endpoint = userType === 'customer' ? 'tasks/customer' : 'tasks/agent';

  const { data: tasks, changeQuery } = usePagination<Task>(
    endpoint,
    {
      page: 1,
      limit: 25,
      userId: selectedCRMUserId,
    },
    undefined,
    'crm'
  );

  useEffect(() => {
    changeQuery({ userId: selectedCRMUserId });
  }, [changeQuery, selectedCRMUserId]);

  const handleTabChange = (_event: React.SyntheticEvent, selectedTab: number) => {
    setActiveTab(selectedTab);
  };

  const filteredTasks = useMemo(
    () => tasks?.items && filterTasksByTab(tasks?.items, CRM_TASKS_TABS[activeTab]),
    [activeTab, tasks?.items]
  );

  return (
    <Stack spacing={1}>
      <Button
        onClick={() => openModal(<FormModal form='tasks' item={selectedCRMUserId} />)}
        startIcon={<Add />}
        sx={styles.addButton}
      >
        Schedule Task
      </Button>
      <StyledTabs value={activeTab} onChange={handleTabChange} variant='fullWidth' indicatorWidth={114}>
        {CRM_TASKS_TABS.map((tab, index) => (
          <StyledTab key={index} label={tab.label}></StyledTab>
        ))}
      </StyledTabs>
      {CRM_TASKS_TABS.map((tab, index) => (
        <TabPanel key={tab.value} index={index} value={activeTab}>
          <Stack spacing={1}>
            {filteredTasks?.map((task) => (
              <CRMTask key={task.id} task={task} userType={userType} isPast={tab.value === 'past'} />
            ))}
          </Stack>
        </TabPanel>
      ))}
    </Stack>
  );
};

export default CRMTasksTab;
