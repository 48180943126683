import { TextField, TextFieldProps } from '@mui/material';
import { ControllerRenderProps } from 'react-hook-form';

type NumberInputProps = TextFieldProps & {
  showHelperText?: boolean;
  allowNegative?: boolean;
  allowDecimals?: boolean;
  decimalPlaces?: number;
  valueAsString?: boolean;
} & {
  onChange: Pick<ControllerRenderProps, 'onChange'>['onChange'];
};

const NumberInput = ({
  value,
  error,
  helperText,
  label,
  onChange,
  defaultValue,
  showHelperText = true,
  allowNegative = false,
  allowDecimals = false,
  decimalPlaces = 2,
  valueAsString = false,
  ...rest
}: NumberInputProps) => {
  const handleValueChange = (value: string, onChange: (value: string | number | null) => void) => {
    const allowNegativePattern = allowNegative ? '-?' : '';
    const decimalPattern = allowDecimals ? `\\.?\\d{0,${decimalPlaces}}` : '';

    const pattern = `${allowNegativePattern}\\d*${decimalPattern}`;
    const regex = new RegExp(`^${pattern}$`);

    if (regex.test(value)) {
      if (valueAsString) {
        onChange(value);
      } else {
        onChange(value ? Number(value) : null);
      }
    }
  };

  return (
    <TextField
      defaultValue={defaultValue}
      value={value ?? ''}
      onChange={(e) => handleValueChange(e.target.value, onChange)}
      error={!!error}
      helperText={helperText}
      label={label}
      {...rest}
    />
  );
};

export default NumberInput;
