import { Chip, ChipProps, styled } from '@mui/material';
import { SxProps } from '@mui/system';

type CustomerDetailBadgeProps = ChipProps & {
  badgeColor?: string;
  textColor?: string;
  label?: string;
  sx?: SxProps;
};

const CustomerDetailBadge = styled(({ badgeColor, textColor, label, ...rest }: CustomerDetailBadgeProps) => (
  <Chip label={label} title={label} {...rest} />
))<CustomerDetailBadgeProps>(({ theme, badgeColor, textColor }) => ({
  backgroundColor: badgeColor ?? '#5BACE7',
  color: textColor ?? theme.palette.primary.contrastText,
  borderRadius: '8px',
  fontWeight: 600,
  height: 28,
  gap: '4px',
  '& .MuiChip-label': {
    padding: '4px 6px',
  },
  maxWidth: 180,
}));

export default CustomerDetailBadge;
