import { getAccessToken } from 'neofusion-fe-shared';
import { useEffect, useState } from 'react';
import Log from '../../utils/logger';

const CasinoPage = () => {
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const accessToken = await getAccessToken();
        if (accessToken) {
          setToken(accessToken);
        }
      } catch (error) {
        Log('[Casino] Error fetching access token: ', error, 'error');
      }
    };

    fetchToken();
  }, []);

  if (!token) return null;
  const casinoBOUrl =
    process.env.REACT_APP_CASINO_BO_URL ?? `https://casino-backoffice-sportsbook/${window.location.hostname}`;

  return (
    <iframe
      title='Casino BO page'
      src={`${casinoBOUrl}/?token=${token}`}
      style={{ width: '100%', height: '100%', border: 'none' }}
    />
  );
};

export default CasinoPage;
