import { MenuItem, Select } from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import type { IconType, StyleObj } from '../../@types';
import { ICON_MAPS } from '../../config/icons';
import IconRenderer from '../atoms/IconRenderer';

const styles: StyleObj = {
  select: {
    height: 48,
    width: 80,
    '& .MuiSelect-select > img': {
      pt: '6px',
    },
  },
  menu: {
    '& .MuiPaper-root': {
      maxHeight: 300,
    },
  },
};

type Props<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  iconType: IconType;
};

const IconSelect = <T extends FieldValues>({ control, name, iconType }: Props<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select
          sx={styles.select}
          MenuProps={{
            sx: styles.menu,
          }}
          {...field}
        >
          {Object.keys(ICON_MAPS[iconType]).map((name) => (
            <MenuItem key={name} value={name}>
              <IconRenderer name={name} type={iconType} />
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};

export default IconSelect;
