import React, { useState } from 'react';
import NumberInput from './NumberInput';

export const styles = {
  '& .MuiInputBase-input': {
    textAlign: 'center',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: '0 !important',
  },
};

type Props = {
  onEnterClick: (id: string, data: unknown) => void;
  marketId: string;
  defaultValue: number;
};

const MarketGroupsPositionInput = ({ onEnterClick, marketId, defaultValue }: Props) => {
  const [value, setValue] = useState<number | ''>(defaultValue || '');

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Enter') {
      onEnterClick(marketId, {
        position: value,
      });
    }
  };

  return <NumberInput value={value} onChange={(value) => setValue(value)} onKeyDown={handleKeyDown} sx={styles} />;
};

export default MarketGroupsPositionInput;
