import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { ConditionType } from '../../../@types/api';
import { CONDITION_ENTITY_LABELS } from '../../../constants';
import { createDescriptionColumn, createEditColumn } from '../../../helpers/columns';
import { defaultColumnsConditionTypes } from '../../../helpers/table';
import { usePagination } from '../../../hooks/usePagination';
import useSort from '../../../hooks/useSort';
import { createColumn } from '../../../utils';
import TableTemplate from '../../templates/TableTemplate';

const ConditionTypesPage = () => {
  const { data, updateQueryParams, isFetching, isLoading, changeQuery } =
    usePagination<ConditionType>('condition-types');

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  const { handleSort } = useSort(changeQuery);

  const conditionTypeColumns: GridColDef[] = [
    createColumn('id', 'ID', {
      sortable: false,
    }),
    createColumn('name', 'Name', { alwaysVisible: true }),
    createColumn('modelType', 'Entity', {
      alwaysVisible: true,
      valueGetter: ({ row }: GridValueGetterParams<ConditionType>) => CONDITION_ENTITY_LABELS[row.modelType],
    }),
    createColumn('verificationMsgAdmin', 'Verification message admin', {
      flex: 2,
      sortable: false,
    }),
    createColumn('verificationMsgClient', 'Verification message client', {
      flex: 2,
      sortable: false,
    }),
    createColumn('restrictionMsgAdmin', 'Restriction message admin', {
      flex: 2,
      sortable: false,
    }),
    createColumn('restrictionMsgClient', 'Restriction message client', {
      flex: 2,
      sortable: false,
    }),
    createDescriptionColumn(),
    createEditColumn(),
  ];

  return (
    <TableTemplate
      rows={data?.items || []}
      rowCount={data?.count || 0}
      columns={conditionTypeColumns}
      defaultVisibleColumns={defaultColumnsConditionTypes}
      loading={isFetching || isLoading}
      handlePaginationModelChange={updateQueryParams}
      handleSearch={handleSearch}
      handleSort={handleSort}
      changeQuery={changeQuery}
    />
  );
};

export default ConditionTypesPage;
