export default {
  name: 'CasinoPromotionalTopContent',
  title: 'Casino - Promotional Top Content',
  type: 'document',
  fields: [
    {
      name: 'title',
      title: 'Title',
      type: 'string',
      description: 'The title for the promotional content item',
      validation: (Rule: any) => Rule.required(),
    },
    {
      name: 'image',
      title: 'Image',
      type: 'image',
      description: 'The image for the promotional content',
      validation: (Rule: any) => Rule.required(),
    },
    {
      name: 'callToActionURL',
      title: 'Call to Action URL',
      type: 'url',
      description: 'The URL to which the call to action button should redirect',
      validation: (Rule: any) => Rule.required(),
    },
  ],
};
