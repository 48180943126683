import { HighlightOff } from '@mui/icons-material';
import { Box, Button, IconButton, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { PaginatedData, PlayerRisk, RiskFactor, Sport } from '../../@types/api';
import { PRODUCT_TYPES_OPTIONS, QUERY_KEYS } from '../../constants';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import useMutateData from '../../hooks/useMutateData';
import { getData } from '../../utils/api';

type SportsLimitsTabProps = {
  playerRisk: PlayerRisk;
  handleClose: () => void;
};

const styles = {
  dropdownsWrapper: { display: 'flex', gap: 2, mb: 2 },
  container: { maxHeight: '400px', overflow: 'auto' },
  menuProps: { maxHeight: 360 },
};

const defaultPairs = [{ sportId: '', riskFactorId: '' }];

const SportsLimitsTab = ({ handleClose, playerRisk }: SportsLimitsTabProps) => {
  const { id, productValue, additionalData } = playerRisk;

  const { updateData } = useMutateData(`risk-management/entity-risk-factors/${productValue}/punter`, [
    QUERY_KEYS.riskPlayers,
    id,
  ]);
  const invalidateData = useInvalidateQuery();

  const initialPairs = Object.entries(additionalData).map(([sportId, riskFactorId]) => ({
    sportId,
    riskFactorId,
  }));

  const [selectPairs, setSelectPairs] = useState(initialPairs.length === 0 ? defaultPairs : initialPairs);

  const handleValueChange = (index: number, key: string, value: string) => {
    const newPairs = [...selectPairs];
    newPairs[index] = { ...newPairs[index], [key]: value };
    setSelectPairs(newPairs);
  };

  const handleAddPair = () => {
    setSelectPairs([...selectPairs, { sportId: '', riskFactorId: '' }]);
  };

  const handleRemovePair = (index: number) => {
    // If there is only one pair left, clear the inputs instead of removing them
    if (selectPairs.length === 1) {
      setSelectPairs([{ sportId: '', riskFactorId: '' }]);
    } else {
      const newPairs = selectPairs.filter((_, i) => i !== index);
      setSelectPairs(newPairs);
    }
  };

  const handleSubmit = () => {
    const data = selectPairs.reduce<Record<string, string>>((acc, curr) => {
      if (curr.sportId) {
        acc[curr.sportId] = curr.riskFactorId;
      }

      return acc;
    }, {});

    updateData(
      id,
      {
        additionalData: data,
      },
      () => {
        invalidateData(['risk-management/punters']);
        handleClose();
      }
    );
  };

  const { data: sportsData } = useQuery([QUERY_KEYS.sports], {
    queryFn: (): Promise<PaginatedData<Sport>> =>
      getData('sports', {
        limit: 100,
        page: 1,
      }),
  });

  const { data: riskFactorData } = useQuery([QUERY_KEYS.riskFactors], {
    queryFn: (): Promise<PaginatedData<RiskFactor>> => getData('risk-management/risk-factors'),
  });

  return (
    <Stack spacing={2}>
      <Typography variant='h5'>{PRODUCT_TYPES_OPTIONS.find((item) => item.id === productValue)?.name}</Typography>
      <Box sx={styles.container}>
        {selectPairs.map((pair, index) => (
          <Box key={index} sx={styles.dropdownsWrapper}>
            <Select
              value={pair.sportId}
              onChange={(e) => handleValueChange(index, 'sportId', e.target.value)}
              MenuProps={{ sx: styles.menuProps }}
              fullWidth
            >
              {sportsData?.items.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <Select
              value={pair.riskFactorId}
              onChange={(e) => handleValueChange(index, 'riskFactorId', e.target.value)}
              MenuProps={{ sx: styles.menuProps }}
              fullWidth
            >
              {riskFactorData?.items.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <IconButton onClick={() => handleRemovePair(index)} color='error'>
              <HighlightOff />
            </IconButton>
          </Box>
        ))}
      </Box>
      <Stack direction='row' justifyContent='flex-end' spacing={2} mt={3}>
        <Button variant='contained' color='info' onClick={handleAddPair}>
          Add new limit
        </Button>
        <Button variant='outlined' onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='contained' type='submit' onClick={handleSubmit}>
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export default SportsLimitsTab;
