import { EditOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import type { GridCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { StyleObj } from '../../../@types';
import { CRMUserData, Customer } from '../../../@types/api';
import { QUERY_KEYS } from '../../../constants';
import { useCRMUser } from '../../../contexts/CRMUserContext';
import { useModal } from '../../../contexts/ModalContext';
import { defaultColumnsCustomers } from '../../../helpers/table';
import useMutateData from '../../../hooks/useMutateData';
import { usePagination } from '../../../hooks/usePagination';
import useSort from '../../../hooks/useSort';
import { createColumn } from '../../../utils';
import FormModal from '../../modals/FormModal';
import CRMDetailsDrawer from '../../organisms/crm/CRMDetailsDrawer';
import TableTemplate from '../../templates/TableTemplate';
import { CUSTOMER_TAB_CONFIG } from '../../../config/crm';
import WalletIcon from '../../icons/WalletIcon';
import { useNavigate } from 'react-router-dom';
import { dayjs } from '../../../config/dayjs';
import { createIsActiveColumn } from '../../../helpers/columns';
import Impersonate from '../../icons/Impersonate';
import { useState } from 'react';
import ConfirmationModal from '../../modals/ConfirmationModal';
import usePermissions from '../../../hooks/usePermissions';

const styles: StyleObj = {
  table: {
    '& .MuiDataGrid-row:hover': {
      cursor: 'pointer',
    },
  },
};

const CustomersPage = () => {
  const {
    data: customerData,
    updateQueryParams,
    isFetching,
    isLoading,
    changeQuery,
  } = usePagination<Customer>('customer/search', undefined, undefined, 'crm');
  const { updateData } = useMutateData('customer', [QUERY_KEYS.customers], 'crm');
  const { handleSort } = useSort(changeQuery, 'crm');
  const { openModal } = useModal();
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();
  const canImpersonatePlayer = hasPermission('impersonatePlayer');

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  const openEditDialog = (row: Customer) => {
    openModal(<FormModal form='customers' />, row);
  };

  const [currentUserId, setCurrentUserId] = useState<string>('');
  const { createData: impersonatePlayer } = useMutateData(
    `admin/realms/sportsbook-end-users/users/${currentUserId}/impersonation`,
    [QUERY_KEYS.customerImpersonate, currentUserId],
    'kc'
  );

  const handleImpersonate = (id: string) => {
    setCurrentUserId(id);
    impersonatePlayer({ userId: id }, undefined, undefined, () =>
      window.open(process.env.REACT_APP_SPORTSBOOK_CLIENT_URL, '_blank')
    );
  };

  const handleConfirmModal = (id: string) => {
    openModal(
      <ConfirmationModal
        title='Are you sure you want to Impersonate this player?'
        actionButtonLabel='Impersonate'
        handleConfirm={() => handleImpersonate(id)}
      />
    );
  };

  const columns: GridColDef[] = [
    createColumn('firstName', 'First Name', { alwaysVisible: true }),
    createColumn('lastName', 'Last Name', { alwaysVisible: true }),
    createColumn('username', 'Username'),
    createColumn('country', 'Country'),
    createColumn('phone', 'Phone Number'),
    createColumn('email', 'Email', { flex: 1.5 }),
    createColumn('createdAt', 'Date Created', {
      alwaysVisible: true,
      valueGetter: (params: GridValueGetterParams<Customer>) => dayjs(params.row.createdAt).format('DD.MM.YYYY'),
      flex: 0.75,
    }),
    createColumn('lastOnline', 'Last Online', {
      valueGetter: (params: GridValueGetterParams<Customer>) =>
        params.row.lastOnline && dayjs(params.row.lastOnline).fromNow(),
      flex: 0.75,
    }),
    createColumn('address', 'Address', {}),
    createColumn('zip', 'Zip Code', {
      flex: 0.75,
    }),
    createColumn('city', 'City', {}),
    createColumn('region', 'Region', {}),
    createColumn('language', 'Language', {}),
    createColumn('dateOfBirth', 'Date of Birth', {
      valueGetter: (params: GridValueGetterParams<Customer>) =>
        params.row.dateOfBirth && dayjs(params.row.dateOfBirth).format('DD.MM.YYYY'),
      flex: 0.75,
    }),
    createColumn('gender', 'Gender', {}),
    createColumn('parent', 'Parent', {
      valueGetter: (params: GridValueGetterParams<Customer>) => params.row.agent?.fullName,
    }),
    createColumn('createdBy', 'Created By'),
    createColumn('nationalId', 'National ID', {}),
    createColumn('currency', 'Currency', {}),
    createIsActiveColumn<Customer>({
      updateData,
    }),
    createColumn('edit', 'Actions', {
      sortable: false,
      disableExport: true,
      renderCell: (params: GridCellParams<Customer>) => (
        <>
          <IconButton onClick={() => navigate(`../players/${params.row.userId}/wallet`)} disabled={!params.row.userId}>
            <WalletIcon />
          </IconButton>
          <IconButton onClick={() => openEditDialog(params.row)} color='primary'>
            <EditOutlined />
          </IconButton>
          {canImpersonatePlayer && (
            <IconButton onClick={() => handleConfirmModal(params.row.userId)}>
              <Impersonate />
            </IconButton>
          )}
        </>
      ),
    }),
  ];

  const { setSelectedCRMUser } = useCRMUser();

  const handleCellClick = (params: GridCellParams<Customer>) => {
    if (params.field !== 'edit' && params.field !== 'isActive') {
      setSelectedCRMUser(params.row as CRMUserData);
    }
  };

  return (
    <>
      <TableTemplate
        rows={customerData?.items || []}
        columns={columns}
        loading={isFetching || isLoading}
        rowCount={customerData?.count ?? 0}
        createItemBtnLabel='Add new player'
        defaultVisibleColumns={defaultColumnsCustomers}
        handlePaginationModelChange={updateQueryParams}
        handleSearch={handleSearch}
        handleSort={handleSort}
        onCellClick={handleCellClick}
        formType='customers'
        sx={styles.table}
        changeQuery={changeQuery}
        showExport
      />
      <CRMDetailsDrawer config={CUSTOMER_TAB_CONFIG} />
    </>
  );
};

export default CustomersPage;
