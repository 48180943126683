import { Box, Divider, Menu } from '@mui/material';
import { StyleObj } from '../../@types';
import { PublishStatus } from '../../@types/api';
import {
  DELETE_OUTCOME_RESOLVE_STATUS_OPTION,
  TRANSFER_STATUS_OPTIONS,
  VOID_OUTCOME_RESOLVE_STATUS_OPTION,
} from '../../constants';
import StyledMenuItem from '../atoms/StyledMenuItem';
import BetStatusIcon from '../icons/BetStatusIcon';
import PublishStatusIcon from '../icons/PublishStatusIcon';
import ManageMenuSection from './ManageMenuSection';

export type HandleMenuItemClickOption = {
  id: number | string;
  name: string;
};

export type HandleMenuItemClickField = 'transferStatus' | 'void' | 'delete';

type ManageEventResultMenuProps = {
  contextMenu: { mouseX: number; mouseY: number } | null;
  handleClose: () => void;
  handleItemClick: (option: HandleMenuItemClickOption, field: HandleMenuItemClickField) => void;
};

const styles: StyleObj = {
  menu: {
    '& .MuiMenu-list': {
      pt: 0,
      pb: 0.75,
    },
  },
  menuContent: { minWidth: 156 },
  divider: {
    '&.MuiDivider-root': {
      m: 0,
    },
  },
};

const ManageEventResultMenu = ({ contextMenu, handleClose, handleItemClick }: ManageEventResultMenuProps) => {
  const handleVoidClick = () => {
    handleItemClick(VOID_OUTCOME_RESOLVE_STATUS_OPTION, 'void');
  };

  const handleDeleteClick = () => {
    handleItemClick(DELETE_OUTCOME_RESOLVE_STATUS_OPTION, 'delete');
  };
  return (
    <Menu
      open={!!contextMenu}
      onClose={handleClose}
      anchorReference='anchorPosition'
      anchorPosition={!!contextMenu ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      sx={styles.menu}
      slotProps={{
        root: {
          onContextMenu: (e) => {
            e.preventDefault();
            handleClose();
          },
        },
      }}
    >
      <Box sx={styles.menuContent}>
        <ManageMenuSection
          title='Change status'
          options={TRANSFER_STATUS_OPTIONS}
          handleItemClick={(option) => {
            handleItemClick(option, 'transferStatus');
          }}
          iconRenderer={(id) => <PublishStatusIcon status={id as PublishStatus} />}
        />
        <Divider sx={styles.divider} />
        <StyledMenuItem onClick={handleVoidClick}>
          <BetStatusIcon status='void' />
          Void
        </StyledMenuItem>
        <StyledMenuItem onClick={handleDeleteClick}>
          <BetStatusIcon status='deleted' />
          Delete Results
        </StyledMenuItem>
      </Box>
    </Menu>
  );
};
export default ManageEventResultMenu;
