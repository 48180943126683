import { DialogType } from '../../../@types';
import { BONUS_PRODUCT_TYPE_OPTIONS, BONUS_TIMEFRAME_OPTIONS } from '../../../constants';
import useFilterForm from '../../../hooks/useFilterForm';
import { AdditionalQueryParams } from '../../../hooks/usePagination';
import { BonusFilterFormData } from '../../../schema';
import FilterDialogLayout from '../../layouts/FilterDialogLayout';
import FormSelect from '../../molecules/FormSelect';
import ValidityPeriodSelect from '../../molecules/ValidityPeriodSelect';

const DEFAULT_FORM_DATA = {
  product: undefined,
  validityPeriod: undefined,
  schedule: undefined,
  fromTimestamp: undefined,
  toTimestamp: undefined,
};

type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const FilterBonuses = ({ closeModal, changeQuery }: Props) => {
  const { control, handleSubmit, handleResetFilter } = useFilterForm<BonusFilterFormData>({
    defaultData: DEFAULT_FORM_DATA,
    onSubmit: (data) => {
      const queryData = {
        ...data,
        fromTimestamp: data.fromTimestamp?.valueOf(),
        toTimestamp: data.toTimestamp?.valueOf(),
      };

      changeQuery(queryData);
      handleClose();
    },
  });

  const handleClose = () => {
    closeModal?.();
  };

  const handleReset = () => {
    handleResetFilter(DEFAULT_FORM_DATA, changeQuery);
    handleClose();
  };

  return (
    <FilterDialogLayout label='bonuses' onClose={handleClose} onSave={handleSubmit} onReset={handleReset}>
      <FormSelect control={control} name='product' label='Product' options={BONUS_PRODUCT_TYPE_OPTIONS} />
      <ValidityPeriodSelect control={control} />
      <FormSelect control={control} name='schedule' label='Timeframe' options={BONUS_TIMEFRAME_OPTIONS} />
    </FilterDialogLayout>
  );
};

export default FilterBonuses;
