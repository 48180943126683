import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { CRMUserData } from '../@types/api';

type CRMUserContextType = {
  selectedCRMUser: CRMUserData | null;
  setSelectedCRMUser: React.Dispatch<React.SetStateAction<CRMUserData | null>>;
};

const CRMUserContext = createContext<CRMUserContextType>({} as CRMUserContextType);

export const SelectedCRMUserProvider = ({ children }: PropsWithChildren) => {
  const [selectedCRMUser, setSelectedCRMUser] = useState<CRMUserData | null>(null);

  return <CRMUserContext.Provider value={{ selectedCRMUser, setSelectedCRMUser }}>{children}</CRMUserContext.Provider>;
};

export const useCRMUser = () => {
  const context = useContext(CRMUserContext);

  if (!context) {
    throw new Error('useCRMUser must be used within a CRMUserProvider');
  }

  return context;
};
