import { SvgIconComponent } from '@mui/icons-material';
import { ButtonProps, IconButton, Tooltip } from '@mui/material';
import StyledIcon from './StyledIcon';

type TableActionButtonProps = ButtonProps & {
  Icon: SvgIconComponent;
};
const TableActionButton = ({ Icon, children, ...buttonProps }: TableActionButtonProps) => {
  const { title, ...restProps } = buttonProps;

  return (
    <Tooltip placement='top' title={title}>
      <IconButton
        sx={{
          '&:hover': { backgroundColor: 'transparent' },
          ...restProps.sx,
        }}
        {...restProps}
      >
        <StyledIcon Icon={Icon} />
      </IconButton>
    </Tooltip>
  );
};

export default TableActionButton;
