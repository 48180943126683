import { Box, BoxProps } from '@mui/material';
import { StyleObj } from '../../@types';

const styles: StyleObj = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6px 8px',
  },
};

const TableIconContainer = ({ children, ...rest }: BoxProps) => {
  return (
    <Box sx={styles.container} {...rest}>
      {children}
    </Box>
  );
};

export default TableIconContainer;
