import { MenuItem } from '@mui/material';
import { GridExportMenuItemProps } from '@mui/x-data-grid';

const handlePrint = () => {
  const dataGridElement = document.querySelector('.MuiDataGrid-root');

  if (dataGridElement) {
    const printFrame = document.createElement('iframe');
    printFrame.style.position = 'absolute';
    printFrame.style.width = '0';
    printFrame.style.height = '0';
    printFrame.style.border = 'none';

    document.body.appendChild(printFrame);

    const printDocument = printFrame.contentDocument || printFrame.contentWindow?.document;

    if (printDocument) {
      const styleSheets = Array.from(document.styleSheets);
      const styles = styleSheets
        .map((sheet) => {
          try {
            const rules = Array.from(sheet.cssRules || []);
            return rules.map((rule) => rule.cssText).join('\n');
          } catch (e) {
            if (sheet.href) {
              return `@import url("${sheet.href}");`;
            }
            return '';
          }
        })
        .filter(Boolean)
        .join('\n');

      const computedStyles = window.getComputedStyle(dataGridElement);
      const inlineStyles = Array.from(computedStyles)
        .map((prop) => `${prop}: ${computedStyles.getPropertyValue(prop)};`)
        .join(' ');

      const clonedGrid = dataGridElement.cloneNode(true) as HTMLElement;
      clonedGrid.style.cssText = inlineStyles;

      // Include overflowed rows and hide pagination
      const additionalStyles = `
        @media print {
          .MuiDataGrid-root {
            height: auto !important;
            overflow: visible !important;
          }
          .MuiTablePagination-toolbar {
            display: none;
          }  
        }
      `;

      printDocument.write(`
        <!DOCTYPE html>
        <html>
          <head>
            <title>${document.title}</title>
            <style>
              ${styles}
              ${additionalStyles}
            </style>
          </head>
          <body>
            ${clonedGrid.outerHTML}
          </body>
        </html>
      `);
      printDocument.close();

      setTimeout(() => {
        printFrame.contentWindow?.focus();
        printFrame.contentWindow?.print();
        document.body.removeChild(printFrame);
      }, 500);
    }
  }
};

const PrintExportMenuOption = ({ hideMenu }: GridExportMenuItemProps<Record<string, never>>) => {
  return (
    <MenuItem
      onClick={() => {
        handlePrint();
        hideMenu?.();
      }}
    >
      Print
    </MenuItem>
  );
};

export default PrintExportMenuOption;
