import { useCallback } from 'react';

const usePersist = (session = true) => {
  const setPersistData = <T>(key: string, value: T) => {
    if (session) {
      sessionStorage.setItem(key, JSON.stringify(value));
      return;
    }

    localStorage.setItem(key, JSON.stringify(value));
  };

  const getPersistData = <T>(key: string): T | null => {
    const json = session ? sessionStorage.getItem(key) : localStorage.getItem(key);

    if (json) {
      return JSON.parse(json);
    }

    return null;
  };

  const deletePersistData = useCallback(
    (key: string) => {
      session ? sessionStorage.removeItem(key) : localStorage.removeItem(key);
    },
    [session]
  );

  return { setPersistData, getPersistData, deletePersistData };
};

export default usePersist;
