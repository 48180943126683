import { zodResolver } from '@hookform/resolvers/zod';
import { Close } from '@mui/icons-material';
import { Autocomplete, Chip, Stack, TextField } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import {
  ArrayFilter,
  DateRangeFilter,
  DayjsRangeFilter,
  DialogType,
  NumberRangeFilter,
  StyleObj,
} from '../../../@types';
import { LS_KEYS } from '../../../constants';
import { useModal } from '../../../contexts/ModalContext';
import useCRMUserType from '../../../hooks/useCRMUserType';
import useLocationData from '../../../hooks/useLocationData';
import { AdditionalQueryParams } from '../../../hooks/usePagination';
import usePersist from '../../../hooks/usePersist';
import { crmUserSchema } from '../../../schema';
import FormFieldStack from '../../atoms/FormFieldStack';
import { StyledSwitch } from '../../atoms/Switch';
import FilterDialogLayout from '../../layouts/FilterDialogLayout';
import DateTimeSelect from '../../molecules/DateTimeSelect';
import FormNumberInput from '../../molecules/FormNumberInput';
import { CUSTOMER_FILTER_OPTIONS, PARTNER_FILTER_OPTIONS, PARTNER_ROLE_TYPES } from '../../../config/crm';

const styles: StyleObj = {
  chip: {
    backgroundColor: 'primary.container',
    border: 0,
    borderRadius: 2,
    fontWeight: 600,
  },
};

const DEFAULT_FORM_VALUES: FilterCRMUserData = {
  firstName: [],
  lastName: [],
  fullName: [],
  isActive: false,
  country: [],
  createdAt: {
    from: null,
    to: null,
  },
  phone: [],
  email: [],
  zip: [],
  city: [],
  region: [],
  language: [],
  age: {
    from: null,
    to: null,
  },
  roleTypes: [],
};

export type FilterCRMUserData = {
  firstName: string[];
  lastName: string[];
  fullName: string[];
  isActive: boolean;
  country: string[];
  createdAt: {
    from: Dayjs | null;
    to: Dayjs | null;
  };
  phone: string[];
  email: string[];
  zip: string[];
  city: string[];
  region: string[];
  language: string[];
  age: {
    from: string | null;
    to: string | null;
  };
  roleTypes: string[];
};

export type FilterOption = {
  id: keyof FilterCRMUserData;
  label: string;
  type: FilterOptionInputType;
  freeSolo?: boolean;
};

type FilterOptionInputType = 'autocomplete' | 'number-range' | 'date-range' | 'switch';

type FilterOptionData = {
  [key in keyof FilterCRMUserData]: ArrayFilter | DateRangeFilter | NumberRangeFilter | DayjsRangeFilter | boolean;
};

type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const assignFilterValue = (value: FilterCRMUserData[keyof FilterCRMUserData]) => {
  if (Array.isArray(value)) {
    return value.length > 0 ? { shouldInclude: true, values: value } : undefined;
  } else {
    return value;
  }
};

const FilterCRMUsers = ({ changeQuery }: Props) => {
  const { setPersistData, getPersistData, deletePersistData } = usePersist(false);
  const { pathname } = useLocation();
  const formDataKey = `${LS_KEYS.appFormFilter}-${pathname}`;

  const savedFilterData = getPersistData<FilterCRMUserData>(formDataKey) || DEFAULT_FORM_VALUES;
  const savedSelectedFilters = getPersistData<FilterOption[]>(`${formDataKey}-'selectedFilters'`) || [];
  const [selectedFilters, setSelectedFilters] = useState<FilterOption[]>(savedSelectedFilters);

  // Only validates the fields that are currently selected
  const selectedFilterIds = selectedFilters.map((filter) => filter.id);
  const filterSchema = crmUserSchema.pick(Object.fromEntries(selectedFilterIds.map((id) => [id, true])));

  const { control, handleSubmit, reset, setValue, watch } = useForm<FilterCRMUserData>({
    defaultValues: savedFilterData,
    resolver: zodResolver(filterSchema),
  });

  const selectedCountries = watch('country');
  const { getLocationOptions } = useLocationData(selectedCountries);

  const { closeModal } = useModal();
  const userType = useCRMUserType();

  const filterOptions = userType === 'customer' ? CUSTOMER_FILTER_OPTIONS : PARTNER_FILTER_OPTIONS;

  const handleFilterOptionChange = (_event: React.SyntheticEvent, newFilter: FilterOption[]) => {
    setSelectedFilters(newFilter);
  };

  const handleClose = () => {
    closeModal();
  };

  const handleFilterReset = () => {
    deletePersistData(formDataKey);
    deletePersistData(`${formDataKey}-'selectedFilters'`);
    reset(DEFAULT_FORM_VALUES);
    changeQuery({ filters: { ...DEFAULT_FORM_VALUES, isActive: undefined }, roleTypes: [] });
    handleClose();
  };

  const prepareData = (data: FilterCRMUserData) => {
    const preparedFilterData = Object.entries(data).reduce((filteredData, [filterKey, filterValue]) => {
      const isFilterSelected = selectedFilterIds.includes(filterKey as keyof FilterCRMUserData);

      if (isFilterSelected) {
        if (filterKey === 'createdAt') {
          const { from, to } = filterValue as DateRangeFilter;
          filteredData[filterKey] = { from: dayjs(from).format('YYYY-MM-DD'), to: dayjs(to).format('YYYY-MM-DD') };
        } else {
          filteredData[filterKey as keyof FilterOptionData] = assignFilterValue(filterValue);
        }
      }

      return filteredData;
    }, {} as Partial<FilterOptionData>);

    return preparedFilterData;
  };

  const onFormSubmit = (data: FilterCRMUserData) => {
    setPersistData(`${formDataKey}-'selectedFilters'`, selectedFilters);
    setPersistData<FilterCRMUserData>(formDataKey, data);

    const filters = prepareData(data);
    // Role type filters are handled as a separate query param
    changeQuery({ filters: { ...filters, roleTypes: undefined }, roleTypes: data.roleTypes });
    handleClose();
  };

  const handleInputChange = (field: keyof FilterCRMUserData, value: string[]) => {
    setValue(field, value);
  };

  // TODO: Could be improved by defining a getter for each autocomplete filter option key
  const generateAutocompleteOptions = (filterOptionId: keyof FilterCRMUserData) => {
    if (filterOptionId === 'roleTypes') {
      return PARTNER_ROLE_TYPES;
    }

    return getLocationOptions(filterOptionId) || [];
  };

  const renderFilterInputFields = (
    filterOption: FilterOption,
    field: ControllerRenderProps<FilterCRMUserData, keyof FilterCRMUserData>
  ) => {
    switch (filterOption.type) {
      case 'autocomplete':
        return (
          <Autocomplete
            clearIcon={false}
            options={generateAutocompleteOptions(filterOption.id)}
            value={(field.value as string[]) || []}
            multiple
            freeSolo={filterOption.freeSolo}
            onChange={(_event: React.SyntheticEvent<Element, Event>, newValue: string[]) =>
              handleInputChange(filterOption.id, newValue)
            }
            renderTags={(value, getTagProps) =>
              value.map((option: string, index: number) => (
                // Key is already spread from getTagProps
                // eslint-disable-next-line react/jsx-key
                <Chip
                  variant='outlined'
                  label={option}
                  sx={styles.chip}
                  deleteIcon={<Close />}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                helperText={filterOption.freeSolo && 'Press enter to add value'}
                label={filterOption.label}
                {...params}
              />
            )}
          />
        );
      case 'number-range':
        return (
          <Stack direction='row' spacing={2}>
            <FormNumberInput
              control={control}
              name={`${filterOption.id}.from` as keyof FilterCRMUserData}
              label={`${filterOption.label} from`}
              fullWidth
              showHelperText
            />
            <FormNumberInput
              control={control}
              name={`${filterOption.id}.to` as keyof FilterCRMUserData}
              label={`${filterOption.label} to`}
              fullWidth
              showHelperText
            />
          </Stack>
        );
      case 'date-range':
        return (
          <Stack direction='row' spacing={2}>
            <DateTimeSelect
              name={`${filterOption.id}.from` as keyof FilterCRMUserData}
              control={control}
              label={`Date from`}
              disableTimePicker
              disableFuture
              fullWidth
            />
            <DateTimeSelect
              name={`${filterOption.id}.to` as keyof FilterCRMUserData}
              control={control}
              label={`Date to`}
              disableTimePicker
              fullWidth
            />
          </Stack>
        );
      case 'switch':
        return (
          <FormFieldStack label={filterOption.label}>
            <Controller
              name={filterOption.id}
              control={control}
              render={({ field }) => <StyledSwitch defaultChecked={savedFilterData.isActive} {...field} ref={null} />}
            />
          </FormFieldStack>
        );
    }
  };

  return (
    <FilterDialogLayout
      onSave={handleSubmit(onFormSubmit)}
      label='by'
      onClose={handleClose}
      onReset={handleFilterReset}
    >
      <Autocomplete
        options={Object.values(filterOptions)}
        onChange={handleFilterOptionChange}
        defaultValue={selectedFilters || []}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        renderInput={(params) => <TextField {...params} />}
        multiple
        ChipProps={{ sx: styles.chip, deleteIcon: <Close /> }}
      />
      {selectedFilters.map((filterOption) => (
        <Controller
          key={filterOption.id}
          name={filterOption.id}
          control={control}
          defaultValue={savedFilterData[filterOption.id]}
          render={({ field }) => renderFilterInputFields(filterOption, field)}
        />
      ))}
    </FilterDialogLayout>
  );
};

export default FilterCRMUsers;
