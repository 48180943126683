import { Box, Button } from '@mui/material';
import React from 'react';
import { useModal } from '../../contexts/ModalContext';
import CreateMessageModal from '../modals/CreateMessageModal';

type Props = {
  children: React.ReactNode;
};

const styles = {
  header: {
    backgroundColor: 'primary.main',
    padding: '16px 24px',
    marginTop: '10px',
    fontWeight: 700,
    color: '#fff',
    borderRadius: '8px 8px 0 0',
  },
  createBtn: {
    position: 'absolute',
    bottom: 30,
    right: 30,
  },
};

const MessagesLayout = ({ children }: Props) => {
  const { openModal, closeModal } = useModal();

  return (
    <Box>
      <Box sx={styles.header}>Messages</Box>
      {children}
      <Button
        sx={styles.createBtn}
        variant='outlined'
        onClick={() => openModal(<CreateMessageModal closeModal={() => closeModal()} />)}
      >
        + New Message
      </Button>
    </Box>
  );
};

export default MessagesLayout;
