import { styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export const StyledMessagesTable = styled(DataGrid)(({ theme }) => ({
  border: 'none',
  '--unstable_DataGrid-radius': '0',
  '& .MuiDataGrid-withBorderColor': {
    border: 'none',
  },
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  '& .MuiDataGrid-cell': {
    border: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-footerContainer': {
    justifyContent: 'flex-end',
  },
  '& .MuiCheckbox-root': {
    color: 'grey.500',
  },
  '& .Mui-checked': {
    color: theme.palette.primary.main,
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-iconButtonContainer .MuiButtonBase-root': {
    color: '#BCBCBC',
  },
  '& .MuiDataGrid-columnHeader:last-child': {
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      justifyContent: 'right',
    },
  },
  '& .MuiTablePagination-selectIcon': {
    color: '#BCBCBC',
  },
  '& .MuiTablePagination-actions': {
    '& .MuiIconButton-root': {
      color: '#BCBCBC',
    },
  },
}));
