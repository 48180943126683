import React from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { DialogType } from '../../@types';
import { AdditionalQueryParams } from '../../hooks/usePagination';
import { LS_KEYS, SPORTSBOOK_FROM_OPTIONS, SPORTSBOOK_WALLET_TYPE } from '../../constants';
import usePersist from '../../hooks/usePersist';
import FormSelect from '../molecules/FormSelect';
import FilterDialogLayout from '../layouts/FilterDialogLayout';

type FilterManagersReportData = {
  from?: string;
  walletType?: string;
};
const defaultValues = {
  partnerIds: undefined,
  from: undefined,
  walletType: undefined,
};
type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const FilterManagersReport = ({ changeQuery, closeModal }: Props) => {
  const { pathname } = useLocation();
  const page = pathname.split('/').pop();
  const formDataKey = `${LS_KEYS.appFormFilter}-${pathname}`;
  const { setPersistData, getPersistData, deletePersistData } = usePersist(false);
  const getSavedData = () => getPersistData<FilterManagersReportData>(formDataKey) || defaultValues;

  const { control, handleSubmit, reset } = useForm<FilterManagersReportData>({ defaultValues: getSavedData() });

  const handleFilterReset = () => {
    deletePersistData(formDataKey);
    reset(defaultValues);
    changeQuery(defaultValues);

    closeModal?.();
  };

  const handleClose = () => {
    closeModal?.();
  };

  const onFormSubmit = (data: FilterManagersReportData) => {
    setPersistData<FilterManagersReportData>(formDataKey, data);
    changeQuery(data);
    closeModal?.();
  };

  return (
    <FilterDialogLayout
      label={page || ''}
      onSave={handleSubmit(onFormSubmit)}
      onClose={handleClose}
      onReset={handleFilterReset}
    >
      <FormSelect name='from' control={control} label='From' options={SPORTSBOOK_FROM_OPTIONS} />
      <FormSelect name='walletType' control={control} label='Bets' options={SPORTSBOOK_WALLET_TYPE} />
    </FilterDialogLayout>
  );
};

export default FilterManagersReport;
