import { GridColDef } from '@mui/x-data-grid';
import IconRenderer from '../components/atoms/IconRenderer';
import TableIconContainer from '../components/atoms/TableIconContainer';
import { createNumberColumn, createPercentageColumn } from '../helpers/columns';
import { createColumn } from '../utils';

const createCommonColumns = () => {
  return {
    icon: createColumn('icon', 'Icon', {
      sortable: false,
      flex: 0.75,
      renderCell: (params) =>
        params.row.icon === 'Totals' ? (
          <>Totals</>
        ) : (
          <TableIconContainer>
            <IconRenderer name={params.value} type='sport' />
          </TableIconContainer>
        ),
    }),
    sportName: createColumn('sportName', 'Sport'),
    competitionName: createColumn('competitionName', 'Competition', {
      flex: 2,
    }),
    tournamentName: createColumn('tournamentName', 'Tournament', {
      flex: 2,
    }),
    ticketCount: createColumn('ticketCount', 'Tickets'),
    stakeAmount: createNumberColumn('stakeAmount', 'Stakes'),
    winningAmount: createNumberColumn('winningAmount', 'Winnings'),
    profitLossAmount: createNumberColumn('profitLossAmount', 'Profit'),
    profitMarginPercentage: createPercentageColumn('profitMarginPercentage', 'Profit Margin %'),
    marketTypeName: createColumn('marketTypeName', 'Market', {
      flex: 2,
    }),
  };
};

export const getSportsbookColumns = (type: string) => {
  const commonColumns = createCommonColumns();
  if (type === 'tournament') {
    const tournamentsColumns: GridColDef[] = [
      commonColumns.icon,
      commonColumns.sportName,
      commonColumns.competitionName,
      commonColumns.tournamentName,
      commonColumns.ticketCount,
      commonColumns.stakeAmount,
      commonColumns.winningAmount,
      commonColumns.profitLossAmount,
      commonColumns.profitMarginPercentage,
    ];
    return { columns: tournamentsColumns };
  } else if (type === 'market-type') {
    const marketsColumns: GridColDef[] = [
      commonColumns.icon,
      commonColumns.sportName,
      commonColumns.marketTypeName,
      commonColumns.ticketCount,
      commonColumns.stakeAmount,
      commonColumns.winningAmount,
      commonColumns.profitLossAmount,
      commonColumns.profitMarginPercentage,
    ];
    return { columns: marketsColumns };
  } else {
    const sportColumns: GridColDef[] = [
      commonColumns.icon,
      commonColumns.sportName,
      commonColumns.ticketCount,
      commonColumns.stakeAmount,
      commonColumns.winningAmount,
      commonColumns.profitLossAmount,
      commonColumns.profitMarginPercentage,
    ];
    return { columns: sportColumns };
  }
};
