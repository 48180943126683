import type { GridPaginationModel } from '@mui/x-data-grid';
import { UseQueryOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';

import { AxiosHeaders, RawAxiosRequestHeaders } from 'axios';
import type { PaginatedData } from '../@types/api';
import { ApiService, MethodsHeaders, getData, hasApiMorePages } from '../utils/api';

type Pagination = {
  page: number;
  limit: number;
};

export type AdditionalQueryParams = {
  [key: string]: unknown;
};

type QueryParams = Pagination & AdditionalQueryParams;

export const usePagination = <T>(
  endPoint: string,
  initialQueryParams?: QueryParams,
  options?: UseQueryOptions<PaginatedData<T>, unknown, PaginatedData<T>, [string, QueryParams]>,
  apiService?: ApiService,
  headers?: (RawAxiosRequestHeaders & MethodsHeaders) | AxiosHeaders
) => {
  const [queryParams, setQueryParams] = useState<QueryParams>({
    page: 1,
    limit: 25,
    ...initialQueryParams,
  });

  const queryClient = useQueryClient();

  const updateQueryParams = (params: GridPaginationModel) => {
    return setQueryParams((prevValue) => {
      return {
        ...prevValue,
        limit: params.pageSize,
        // mui pagination starts from 0 and api starts from 1 so we need to add 1
        page: params.page + 1,
      };
    });
  };

  const changeQuery = useCallback(
    (query: AdditionalQueryParams) => {
      return setQueryParams((prevValue) => {
        const queryData: QueryParams = { ...prevValue, ...query, page: 1 };
        Object.keys(queryData).forEach((key) => {
          if (queryData[key] === null) {
            delete queryData[key];
          }
        });
        return queryData;
      });
    },
    [setQueryParams]
  );

  const { data, isFetching, isLoading, refetch, status } = useQuery([endPoint, queryParams], {
    queryFn: (): Promise<PaginatedData<T>> => getData(endPoint, { ...queryParams }, apiService, headers),
    keepPreviousData: true,
    ...options,
  });

  const prefetchData = useCallback(
    (prefetchPage: number) => {
      queryClient.prefetchQuery(
        [
          endPoint,
          {
            ...queryParams,
            page: prefetchPage,
          },
        ],
        () => getData(endPoint, { ...queryParams, page: prefetchPage }, apiService)
      );
    },
    [queryClient, endPoint, queryParams, apiService]
  );

  useEffect(() => {
    if (data && hasApiMorePages(data.count, queryParams.page, data.limit)) {
      prefetchData(queryParams.page + 1);
    }

    if ((queryParams?.page as number) > 1) {
      prefetchData(queryParams.page - 1);
    }
  }, [data, prefetchData, queryParams?.page]);

  return {
    data,
    activePage: queryParams.page,
    isFetching,
    isLoading,
    changeQuery,
    limit: queryParams.limit,
    updateQueryParams,
    refetch,
    status,
  };
};
