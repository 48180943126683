import { zodResolver } from '@hookform/resolvers/zod';
import { TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { DialogType, StyleObj } from '../../../@types';
import { Competition, PaginatedData, Sport, Tournament } from '../../../@types/api';
import { FEATURED_WARNING_MESSAGE, QUERY_KEYS } from '../../../constants';
import { useModal } from '../../../contexts/ModalContext';
import useMutateData from '../../../hooks/useMutateData';
import { TournamentFormData, tournamentFormSchema } from '../../../schema';
import { getData } from '../../../utils/api';
import FormFieldStack from '../../atoms/FormFieldStack';
import Switch from '../../atoms/Switch';
import FormModalLayout from '../../layouts/FormModalLayout';
import FormNumberInput from '../../molecules/FormNumberInput';
import FormSelect from '../../molecules/FormSelect';
import FormAutocomplete from '../FormAutocomplete';

const DEFAULT_FORM_DATA = {
  sportId: '',
  competitionId: '',
  parentId: null,
  name: '',
  shortName: '',
  position: null,
  isActive: false,
  topLeague: false,
  topOffer: false,
  topOfferPosition: null,
  featured: false,
  information: null,
};

const styles: StyleObj = {
  position: {
    width: 80,
  },
  featuredWarning: {
    color: 'error.main',
    width: '305px',
  },
};

type Props = DialogType;
const TournamentForm = ({ closeModal }: Props) => {
  const [showFeaturedWarning, setShowFeaturedWarning] = useState(false);
  const featuredWarningRef = useRef<HTMLDivElement>(null);
  const { createData, updateData } = useMutateData('tournaments', [QUERY_KEYS.tournaments]);

  const { item } = useModal<Tournament>();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm<TournamentFormData>({
    defaultValues: DEFAULT_FORM_DATA,
    resolver: zodResolver(tournamentFormSchema),
  });

  const sportId = watch('sportId');
  const competitionId = watch('competitionId');

  const { data: sportsData } = useQuery([QUERY_KEYS.sports], {
    queryFn: (): Promise<PaginatedData<Sport>> =>
      getData('sports', {
        limit: 100,
        page: 1,
      }),
  });

  useEffect(() => {
    if (showFeaturedWarning && featuredWarningRef.current) {
      featuredWarningRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showFeaturedWarning]);

  useEffect(() => {
    if (item) {
      reset({
        ...item,
        sportId: item.sport.id,
      });
    }
  }, [item, reset]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type !== 'change') return;

      if (name === 'sportId') {
        setValue('competitionId', '');
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const handleClose = () => {
    reset(DEFAULT_FORM_DATA);
    closeModal?.();
  };

  const onFormSubmit = (data: TournamentFormData) => {
    if (item) {
      updateData(item.id, data, () => {
        handleClose();
      });
    } else {
      createData(data, () => {
        handleClose();
      });
    }
  };

  const handleOnChangeFeatured = (field: ControllerRenderProps<TournamentFormData, 'featured'>) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      field.onChange(e);
      if (e.target.checked) {
        setShowFeaturedWarning(true);
      } else {
        setShowFeaturedWarning(false);
      }
    };
  };

  return (
    <FormModalLayout
      onSave={handleSubmit(onFormSubmit)}
      label={item ? 'Edit tournament' : 'Add new tournament'}
      onClose={handleClose}
      isEdit={!!item}
    >
      <FormSelect
        label='Sport'
        name='sportId'
        required
        control={control}
        error={errors.sportId}
        options={sportsData?.items || []}
      />
      <FormAutocomplete<Competition, TournamentFormData>
        name='competitionId'
        control={control}
        label='Competition'
        required
        url='competitions'
        queryKey={[QUERY_KEYS.competitions, sportId]}
        queryParams={{
          sportId: sportId,
          isActive: true,
        }}
        disabled={!sportId}
        error={errors.competitionId}
        hookEnabled={!!sportId}
        getOptionLabel={(options, value) => {
          const option = options.find((option) => option?.id === value);
          return option?.name || '';
        }}
      />
      <FormAutocomplete<Tournament, TournamentFormData>
        name='parentId'
        control={control}
        label='Parent tournament'
        url='tournaments'
        queryKey={[QUERY_KEYS.tournaments, competitionId]}
        queryParams={{
          competitionId: competitionId,
          isActive: true,
          tournamentIds: [item?.parentId],
        }}
        disabled={!competitionId}
        hookEnabled={!!competitionId}
        error={errors.competitionId}
        getOptionLabel={(options, value) => {
          const option = options.find((option) => option?.id === value);
          return option?.name || '';
        }}
      />
      <TextField error={!!errors.name} helperText={errors.name?.message} label='Name' {...register('name')} required />
      <TextField
        error={!!errors.shortName}
        helperText={errors.shortName?.message}
        label='Short name'
        {...register('shortName')}
      />
      <FormFieldStack label='Position'>
        <FormNumberInput name='position' control={control} error={errors.position} sx={styles.position} />
      </FormFieldStack>
      <FormFieldStack label='Top league'>
        <Controller name='topLeague' control={control} render={({ field }) => <Switch {...field} ref={null} />} />
      </FormFieldStack>
      <FormFieldStack label='Top offer'>
        <Controller name='topOffer' control={control} render={({ field }) => <Switch {...field} ref={null} />} />
      </FormFieldStack>
      <FormFieldStack label='Top offer position'>
        <FormNumberInput
          name='topOfferPosition'
          control={control}
          error={errors.topOfferPosition}
          sx={styles.position}
        />
      </FormFieldStack>
      <FormFieldStack label='Status'>
        <Controller name='isActive' control={control} render={({ field }) => <Switch {...field} ref={null} />} />
      </FormFieldStack>
      <TextField
        error={!!errors.information}
        helperText={errors.information?.message}
        label='Information'
        multiline
        {...register('information')}
      />
      <FormFieldStack label='Featured'>
        <Controller
          name='featured'
          control={control}
          render={({ field }) => <Switch {...field} ref={null} onChange={handleOnChangeFeatured(field)} />}
        />
      </FormFieldStack>
      {showFeaturedWarning && (
        <Typography sx={styles.featuredWarning} ref={featuredWarningRef}>
          {FEATURED_WARNING_MESSAGE}
        </Typography>
      )}
    </FormModalLayout>
  );
};

export default TournamentForm;
