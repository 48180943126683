import { useLocation } from 'react-router-dom';
import { CRMApiEntityType } from '../@types/api';
import { PATH_TO_USER_TYPE_MAP } from '../config/crm';

const useCRMUserType = () => {
  const { pathname } = useLocation();
  const pathSegment = pathname.split('/').pop();
  const userType = pathSegment && PATH_TO_USER_TYPE_MAP[pathSegment as keyof typeof PATH_TO_USER_TYPE_MAP];

  return userType as CRMApiEntityType;
};

export default useCRMUserType;
