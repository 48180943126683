import { Replay } from '@mui/icons-material';
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { DialogType } from '../../../@types';
import { PublishModelType } from '../../../@types/api';
import { LS_KEYS, PUBLISH_MODEL_OPTIONS, QUERY_KEYS } from '../../../constants';
import { AdditionalQueryParams } from '../../../hooks/usePagination';
import usePersist from '../../../hooks/usePersist';
import FormSelect from '../../molecules/FormSelect';
import SportSelect from '../../molecules/SportSelect';
import FormAutocomplete from '../FormAutocomplete';

const defaultValues = {
  sportId: undefined,
  competitionIds: [],
  publishModel: undefined,
};

export type FilterPublishEventsData = {
  sportId?: string;
  competitionIds: string[];
  publishModel?: PublishModelType;
};

type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const FilterPublishEvents = ({ changeQuery, closeModal }: Props) => {
  const { setPersistData, getPersistData, deletePersistData } = usePersist(false);
  const { pathname } = useLocation();
  const formDataKey = `${LS_KEYS.appFormFilter}-${pathname}`;

  const getSavedData = () => getPersistData<FilterPublishEventsData>(formDataKey) || defaultValues;
  const { control, handleSubmit, watch, reset, setValue } = useForm<FilterPublishEventsData>({
    defaultValues: getSavedData(),
  });

  const sportId = watch('sportId');
  const competitionIds = watch('competitionIds');

  useEffect(() => {
    const subscription = watch((_value, { name, type }) => {
      if (type !== 'change') return;

      if (name === 'sportId') {
        if (!isEmpty(competitionIds)) {
          setValue('competitionIds', []);
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, competitionIds]);

  const handleFilterReset = () => {
    deletePersistData(formDataKey);
    reset(defaultValues);
    changeQuery(defaultValues);

    closeModal?.();
  };

  const onFormSubmit = (data: FilterPublishEventsData) => {
    setPersistData<FilterPublishEventsData>(formDataKey, data);
    changeQuery(data);
    closeModal?.();
  };

  return (
    <>
      <DialogTitle variant='h4' gutterBottom>
        Filter publish events
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <SportSelect label='Sport' control={control} name='sportId' closeMenuOnSelect />
          <FormAutocomplete
            name='competitionIds'
            control={control}
            label='Competitions'
            url='competitions'
            queryKey={[QUERY_KEYS.competitions, sportId, competitionIds]}
            queryParams={{
              sportId,
              isActive: true,
              competitionIds,
            }}
            disabled={isEmpty([sportId])}
            hookEnabled={!!sportId}
            getOptionLabel={(options, value) => {
              const option = options.find((option) => option?.id === value);
              return option?.name || '';
            }}
            multiple
          />
          <FormSelect control={control} name='publishModel' label='Publish model' options={PUBLISH_MODEL_OPTIONS} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='text' onClick={handleFilterReset} startIcon={<Replay />} size='small'>
          Reset filters
        </Button>
        <Button variant='outlined' onClick={closeModal}>
          Cancel
        </Button>
        <Button variant='contained' onClick={handleSubmit(onFormSubmit)}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default FilterPublishEvents;
