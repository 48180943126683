import { Box } from '@mui/material';
import LiabilityEventDetailsRow from '../atoms/LiabilityEventDetailsRow';
import { LS_KEYS, QUERY_KEYS } from '../../constants';
import { useQuery } from '@tanstack/react-query';
import { getData } from '../../utils/api';
import { PaginatedData } from '../../@types/api';
import { useLocation } from 'react-router-dom';
import usePersist from '../../hooks/usePersist';
import { FilterLiabilityMonitoringData } from '../organisms/FilterLiabilityMonitoring';

const styles = {
  container: {
    width: '100%',
  },
  header: {
    display: 'flex',
    backgroundColor: 'background.lightGreen',
    borderTop: '1px solid rgba(0, 83, 55, 0.20)',
    borderBottom: '1px solid rgba(0, 83, 55, 0.20)',
    '& > :first-of-type': {
      borderLeft: '1px solid rgba(0, 83, 55, 0.20)',
    },
  },
  headerItem: {
    py: 2,
    width: '100%',
    borderRight: '1px solid rgba(0, 83, 55, 0.20)',
    fontSize: 14,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  percentageHeaderItem: {
    minWidth: 60,
    py: 2,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid rgba(0, 83, 55, 0.20)',
  },
  valuesParent: {
    '& > :first-of-type': {
      backgroundColor: 'background.lighter',
    },
  },
};

type Props = {
  eventId: string;
};

type MonitoringEventData = {
  marketName: string;
  maxLiability: string;
  currentLiability: string;
  currentShare: string;
  outcomes: { name: string; value: string }[];
};

const LiabilityEventDataDetails = ({ eventId }: Props) => {
  const { pathname } = useLocation();
  const { getPersistData } = usePersist(false);

  const savedData = getPersistData<FilterLiabilityMonitoringData>(`${LS_KEYS.appFormFilter}-${pathname}`);

  const product = savedData?.product ?? 'pre-match';

  const { data: event } = useQuery<PaginatedData<MonitoringEventData>>([QUERY_KEYS.event, eventId, product], {
    queryFn: () =>
      getData(`risk-management/monitoring/events/${eventId}`, {
        product,
      }),
    enabled: !!eventId && !!product,
  });

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Box sx={styles.headerItem}>Market Name</Box>
        <Box sx={styles.headerItem}>Max. Liability</Box>
        <Box sx={styles.headerItem}>Current Liability</Box>
        <Box sx={styles.percentageHeaderItem}>%</Box>
      </Box>
      <Box sx={styles.valuesParent}>
        {event?.items.map((event) => (
          <LiabilityEventDetailsRow
            key={event.marketName}
            currentLiability={event.currentLiability}
            marketName={event.marketName}
            maxLiability={event.maxLiability}
            outcomes={event.outcomes}
            percentageValue={event.currentShare}
          />
        ))}
      </Box>
    </Box>
  );
};

export default LiabilityEventDataDetails;
