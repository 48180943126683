import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';

export const COMMON_COLUMN_PROPS: Omit<GridColDef, 'field' | 'headerName'> = {
  headerAlign: 'center',
  align: 'center',
  flex: 1,
  sortable: true,
};

export const COLUMN_FIELDS = {
  ID: 'id',
  NAME: 'name',
  SHORT_NAME: 'shortName',
  POSITION: 'position',
  IS_ACTIVE: 'isActive',
  ICON: 'icon',
  EDIT: 'edit',
  SPORT_ID: 'sportId',
  SPORT_NAME: 'sportName',
  SPORT_ICON: 'sportIcon',
  TOP_OFFER: 'topOffer',
  TOP_OFFER_POSITION: 'topOfferPosition',
  COMPETITION_ID: 'competitionId',
  COMPETITION_NAME: 'competitionName',
  FEATURED: 'featured',
  INFORMATION: 'information',
  TOURNAMENT_ID: 'tournamentId',
  TOURNAMENT_NAME: 'tournamentName',
  PARTICIPANT_ID: 'participantId',
  PARTICIPANT_NAME: 'participantName',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  DISPLAY_NAME: 'displayName',
  MARKET_NAME: 'marketTypeName',
  DESCRIPTION: 'description',
  COUNTRY: 'country',
  PHONE_NUMBER: 'phone',
  CASHOUT: 'eligibleForCashout',
  EMAIL: 'email',
  DATE_CREATED: 'dateCreated',
  LAST_ONLINE: 'lastOnline',
  ADDRESS: 'address',
  ZIP_CODE: 'zip',
  CITY: 'city',
  REGION: 'region',
  LANGUAGE: 'language',
  DATE_OF_BIRTH: 'dateOfBirth',
  GENDER: 'gender',
  PARENT: 'parent',
  CREATED_BY: 'createdBy',
  NATIONAL_ID: 'nationalId',
  CURRENCY: 'currency',
  START_DATE: 'startDate',
  START_TIME: 'startTime',
  RESULT: 'result',
  MODEL_OUTRIGHTS: 'publishTimeOutrightModel',
  PUBLISH_TIME_OUTRIGHTS: 'publishTimeInDaysOutrightValue',
  MODEL_MATCHES: 'publishTimeMatchModel',
  PUBLISH_TIME_MATCHES: 'publishTimeInDaysMatchValue',
  ENTITY: 'modelType',
  VERIFICATION_MESSAGE_ADMIN: 'verificationMsgAdmin',
  VERIFICATION_MESSAGE_CLIENT: 'verificationMsgClient',
  RESTRICTION_MESSAGE_ADMIN: 'restrictionMsgAdmin',
  RESTRICTION_MESSAGE_CLIENT: 'restrictionMsgClient',
  CONDITION_GROUP_NAME: 'conditionGroupName',
  USER: 'user',
  USERNAME: 'username',
  RISK_FACTOR: 'riskFactor',
  RISK_FACTOR_VALUE: 'riskFactorValue',
  PRE_MATCH_RISK_FACTOR: 'preMatchRiskFactor',
  IN_PLAY_RISK_FACTOR: 'inPlayRiskFactor',
  TYPE: 'type',
  DEVICE: 'device',
  PAID_OUT_AT: 'resolvedAt',
  TOTAL_STAKE_AMOUNT: 'totalStakeAmount',
  TOTAL_ODDS: 'totalOdds',
  POSSIBLE_WINNINGS: 'possibleWinnings',
  WINNINGS: 'winnings',
  COMBINATIONS_COUNT: 'combinationsCount',
  REOFFERED: 'reoffered',
  CANCELED: 'canceled',
  RESOLUTION_STATUS: 'status',
  IP_ADDRESS: 'ipAddress',
  ACCEPT_STATUS: 'acceptStatus',
  CREATED_AT: 'createdAt',
  TICKET_ID: 'ticketId',
  BETS_COUNT: 'betsCount',
  FROM: 'from',
  SOURCE_TYPE: 'sourceType',
  EVENT: 'event',
  BANKER: 'banker',
  IS_WAYS: 'isWays',
  MARKET: 'market',
  SPECIAL_VALUE: 'specialValue',
  OUTCOME: 'outcome',
  ODDS: 'odds',
  USED_FOR: 'usedFor',
  DISPLAY_TYPE: 'displayType',
  FULL_NAME: 'fullName',
  RISK_FACTOR_ID: 'riskFactorID',
  RISK_FACTOR_NAME: 'riskFactorName',
  MIN_VERIFICATION_TRIGGER: 'minVerificationTrigger',
  MAX_VERIFICATION_TRIGGER: 'maxVerificationTrigger',
  LIABILITY_LIMIT_ID: 'liabilityLimitID',
  LIABILITY_LIMIT_NAME: 'liabilityName',
  LIABILITY_LIMIT_VALUE: 'liabilityValue',
  LIABILITY_CALCULATION: 'liabilityCalculation',
  DEFAULT_RISK_FACTOR: 'defaultRiskFactor',
  DEFAULT_MIN_TRIGGER: 'defaultMinTrigger',
  DEFAULT_MAX_TRIGGER: 'defaultMaxTrigger',
  SETTINGS: 'settings',
  SOLUTION: 'solution',
  PAYMENT_METHOD: 'paymentMethod',
  AMOUNT: 'amount',
  STATUS: 'status',
  EXECUTION_TIME: 'executionTime',
  TOP_LIABILITY: 'topLiability',
  LIABILITY_PERCENTAGE: 'liabilityPercentage',
  EVENT_ID: 'eventId',
  UNIQUE_ID: 'uniqueId',
  LIMIT_ID: 'limitId',
  LIMIT_VALUE: 'limitValue',
  NUMBER_TICKET_BETS: 'numberTicketBets',
  TOP_LEAGUE: 'topLeague',
  BETTING_OPERATOR: 'operator',
  ACTIONS: 'actions',
  SOURCE_IP: 'ipAddress',
  WINNING_TICKETS: 'winningTickets',
  PRODUCT: 'product',
  STAKE_RANGES: 'stakeRanges',
  WINNING_AMOUNT: 'winningAmount',
} as const;
