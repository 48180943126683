import { useQuery } from '@tanstack/react-query';
import { Control, FieldValues, Path } from 'react-hook-form';
import { StyleObj } from '../../@types';
import { Bet, PaginatedData, SpecialValueWithOutcomeInfo } from '../../@types/api';
import { QUERY_KEYS } from '../../constants';
import { getData } from '../../utils/api';
import FormSelect from './FormSelect';
import { SelectChangeEvent } from '@mui/material';
import {
  extractSpecialValuesFromOutcomeName,
  getSpecialValuesDisplayValue,
  isHandicapMarket,
} from '../../helpers/specialValues';
import { useMemo } from 'react';

const styles: StyleObj = {
  input: {
    '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
    '& .MuiSelect-select': {
      fontSize: '0.875rem',
      fontWeight: 'bold',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
  },
  menuItem: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
  },
};

type Props<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T>;
  bet: Bet;
  onSpecialValueChange: (
    selectedSpecialValueId: string,
    selectedSpecialValueMarketId: string,
    outcomeId: string,
    selectedOdds: string,
    name: string
  ) => void;
};

const TicketAuthSpecialValueSelect = <T extends FieldValues>({
  name,
  control,
  bet,
  onSpecialValueChange,
}: Props<T>) => {
  const { data: initialSpecialValues } = useQuery([QUERY_KEYS.specialValues, bet.id], {
    queryFn: (): Promise<PaginatedData<SpecialValueWithOutcomeInfo>> =>
      getData('/special-values', {
        eventId: bet.event.id,
        marketTypeId: bet.market.marketTypeId,
        outcomeTypeId: bet.outcome.outcomeTypeId,
        modelType: bet.specialValues?.[0].modelType,
        modelId: bet.specialValues?.[0].modelId,
      }),
    select: (data) => data.items,
  });

  const marketTypeName = bet.market.marketType.name;
  const specialValues = useMemo(() => {
    return initialSpecialValues
      ?.map((specialValue) => {
        const isHandicap = isHandicapMarket(marketTypeName);

        if (!isHandicap) return specialValue;

        return {
          ...specialValue,
          value: getSpecialValuesDisplayValue(
            extractSpecialValuesFromOutcomeName(
              specialValue.outcome.name,
              initialSpecialValues,
              isHandicap,
              marketTypeName
            )
          ),
        };
      })
      .sort((a, b) => Number(b.value) - Number(a.value));
  }, [initialSpecialValues, marketTypeName]);

  const options =
    specialValues?.map((specialValue) => ({
      id: specialValue.outcome.id,
      name: specialValue.value?.toString() || '',
    })) || [];

  const getOutcomeById = (specialValueId: string) => {
    const specialValue = specialValues?.find((specialValue) => specialValue.outcome.id === specialValueId);

    if (!specialValue) {
      return {
        odds: '',
        name: '',
        marketId: '',
      };
    }

    const odds = specialValue.outcome.odds.toString();
    const name = specialValue.value?.toString() || '';
    const marketId = specialValue.marketId;

    return {
      odds,
      name,
      marketId,
    };
  };

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    const selectedOutcomeId = e.target.value as string;
    const { odds, name, marketId } = getOutcomeById(selectedOutcomeId);
    onSpecialValueChange(selectedOutcomeId, marketId, bet.id, odds, name);
  };

  return (
    <FormSelect
      control={control}
      name={name}
      size='small'
      options={options}
      sx={styles.input}
      onChange={handleChange}
      MenuProps={{
        PaperProps: {
          sx: {
            '& .MuiMenuItem-root': {
              fontSize: '0.875rem',
              fontWeight: 'bold',
            },
          },
        },
      }}
    />
  );
};

export default TicketAuthSpecialValueSelect;
