import { ReactNode, createContext, useCallback, useContext, useEffect, useRef } from 'react';

interface AudioContextType {
  audio: HTMLAudioElement;
  loopAudio: (duration?: number) => void;
  stopAudio: () => void;
}

const AudioContext = createContext<AudioContextType | undefined>(undefined);

type AudioProviderProps = {
  children: ReactNode;
  src: string;
};

export const AudioProvider = ({ children, src }: AudioProviderProps) => {
  const userInteracted = useRef(false);
  const audio = useRef(new Audio(src));
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  const loopAudio = useCallback((duration?: number) => {
    if (userInteracted.current) {
      audio.current.loop = true;
      audio.current.play();

      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      if (duration) {
        timeoutId.current = setTimeout(() => {
          if (!audio.current.paused) {
            audio.current.pause();
            audio.current.currentTime = 0;
          }
        }, duration * 1000);
      }
    }
  }, []);

  const stopAudio = useCallback(() => {
    if (!audio.current.paused) {
      audio.current.pause();
      audio.current.currentTime = 0;
    }
  }, []);

  useEffect(() => {
    const handleInteraction = () => {
      userInteracted.current = true;
    };

    document.addEventListener('click', handleInteraction);
    document.addEventListener('keydown', handleInteraction);

    return () => {
      document.removeEventListener('click', handleInteraction);
      document.removeEventListener('keydown', handleInteraction);

      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  const value = {
    audio: audio.current,
    loopAudio,
    stopAudio,
  };

  return <AudioContext.Provider value={value}>{children}</AudioContext.Provider>;
};

export const useAudio = () => {
  const context = useContext(AudioContext);
  if (!context) {
    throw new Error('useAudio must be used within an AudioProvider');
  }
  return context;
};
