import { Outlet, useNavigate } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import { ModalProvider } from '../contexts/ModalContext';
import { useEffect } from 'react';
import usePermissions from '../hooks/usePermissions';
import { ROLE_TO_ROUTE_MAP } from '../constants/keycloak';

const Root = () => {
  const navigate = useNavigate();
  const { role } = usePermissions();

  useEffect(() => {
    const route = ROLE_TO_ROUTE_MAP[role];
    navigate(route);
  }, [role, navigate]);

  return (
    <MainLayout>
      <ModalProvider>
        <Outlet />
      </ModalProvider>
    </MainLayout>
  );
};

export default Root;
