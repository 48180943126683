import { Stack, Typography } from '@mui/material';
import { StyleObj } from '../../@types';
import { PropsWithChildren } from 'react';

const styles: StyleObj = {
  stack: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

type FormFieldStackProps = PropsWithChildren & {
  label: string;
};

const FormFieldStack = ({ label, children }: FormFieldStackProps) => {
  return (
    <Stack sx={styles.stack}>
      <Typography variant='h6' fontWeight={600}>
        {label}
      </Typography>
      {children}
    </Stack>
  );
};

export default FormFieldStack;
