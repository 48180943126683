import { Box } from '@mui/material';

const styles = {
  borderRadius: '50%',
  backgroundColor: '#5BACE7',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '32px',
  height: '32px',
  color: '#fff',
};

type Props = {
  author: string;
};

const MessagesBadge = ({ author }: Props) => {
  return (
    <Box>
      <Box sx={styles}>{author.substring(0, 1)}</Box>
    </Box>
  );
};

export default MessagesBadge;
