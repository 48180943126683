import { zodResolver } from '@hookform/resolvers/zod';
import { Divider, TextField } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Control, useForm } from 'react-hook-form';
import { DialogType, SelectOption } from '../../../@types';
import { BonusCampaign } from '../../../@types/api';
import {
  BONUS_ELIGIBILITY_OPTIONS,
  BONUS_PRODUCT_TYPE_OPTIONS,
  BONUS_STATUS_OPTIONS_CUSTOM,
  BONUS_STATUS_OPTIONS_ONGOING,
  BONUS_TIMEFRAME_OPTIONS,
  QUERY_KEYS,
} from '../../../constants';
import { useModal } from '../../../contexts/ModalContext';
import useMutateData from '../../../hooks/useMutateData';
import { BonusFilterFormData, BonusFormData, bonusFormSchema } from '../../../schema';
import FormModalLayout from '../../layouts/FormModalLayout';
import CustomerSelect from '../../molecules/CustomerSelect';
import FormNumberInput from '../../molecules/FormNumberInput';
import FormSelect from '../../molecules/FormSelect';
import ValidityPeriodSelect from '../../molecules/ValidityPeriodSelect';

const DEFAULT_FORM_DATA: BonusFormData = {
  name: '',
  product: 'SPORTSBOOK',
  eligibility: 'LOST_MONEY_IN_DEFINED_TIMEFRAME',
  validityPeriod: 'ONGOING',
  fromTimestamp: undefined,
  toTimestamp: undefined,
  status: 'READY',
  refundPercentage: 0,
  minimumLostAmount: undefined,
  maximumRefundAmount: undefined,
  schedule: 'DAILY',
  userIds: [],
  description: '',
};

type ValidityPeriod = BonusFormData['validityPeriod'];
type BonusStatus = BonusFormData['status'];

const STATUS_CONFIG: Record<
  ValidityPeriod,
  {
    defaultStatus: BonusStatus;
    statusOptions: readonly SelectOption[];
  }
> = {
  CUSTOM: {
    defaultStatus: 'SCHEDULED',
    statusOptions: BONUS_STATUS_OPTIONS_CUSTOM,
  },
  ONGOING: {
    defaultStatus: 'READY',
    statusOptions: BONUS_STATUS_OPTIONS_ONGOING,
  },
};

type Props = DialogType;

const BonusForm = ({ closeModal }: Props) => {
  const { item } = useModal<BonusCampaign>();
  const { createData, updateData } = useMutateData('bonus-campaigns', [QUERY_KEYS.bonus]);

  const isEditMode = !!item;

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm<BonusFormData>({
    defaultValues: DEFAULT_FORM_DATA,
    resolver: zodResolver(bonusFormSchema),
  });

  const validityPeriod = watch('validityPeriod');
  const { defaultStatus, statusOptions } = STATUS_CONFIG[validityPeriod];

  useEffect(() => {
    if (!isEditMode) {
      setValue('status', defaultStatus);
    }
  }, [defaultStatus, isEditMode, setValue]);

  const handleClose = () => {
    closeModal?.();
    reset(DEFAULT_FORM_DATA);
  };

  const onFormSubmit = (data: BonusFormData) => {
    const payload = {
      ...data,
      fromTimestamp: data.fromTimestamp?.valueOf(),
      toTimestamp: data.toTimestamp?.valueOf(),
    };

    if (item) {
      updateData(item.id, payload, handleClose);
    } else {
      createData(payload, handleClose);
    }
  };

  useEffect(() => {
    if (item) {
      reset({
        ...item,
        fromTimestamp: dayjs(item.effectiveDateStart),
        toTimestamp: dayjs(item.effectiveDateEnd),
        userIds: item.userIds || [],
      });
    }
  }, [item, reset]);

  return (
    <FormModalLayout
      onSave={handleSubmit(onFormSubmit)}
      label={isEditMode ? 'Edit bonus' : 'Add new bonus'}
      onClose={handleClose}
      isEdit={isEditMode}
      showEditTabs={false}
    >
      <TextField error={!!errors.name} helperText={errors.name?.message} label='Name' {...register('name')} required />
      <FormSelect
        label='Product'
        name='product'
        control={control}
        error={errors.product}
        options={BONUS_PRODUCT_TYPE_OPTIONS}
        required
        allowDeselect={false}
      />
      <FormSelect
        label='Eligibility criteria'
        name='eligibility'
        control={control}
        error={errors.eligibility}
        options={BONUS_ELIGIBILITY_OPTIONS}
        required
        allowDeselect={false}
      />
      <Divider />
      <ValidityPeriodSelect control={control as unknown as Control<BonusFilterFormData>} disabled={isEditMode} />
      <FormSelect
        control={control}
        name='status'
        label='Status'
        defaultValue={defaultStatus}
        options={statusOptions}
        required
        allowDeselect={false}
        disabled={!isEditMode}
      />
      <Divider />
      <FormNumberInput
        control={control}
        name='refundPercentage'
        label='Refund percentage'
        error={errors.refundPercentage}
        InputProps={{ endAdornment: '%' }}
        required
      />
      <FormNumberInput
        control={control}
        name='minimumLostAmount'
        label='Minimum lost amount'
        error={errors.minimumLostAmount}
      />
      <FormNumberInput
        control={control}
        name='maximumRefundAmount'
        label='Maximum refund amount'
        error={errors.maximumRefundAmount}
      />
      <FormSelect
        label='Timeframe'
        name='schedule'
        control={control}
        error={errors.schedule}
        options={BONUS_TIMEFRAME_OPTIONS}
        required
        allowDeselect={false}
      />
      <CustomerSelect control={control} name='userIds' label='Applied to' />
      <TextField
        error={!!errors.description}
        helperText={errors.description?.message}
        label='Description'
        {...register('description')}
        multiline
      />
    </FormModalLayout>
  );
};

export default BonusForm;
