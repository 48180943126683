import { Button, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { PlayerRisk, UserSettings } from '../../@types/api';
import { BETSLIP_CONTROL_DELAY_OPTIONS, PRODUCT_TYPES_OPTIONS, QUERY_KEYS } from '../../constants';
import { COLUMN_FIELDS } from '../../constants/table';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import useMutateData from '../../hooks/useMutateData';
import { getData } from '../../utils/api';
import FormFieldStack from '../atoms/FormFieldStack';
import Switch from '../atoms/Switch';
import FormSelect from '../molecules/FormSelect';

type FilterSportsLimitsData = {
  rejectAll: boolean;
  verifyAll: boolean;
  timeDelaySeconds?: number;
  eligibleForCashout: boolean;
};

type BetslipControlTabProps = {
  playerRisk: PlayerRisk;
  handleClose: () => void;
};

const BetslipControlTab = ({ handleClose, playerRisk }: BetslipControlTabProps) => {
  const { productValue, id } = playerRisk;

  const invalidateData = useInvalidateQuery();
  const { control, handleSubmit, reset } = useForm<FilterSportsLimitsData>();
  const { updateData } = useMutateData(`risk-management/entity-risk-factors/${productValue}/punter`, [
    QUERY_KEYS.riskPlayers,
    id,
  ]);

  const { updateData: updateUserSettings } = useMutateData('user-settings', [QUERY_KEYS.userSettings]);
  const { data: userSettings } = useQuery([QUERY_KEYS.userSettings, id], {
    queryFn: (): Promise<UserSettings> => getData(`user-settings/${id}`),
  });

  const handleSave = (data: FilterSportsLimitsData) => {
    const { eligibleForCashout, ...limitData } = data;
    updateUserSettings(id, { eligibleForCashout }, () => handleClose());
    updateData(
      id,
      {
        rejectAll: limitData.rejectAll ?? undefined,
        verifyAll: limitData.verifyAll ?? undefined,
        timeDelaySeconds: limitData.timeDelaySeconds,
      },
      () => {
        invalidateData(['risk-management/punters']);
        handleClose();
      }
    );
  };

  useEffect(() => {
    reset({
      ...playerRisk,
      eligibleForCashout: userSettings?.[COLUMN_FIELDS.CASHOUT],
    });
  }, [playerRisk, reset, userSettings]);

  return (
    <Stack spacing={2}>
      <Typography variant='h5'>{PRODUCT_TYPES_OPTIONS.find((item) => item.id === productValue)?.name}</Typography>
      <Stack spacing={2} mt={2}>
        <FormSelect
          label='Time delay'
          control={control}
          name='timeDelaySeconds'
          options={BETSLIP_CONTROL_DELAY_OPTIONS}
        />
        <FormFieldStack label='Reject all'>
          <Controller control={control} name='rejectAll' render={({ field }) => <Switch {...field} ref={null} />} />
        </FormFieldStack>
        <FormFieldStack label='Verify all'>
          <Controller control={control} name='verifyAll' render={({ field }) => <Switch {...field} ref={null} />} />
        </FormFieldStack>
        <FormFieldStack label='Cash Out'>
          <Controller
            control={control}
            name='eligibleForCashout'
            render={({ field }) => <Switch {...field} ref={null} />}
          />
        </FormFieldStack>
      </Stack>
      <Stack spacing={2} direction='row' justifyContent='flex-end' mt={3}>
        <Button variant='outlined' onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='contained' type='submit' onClick={handleSubmit(handleSave)}>
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export default BetslipControlTab;
