import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SportsbookReportsTotals, SportsbookEntityType } from '../../../../@types/api';
import { usePagination } from '../../../../hooks/usePagination';
import useSort from '../../../../hooks/useSort';
import { Box } from '@mui/material';
import TableTemplate from '../../../templates/TableTemplate';
import { getSportsbookColumns } from '../../../../config/sportsbookReports';
import { StyleObj } from '../../../../@types';
import { generateDateRangeTimestamps, getTotalsRowClass } from '../../../../helpers';
import DateRangeSelect, { DayjsTimestamp } from '../../../molecules/DateRangeSelect';
import dayjs from 'dayjs';

const styles: StyleObj = {
  container: {
    position: 'relative',
    height: 'calc(100% - 200px)',
  },
};

const URL_TO_SPORTSBOOK_MODEL_TYPE_MAP: Record<string, SportsbookEntityType> = {
  tournaments: 'tournament',
  markets: 'market-type',
  sports: 'sport',
};

const generateTotalsRow = (data?: SportsbookReportsTotals) => {
  if (data)
    return {
      id: 'totals',
      icon: 'Totals',
      ticketCount: data.ticketCount,
      stakeAmount: data.stakeAmount,
      winningAmount: data.winningAmount,
      profitLossAmount: data.profitLossAmount,
      profitMarginPercentage: data.profitMarginPercentage,
    };
  return { id: 'totals' };
};

const SportsbookReportsEntityPage = () => {
  const location = useLocation();
  const { fromTimestamp, toTimestamp } = generateDateRangeTimestamps('today');
  const [date, setDate] = useState<DayjsTimestamp>({
    fromTimestamp: dayjs().startOf('day'),
    toTimestamp: dayjs().endOf('day'),
  });
  const pathSegment = location.pathname.split('/').pop();
  const type = pathSegment && URL_TO_SPORTSBOOK_MODEL_TYPE_MAP[pathSegment];
  const { data, updateQueryParams, isFetching, isLoading, changeQuery } = usePagination(`reports/${type}`, {
    page: 1,
    limit: 25,
    fromTimestamp: fromTimestamp.valueOf(),
    toTimestamp: toTimestamp.valueOf(),
    walletType: 'main',
  });

  const totalsRow = generateTotalsRow(data?.total);

  const aggregatedData = data?.items ? [...data.items, totalsRow] : [];
  const { handleSort } = useSort(changeQuery);

  const { columns } = getSportsbookColumns(type as SportsbookEntityType);

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  const handleDateChange = (newDate: DayjsTimestamp) => {
    setDate(newDate);
  };

  useEffect(() => {
    changeQuery({
      fromTimestamp: date.fromTimestamp.valueOf(),
      toTimestamp: date.toTimestamp.valueOf(),
    });
  }, [date, changeQuery]);

  return (
    <Box sx={styles.container}>
      <DateRangeSelect date={date} onDateChange={handleDateChange} />
      <TableTemplate
        rows={aggregatedData}
        columns={columns}
        loading={isFetching || isLoading}
        rowCount={data?.count || 0}
        handlePaginationModelChange={updateQueryParams}
        handleSearch={handleSearch}
        handleSort={handleSort}
        getRowClassName={getTotalsRowClass}
        changeQuery={changeQuery}
        showExport
      />
    </Box>
  );
};

export default SportsbookReportsEntityPage;
