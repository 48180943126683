import { BettingSlipFrom } from '../../@types/api';
import { getBettingSlipFromIcon } from '../../helpers';

type BettingSlipFromIconProps = {
  from: BettingSlipFrom;
};
const BettingSlipFromIcon = ({ from }: BettingSlipFromIconProps) => {
  const Icon = getBettingSlipFromIcon(from);
  return <Icon />;
};

export default BettingSlipFromIcon;
