import { Box, Typography } from '@mui/material';
import { CommissionPicks } from '../../@types/api';
import { COMMISSION_PICKS } from '../../constants';

type Props = {
  commission: CommissionPicks<number>;
};

const styles = {
  container: {
    width: 1200,
  },
  bold: {
    fontWeight: 600,
  },
};

const AgentsReportsCommissions = ({ commission }: Props) => {
  return (
    <Box sx={styles.container}>
      <Typography sx={styles.bold}>Per Pick</Typography>
      <Typography sx={styles.bold}>Player on: Per Pick</Typography>
      {COMMISSION_PICKS.map((pick) => (
        <Typography key={pick}>
          {pick}: {commission[pick] / 100}%
        </Typography>
      ))}
    </Box>
  );
};

export default AgentsReportsCommissions;
