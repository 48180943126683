import { Box } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { StyleObj } from '../../../@types';
import { ConditionEntity, ConditionEntityType } from '../../../@types/api';
import { CONDITION_ENTITY_CONFIG } from '../../../config/conditions';
import { QUERY_KEYS } from '../../../constants';
import useMutateData from '../../../hooks/useMutateData';
import { usePagination } from '../../../hooks/usePagination';
import useSort from '../../../hooks/useSort';
import { ConditionFormData } from '../../../schema';
import ConditionsValuesForm from '../../organisms/forms/conditions/ConditionsValuesForm';
import TableTemplate from '../../templates/TableTemplate';
import { defaultValues } from '../../organisms/forms/FilterPreMatchSettingsData';

const makeStyles = (selectedRow: boolean): StyleObj => ({
  container: {
    display: 'grid',
    gridTemplateColumns: selectedRow ? '2fr 1fr' : '1fr',
    height: '100%',
    maxHeight: '100%',
    minHeight: 0,
    gap: 3,
  },
  createItemBtn: selectedRow
    ? {
        position: 'absolute',
        top: 8,
        right: 0,
        ml: 0,
      }
    : {},
  conditionsValuesFormWrapper: {
    mt: 11,
    backgroundColor: 'background.light',
    p: 3,
    borderRadius: 2,
    height: 'fit-content',
  },
});

export const URL_TO_CONDITION_MODEL_TYPE_MAP: Record<string, ConditionEntityType> = {
  sports: 'sport',
  competitions: 'competition',
  tournaments: 'tournament',
  markets: 'market_type',
  punters: 'punter',
};

const ConditionEntityPage = () => {
  const [selectedRow, setSelectedRow] = useState<{
    id: string;
    conditionGroupId: string | null;
  } | null>(null);
  const location = useLocation();
  const pathSegment = location.pathname.split('/').pop();

  const type = pathSegment && URL_TO_CONDITION_MODEL_TYPE_MAP[pathSegment];

  const { data, updateQueryParams, isFetching, isLoading, changeQuery } = usePagination(
    'conditions',
    {
      page: 1,
      limit: 25,
      type,
    },
    {
      enabled: !!type,
    }
  );

  const { createData: createCondition } = useMutateData('conditions', [QUERY_KEYS.conditions]);

  const styles = makeStyles(Boolean(selectedRow));

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  useEffect(() => {
    changeQuery({ type, search: undefined });
    setSelectedRow(null);
  }, [changeQuery, type]);

  const { handleSort } = useSort(changeQuery);

  const { columns, defaultVisibleColumns } = CONDITION_ENTITY_CONFIG[type as keyof typeof CONDITION_ENTITY_CONFIG];

  const onFormCancel = () => {
    setSelectedRow(null);
  };

  const onSave = (data: ConditionFormData) => {
    createCondition(data);
  };

  const onRowClick = (row: GridRowParams<ConditionEntity>) => {
    setSelectedRow({
      id: row.row.id || row.row.userId,
      conditionGroupId: row.row.conditions?.conditionGroupId || null,
    });
  };

  const slotProps = {
    row: {
      style: {
        cursor: 'pointer',
      },
    },
  };

  useEffect(() => {
    changeQuery({ ...defaultValues, type: URL_TO_CONDITION_MODEL_TYPE_MAP[pathSegment as string] });
  }, [pathSegment, changeQuery]);

  return (
    <Box sx={styles.container}>
      <TableTemplate
        rows={data?.items || []}
        rowCount={data?.count || 0}
        columns={columns}
        defaultVisibleColumns={defaultVisibleColumns}
        loading={isFetching || isLoading}
        handlePaginationModelChange={updateQueryParams}
        handleSearch={handleSearch}
        handleSort={handleSort}
        changeQuery={changeQuery}
        onRowClick={onRowClick}
        rowSelectionModel={selectedRow ? [selectedRow.id] : []}
        slotProps={slotProps}
        getRowId={(params) => (type === 'punter' ? params.userId : params.id)}
      />
      {selectedRow && type && (
        <Box sx={styles.conditionsValuesFormWrapper}>
          <ConditionsValuesForm
            onCancel={onFormCancel}
            onSave={onSave}
            conditionGroupId={selectedRow?.conditionGroupId || null}
            conditionModelType={type}
            modelId={selectedRow.id}
          />
        </Box>
      )}
    </Box>
  );
};

export default ConditionEntityPage;
