import { QueryKey, useQuery } from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';
import { useState } from 'react';

import { omitBy } from 'lodash-es';
import { PaginatedData } from '../@types/api';
import { ApiService, getData } from '../utils/api';

const DEFAULT_SEARCH_VALUE = '';

type HookInputOptions = {
  limit?: number;
  page?: number;
  additionalParams?: Record<string, unknown>;
  delay?: number;
  hookEnabled?: boolean;
};

const useDebouncedSearch = <T>(
  apiService: ApiService = 'feed',
  url: string,
  queryKey: QueryKey,
  options?: HookInputOptions,
  hookEnabled?: boolean
) => {
  const { limit = 100, page = 1, delay = 300, additionalParams = {} } = options || {};

  const [search, setSearch] = useState(DEFAULT_SEARCH_VALUE);

  const debouncedSearch = useDebounce(search, delay);

  const additionalParamsWithoutEmptyValues = omitBy(additionalParams, (value) => value === '');

  const { data, isFetching } = useQuery([queryKey, debouncedSearch], {
    queryFn: (): Promise<PaginatedData<T>> =>
      getData(
        url,
        {
          limit,
          page,
          search: debouncedSearch?.trim() || undefined,
          ...additionalParamsWithoutEmptyValues,
        },
        apiService
      ),
    keepPreviousData: true,
    enabled: hookEnabled,
  });

  const handleSearchInputChange = (value: string) => {
    setSearch(value);
  };

  return {
    data,
    search,
    isFetching,
    onSearch: handleSearchInputChange,
    reset: () => setSearch(DEFAULT_SEARCH_VALUE),
  };
};

export default useDebouncedSearch;
