import { SVGProps } from 'react';

const PreMatchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={25} height={24} fill='none' {...props}>
    <rect width={24} height={24} x={0.647} fill='#2B7FFE' rx={2} />
    <path
      fill='#fff'
      d='M8.847 18V6.64h4.816a3.24 3.24 0 0 1 1.44.32c.437.213.816.501 1.136.864.33.363.586.768.768 1.216.18.448.272.907.272 1.376 0 .64-.15 1.248-.448 1.824a3.833 3.833 0 0 1-1.232 1.408c-.523.363-1.142.544-1.856.544h-2.688V18H8.847Zm2.208-5.744h2.56c.266 0 .506-.075.72-.224.213-.15.384-.363.512-.64.128-.288.192-.613.192-.976 0-.395-.075-.73-.224-1.008a1.457 1.457 0 0 0-.56-.624 1.371 1.371 0 0 0-.736-.208h-2.464v3.68Z'
    />
  </svg>
);
export default PreMatchIcon;
