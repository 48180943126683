import { Replay } from '@mui/icons-material';
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import dayjs, { Dayjs } from 'dayjs';
import { isEmpty } from 'lodash-es';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { DialogType, StyleObj } from '../../../@types';
import { PublishStatus } from '../../../@types/api';
import {
  CHANGED_STATUS_OPTIONS,
  EVENT_SOURCE_OPTIONS,
  HAS_STARTED_OPTIONS,
  IS_LIVE_OPTIONS,
  LS_KEYS,
  PUBLISH_STATUS_BASE_OPTIONS,
  QUERY_KEYS,
} from '../../../constants';
import { AdditionalQueryParams } from '../../../hooks/usePagination';
import usePersist from '../../../hooks/usePersist';
import { getBool } from '../../../utils';
import DateTimeSelect from '../../molecules/DateTimeSelect';
import FormSelect from '../../molecules/FormSelect';
import SportSelect from '../../molecules/SportSelect';
import TournamentSelect from '../../molecules/TournamentSelect';
import FormAutocomplete from '../FormAutocomplete';

const defaultValues = {
  fromTimestamp: dayjs(),
  toTimestamp: dayjs().add(14, 'day'),
  sportIds: [],
  competitionIds: [],
  tournamentIds: [],
  isStarted: undefined,
  isLive: 0,
  changed: undefined,
  publishStatuses: [],
  source: null,
};

export type FilterEventsData = {
  fromTimestamp?: number | Dayjs;
  toTimestamp?: number | Dayjs;
  sportIds: string[];
  competitionIds: string[];
  tournamentIds: string[];
  isStarted?: boolean;
  isLive?: number;
  changed?: boolean;
  publishStatuses: PublishStatus[];
  source: 'manual' | 'odds_matrix_feed' | null;
};

const styles: StyleObj = {
  container: { display: 'flex', flexDirection: 'column', gap: 2 },
};

type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const FilterEvents = ({ changeQuery, closeModal }: Props) => {
  const { setPersistData, getPersistData, deletePersistData } = usePersist(false);
  const { pathname } = useLocation();

  const formDataKey = `${LS_KEYS.appFormFilter}-${pathname}`;
  const getSavedData = () => getPersistData<FilterEventsData>(formDataKey) || defaultValues;

  const { control, handleSubmit, watch, reset, setValue } = useForm<FilterEventsData>({
    defaultValues: getSavedData(),
  });

  const sportIds = watch('sportIds');
  const competitionIds = watch('competitionIds');
  const tournamentIds = watch('tournamentIds');
  const fromTimestamp = watch('fromTimestamp');
  const toTimestamp = watch('toTimestamp');
  const eventsStatus = watch('isLive');

  useEffect(() => {
    const subscription = watch((_value, { name, type }) => {
      if (type !== 'change') return;

      if (name === 'sportIds') {
        if (!isEmpty(competitionIds)) {
          setValue('competitionIds', []);
        }
        if (!isEmpty(tournamentIds)) {
          setValue('tournamentIds', []);
        }
      }
      if (name === 'competitionIds') {
        if (!isEmpty(tournamentIds)) {
          setValue('tournamentIds', []);
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, competitionIds, tournamentIds]);

  const prepareData = (data: FilterEventsData) => {
    const preparedData = { ...data };

    if (!preparedData.isLive) {
      if (data.fromTimestamp) {
        preparedData.fromTimestamp = dayjs(data.fromTimestamp).unix() * 1000;
      }
      if (data.toTimestamp) {
        preparedData.toTimestamp = dayjs(data.toTimestamp).endOf('day').unix() * 1000;
      }
    } else {
      preparedData.fromTimestamp = undefined;
      preparedData.toTimestamp = undefined;
    }

    if (data.isStarted !== undefined) {
      preparedData.isStarted = Boolean(data.isStarted);
    }
    if (data.changed !== undefined) {
      preparedData.changed = Boolean(data.changed);
    }

    return preparedData;
  };

  const handleFilterReset = () => {
    deletePersistData(formDataKey);
    reset(defaultValues);
    changeQuery(prepareData(defaultValues));

    closeModal?.();
  };

  const onFormSubmit = (data: FilterEventsData) => {
    setPersistData<FilterEventsData>(formDataKey, data);
    changeQuery({ ...prepareData(data), isLive: getBool(data.isLive) });
    closeModal?.();
  };

  return (
    <>
      <DialogTitle variant='h4' gutterBottom>
        Filter events
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <FormSelect control={control} name='isLive' label='Event status' options={IS_LIVE_OPTIONS} />
          {!eventsStatus && (
            <Box sx={styles.container}>
              <DateTimeSelect control={control} name='fromTimestamp' label='From' maxDateTime={dayjs(toTimestamp)} />
              <DateTimeSelect control={control} name='toTimestamp' label='To' minDateTime={dayjs(fromTimestamp)} />
            </Box>
          )}
          <SportSelect label='Sports' control={control} name='sportIds' multiple closeMenuOnSelect />
          <FormAutocomplete
            name='competitionIds'
            control={control}
            label='Competitions'
            url='competitions'
            queryKey={[QUERY_KEYS.competitions, sportIds, competitionIds]}
            queryParams={{
              sportIds,
              isActive: true,
              competitionIds,
            }}
            disabled={isEmpty(sportIds)}
            hookEnabled={!!sportIds}
            getOptionLabel={(options, value) => {
              const option = options.find((option) => option?.id === value);
              return option?.name || '';
            }}
            multiple
          />
          <TournamentSelect
            label='Tournaments'
            control={control}
            name='tournamentIds'
            competitionIds={competitionIds}
            multiple
            closeMenuOnSelect
          />
          <FormSelect control={control} name='isStarted' label='Time status' options={HAS_STARTED_OPTIONS} />
          <FormSelect
            control={control}
            name='publishStatuses'
            label='Publish status'
            options={PUBLISH_STATUS_BASE_OPTIONS}
            multiple
            closeOnSelect
          />
          <FormSelect control={control} name='changed' label='Changed' options={CHANGED_STATUS_OPTIONS} />
          <FormSelect control={control} name='source' label='Source' options={EVENT_SOURCE_OPTIONS} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='text' onClick={handleFilterReset} startIcon={<Replay />} size='small'>
          Reset filters
        </Button>
        <Button variant='outlined' onClick={closeModal}>
          Cancel
        </Button>
        <Button variant='contained' onClick={handleSubmit(onFormSubmit)}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default FilterEvents;
