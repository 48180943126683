import { useForm } from 'react-hook-form';
import { useModal } from '../../contexts/ModalContext';
import FormModalLayout from '../layouts/FormModalLayout';
import FormAutocomplete from './FormAutocomplete';
import { QUERY_KEYS } from '../../constants';
import { MarketType } from '../../@types/api';
import useMutateData from '../../hooks/useMutateData';
import { useCallback } from 'react';

type FormData = {
  marketTypeCombiningIds: string[];
};

const MarketCombiningTab = () => {
  const { item, closeModal } = useModal<MarketType>();

  const { control, handleSubmit, watch } = useForm<FormData>({
    defaultValues: {
      marketTypeCombiningIds: item?.marketTypeCombiningIds || [],
    },
  });

  const { updateData } = useMutateData('market-types', [QUERY_KEYS.marketTypes]);

  const onFormSubmit = (data: FormData) => {
    if (!item) return;

    updateData(item?.id, data, () => {
      closeModal?.();
    });
  };

  const getOptionLabel = useCallback((options: MarketType[], value: string) => {
    const option = options.find((option) => option?.id === value);
    return option ? `${option.name}, ${option.eventPartName}` : '';
  }, []);

  const marketTypeCombiningIds = watch('marketTypeCombiningIds');

  return (
    <FormModalLayout
      label={item?.shortName ?? `${item?.name}, ${item?.eventPartName}`}
      onClose={closeModal}
      onSave={handleSubmit(onFormSubmit)}
    >
      <FormAutocomplete<MarketType, FormData>
        control={control}
        label='Can combine with'
        name='marketTypeCombiningIds'
        multiple
        url='market-types'
        queryKey={[QUERY_KEYS.marketTypes, item?.sportId, marketTypeCombiningIds]}
        queryParams={{
          sportId: item?.sportId,
          marketTypeIds: marketTypeCombiningIds,
          canCombineWithId: item?.id,
        }}
        getOptionLabel={getOptionLabel}
      />
    </FormModalLayout>
  );
};

export default MarketCombiningTab;
