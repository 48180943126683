import { Replay } from '@mui/icons-material';
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { DialogType } from '../../../@types';
import { BettingSlipFrom, Customer, Device, Verification } from '../../../@types/api';
import {
  BETTING_SLIP_FROM_OPTIONS,
  BETTING_SLIP_VERIFICATION_OPTIONS,
  DEVICE_OPTIONS,
  LS_KEYS,
  QUERY_KEYS,
} from '../../../constants';
import { AdditionalQueryParams } from '../../../hooks/usePagination';
import usePersist from '../../../hooks/usePersist';
import FormSelect from '../../molecules/FormSelect';
import FormAutocomplete from '../FormAutocomplete';

const defaultValues = {
  userId: undefined,
  from: undefined,
  device: undefined,
  verification: undefined,
};

export type FilterTicketAuthorizationData = {
  userId?: string;
  from?: BettingSlipFrom;
  device?: Device;
  verification?: Verification;
};

type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const FilterTicketAuthorization = ({ changeQuery, closeModal }: Props) => {
  const { setPersistData, getPersistData, deletePersistData } = usePersist(false);
  const { pathname } = useLocation();

  const formDataKey = `${LS_KEYS.appFormFilter}-${pathname}`;
  const getSavedData = () => getPersistData<FilterTicketAuthorizationData>(formDataKey) || defaultValues;

  const { control, handleSubmit, reset } = useForm<FilterTicketAuthorizationData>({
    defaultValues: getSavedData(),
  });

  const handleFilterReset = () => {
    deletePersistData(formDataKey);
    reset(defaultValues);
    changeQuery(defaultValues);

    closeModal?.();
  };

  const onFormSubmit = (data: FilterTicketAuthorizationData) => {
    setPersistData<FilterTicketAuthorizationData>(formDataKey, {
      ...data,
    });
    changeQuery(data);

    closeModal?.();
  };

  return (
    <>
      <DialogTitle variant='h4' gutterBottom>
        Filter betting slips
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <FormSelect control={control} name='from' label='From' options={BETTING_SLIP_FROM_OPTIONS} />
          <FormSelect
            control={control}
            name='verification'
            label='Verification'
            options={BETTING_SLIP_VERIFICATION_OPTIONS}
          />
          <FormSelect control={control} name='device' label='Source' options={DEVICE_OPTIONS} />
          <FormAutocomplete<Customer, FilterTicketAuthorizationData>
            name='userId'
            control={control}
            queryKey={[QUERY_KEYS.customers]}
            apiService='crm'
            url='customer/search'
            getOptionLabel={(options, value) => {
              const option = options.find((option) => option?.userId === value);
              return option ? `${option.firstName} ${option.lastName} (${option.username})` : '';
            }}
            label='Punter'
            optionsFilter='userId'
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='text' onClick={handleFilterReset} startIcon={<Replay />} size='small'>
          Reset filters
        </Button>
        <Button variant='outlined' onClick={closeModal}>
          Cancel
        </Button>
        <Button variant='contained' onClick={handleSubmit(onFormSubmit)}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default FilterTicketAuthorization;
