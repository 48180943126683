import { isEmpty } from 'lodash-es';
import { isNumeric } from '.';
import { Market, SpecialValue } from '../@types/api';

const MARKET_TYPE_ASIAN_HANDICAP_WITH_SCORE = 'Asian Handicap with Score';

export const extractSpecialValuesFromOutcomeName = (
  outcomeName: string,
  specialValues: SpecialValue[] = [],
  isHandicapMarket: boolean,
  marketTypeName?: string
) => {
  if (marketTypeName === MARKET_TYPE_ASIAN_HANDICAP_WITH_SCORE) {
    const specialValue = outcomeName.split(' ').pop();

    if (specialValue && isNumeric(specialValue)) {
      return [specialValue];
    }
  }

  // For non-handicap markets, extract only numeric values
  const regex = isHandicapMarket ? /\(([^()]+(?:\([^()]*\)[^()]*)*)\)/g : /\(([-+]?[0-9]*\.?[0-9]+)\)/g;
  const specialValuesFinal: string[] = [];
  let match;

  while ((match = regex.exec(outcomeName)) !== null) {
    const outcomeNameBracketsValues = match[1].split(' ');
    const SVValues = specialValues.map((sv) => Math.abs(sv.value || 0));

    outcomeNameBracketsValues.forEach((value) => {
      if (isNumeric(value)) {
        const absoluteValue = Math.abs(parseFloat(value));
        if (SVValues.includes(absoluteValue)) {
          specialValuesFinal.push(value);
        }
      } else {
        if ((value !== '(W)' && value !== 'W') || !isEmpty(specialValuesFinal)) {
          specialValuesFinal.push(value);
        }
      }
    });
  }

  return specialValuesFinal;
};

export const getSpecialValuesDisplayValue = (
  specialValuesFromOutcomeName: string[] | null,
  specialValues?: SpecialValue[]
) => {
  if (isEmpty(specialValuesFromOutcomeName)) {
    return specialValues?.map((specialValue) => specialValue.value).join(' ') || '';
  }

  return specialValuesFromOutcomeName?.join(' ');
};

export const isHandicapMarket = (marketTypeName: string) => marketTypeName.toLowerCase().includes('handicap');

export const mapSpecialValuesToHomeTeam = (market: Market) => {
  return market.specialValues?.map((specialValue) => {
    const isHandicap = isHandicapMarket(market.marketType.name);

    if (!isHandicap) return specialValue;

    return {
      ...specialValue,
      value: extractSpecialValuesFromOutcomeName(
        market.outcomes[0].name,
        market.specialValues,
        isHandicap,
        market.marketType.name
      ),
    };
  });
};
