import { capitalize } from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { Transaction } from '../@types/api';
import { walletValueGetter } from '../helpers';
import { createColumn, formatSnakeCaseString } from '../utils';

const columns: Record<string, GridColDef> = {
  id: createColumn('id', 'ID', { sortable: false }),
  executionTime: createColumn('executionTime', 'Date and Time', {
    flex: 1.25,
    valueGetter: (params: GridValueGetterParams<Transaction>) =>
      dayjs(params.row.executionTime).format('DD/MM/YYYY HH:mm:ss'),
  }),
  updatedBy: createColumn('updatedBy', 'Updated by', {
    valueGetter: (params: GridValueGetterParams<Transaction>) => params.row.metadata?.operatorFullName,
  }),
  type: createColumn('type', 'Type', {
    flex: 0.75,
    valueGetter: (params: GridValueGetterParams<Transaction>) => formatSnakeCaseString(params.row.type),
  }),

  from: createColumn('from', 'From', {
    sortable: false,
  }),
  to: createColumn('to', 'To', {
    sortable: false,
  }),
  mainIn: createColumn('mainIn', '+', {
    flex: 0.5,
    sortable: false,
    valueGetter: walletValueGetter('mainWallet', 'amountIn'),
  }),
  mainOut: createColumn('mainOut', '-', {
    flex: 0.5,
    sortable: false,
    valueGetter: walletValueGetter('mainWallet', 'amountOut'),
  }),
  mainBalance: createColumn('mainBalance', 'Balance', {
    flex: 0.5,
    sortable: false,
    valueGetter: walletValueGetter('mainWallet', 'balance'),
  }),
  bonusIn: createColumn('bonusIn', '+', {
    flex: 0.5,
    sortable: false,
    valueGetter: walletValueGetter('bonusWallet', 'amountIn'),
  }),
  bonusOut: createColumn('bonusOut', '-', {
    flex: 0.5,
    sortable: false,
    valueGetter: walletValueGetter('bonusWallet', 'amountOut'),
  }),
  bonusBalance: createColumn('bonusBalance', 'Balance', {
    flex: 0.5,
    sortable: false,
    valueGetter: walletValueGetter('bonusWallet', 'balance'),
  }),
  status: createColumn('status', 'Status', {
    valueGetter: (params: GridValueGetterParams<Transaction>) => capitalize(params.row.status),
  }),
};

export const customerWalletTransactionColumns: GridColDef[] = [
  columns.id,
  columns.updatedBy,
  columns.executionTime,
  columns.type,
  columns.from,
  columns.to,
  columns.mainIn,
  columns.mainOut,
  columns.mainBalance,
  columns.bonusIn,
  columns.bonusOut,
  columns.bonusBalance,
  columns.status,
];

export const myWalletTransactionColumns: GridColDef[] = [
  columns.id,
  columns.updatedBy,
  columns.executionTime,
  columns.type,
  columns.from,
  columns.to,
  columns.mainIn,
  columns.mainOut,
  columns.mainBalance,
  columns.status,
];
