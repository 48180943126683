const MixIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={25} height={24} fill='none' {...props}>
    <rect width={24} height={24} x={0.647} fill='#9A82DB' rx={2} />
    <path
      fill='#fff'
      d='M16.173 18v-7.456l-2.912 5.552H12.03l-2.912-5.552V18H6.91V6.64h2.368l3.36 6.464L16.03 6.64h2.352V18h-2.208Z'
    />
  </svg>
);
export default MixIcon;
